<template>
    <div class="section" style="">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="swiper-image1">
                        <img src="../assets/css/img/1-4-1.png" style="width:100%;overflow:hidden">
                        <div class="swiper-box">
                            <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                                <span class="p1-span1">
                                    <!-- 一 个 登 录 账 号  -->
                                    <div :class="$i18n.locale=='en'?'active1':'active'"> 一 个 登 录 账 号 </div>
                                    <div :class="$i18n.locale=='en'?'active':'active1'">One login account has data</div>
                                    <!-- {{ $t('index.swiper1') }} -->
                                </span>
                                <span class="p1-span2">
                                    <!-- 不 同 终 端 数 据 共 通  -->
                                    <!-- {{ $t('index.swiper2') }} -->
                                    <div :class="$i18n.locale=='en'?'active1':'active'"> 不 同 终 端 数 据 共 通 </div>
                                    <div :class="$i18n.locale=='en'?'active':'active1'">common to different terminals</div>
                                </span>
                            </p>
                            <p class="content-p2">
                                <span class="p2-span1">
                                    <!-- 收银机 自助机 盘点系统  -->
                                    {{ $t('index.swiper3') }}
                                </span>
                                <span class="p2-span2">
                                    <!-- WEB 手机助手 点餐宝 -->
                                    {{ $t('index.swiper4') }}
                                </span>
                            </p>
                            <div class="div-border"></div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="swiper-image2">
                        <img src="../assets/css/img/1-2-1.png" style="width:100%;">
                        <div class="content">
                            <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                                <!-- 餐饮零售 -->
                                {{ $t('index.swiper5') }}
                            </p>
                            <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                                <!-- 多种场景支持 -->
                                {{ $t('index.swiper6') }}
                            </p>
                            <p class="p-border"></p>
                            <p class="content-p2">
                                <!-- 适合餐饮零售不同类型门店 -->
                                {{ $t('index.swiper7') }}
                            </p>
                            <p class="content-p2">
                                <!-- 多种营销模式配合使用 -->
                                {{ $t('index.swiper8') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="swiper-image3">
                        <img src="../assets/css/img/1-3-1.png" style="width:100%;">
                        <div class="content">
                            <p :class="$i18n.locale=='en'?'content-p1-1':'content-p1'">
                                <!-- 双屏客显 -->
                                {{ $t('index.swiper9') }}
                            </p>
                            <p :class="$i18n.locale=='en'?'content-p1-1':'content-p1'">
                                <!-- 视屏广告 一触即发 -->
                                {{ $t('index.swiper10') }}
                            </p>
                            <p class="p-border"></p>
                            <p class="content-p2">
                                <!-- 顾客付款时消费信息一目了然 -->
                                {{ $t('index.swiper11') }}
                            </p>
                            <p class="content-p2">
                                <!-- 交互体验更友好 -->
                                {{ $t('index.swiper12') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
        </div>
        <div id="section" style="background:#F3F4F5;padding-top:5rem;margin-top:-0.3rem;">
            <div class="section-1" style="margin-top:0rem;">
                <div class="content" style="margin-top:-0.3rem;">
                    <p class="content-p1">
                        <!-- 产品功能 -->
                        {{ $t('index.goods1') }}
                    </p>
                    <p class="content-p2">
                        <!-- 区别于传统收银 ，唐库做了全方位的系统统升级， -->
                        {{ $t('index.goods2') }}
                    </p>
                    <p class="content-p2">
                        <!-- 体验升级、商户说好，消费者也说好。 -->
                        {{ $t('index.goods3') }}
                    </p>
                </div>
            </div>
            <div class="section-2">
                <div class="box">
                    <div class="box-1">
                        <img src="../assets/css/img/icon_stores02.png" />
                        <div class="box-content">
                            <p class="box-p1">
                                <!-- 门店管理 -->
                                {{ $t('index.md') }}
                            </p>
                            <p :class="$i18n.locale == 'en' ? 'box-p2' : 'zh'">
                                {{ $t('index.md1') }}
                            </p>
                            <!-- <p class="box-p2">
                                店内经营、业务报表、营销 
                                {{ $t('index.md1') }}
                            </p>
                            <p class="box-p2">
                                策略、会员信息、店面视频 
                                {{ $t('index.md2') }}
                            </p>
                            <p class="box-p2">
                                监控等、手机、PC随时随地 
                                {{ $t('index.md3') }}
                            </p>
                            <p class="box-p2">
                                 掌握全局 
                                {{ $t('index.md4') }}
                            </p> -->
                        </div>
                    </div>
                    <div class="box-1">
                        <img src="../assets/css/img/icon_members02.png" />
                        <div class="box-content">
                            <p class="box-p1">
                                <!-- 会员营销 -->
                                {{ $t('index.hy') }}
                            </p>
                            <p :class="$i18n.locale == 'en' ? 'box-p2' : 'zh'">
                                {{ $t('index.hy1') }}
                            </p>
                            <!-- <p class="box-p2">
                                玩转会员营销，您可以设置

                            </p>
                            <p class="box-p2">
                                会员积分制度，充值赠送、
                            </p>
                            <p class="box-p2">
                                满额立减和返现活动，实实
                            </p>
                            <p class="box-p2">
                                在在回流更多资金。
                            </p> -->
                        </div>
                    </div>
                    <div class="box-1">
                        <img src="../assets/css/img/icon_pay02.png" />
                        <div class="box-content">
                            <p class="box-p1">
                                <!-- 聚合支付 -->
                                {{ $t('index.zf') }}
                            </p>
                            <p :class="$i18n.locale == 'en' ? 'box-p2' : 'zh'">
                                {{ $t('index.zf1') }}
                            </p>
                            <!-- <p class="box-p2">
                                依托智能终端设备，为商户
                            </p>
                            <p class="box-p2">
                                的日常经营活动提供强大的
                            </p>
                            <p class="box-p2">
                                基础收银、聚合支付等功
                            </p>
                            <p class="box-p2">
                                能。
                            </p> -->
                        </div>
                    </div>
                    <div class="box-1">
                        <img src="../assets/css/img/icon_service02.png" />
                        <div class="box-content">
                            <p class="box-p1">
                                <!-- 增值服务 -->
                                {{ $t('index.zz') }}
                            </p>
                            <p :class="$i18n.locale == 'en' ? 'box-p2' : 'zh'">
                                {{ $t('index.zz1') }}
                            </p>
                            <!-- <p class="box-p2">
                                会员管理、营销,模式多样
                            </p>
                            <p class="box-p2">
                                化、精准化、人性化，服
                            </p>
                            <p class="box-p2">
                                务、营销信息瞬间抵达目标
                            </p>
                            <p class="box-p2">
                                会员，沟通互动上通下达无
                            </p>
                            <p class="box-p2">
                                障碍。
                            </p> -->
                        </div>
                    </div>
                    <div class="box-1">
                        <img src="../assets/css/img/icon_statistical02.png" />
                        <div class="box-content">
                            <p class="box-p1">
                                <!-- 统计分析 -->
                                {{ $t('index.tj') }}
                            </p>
                            <p :class="$i18n.locale == 'en' ? 'box-p2' : 'zh'">
                                {{ $t('index.tj1') }}
                            </p>
                            <!-- <p class="box-p2">
                                支持手机、PC查看报表，发
                            </p>
                            <p class="box-p2">
                                现营业数据背后的价值。关
                            </p>
                            <p class="box-p2">
                                注“唐库”，即刻体验!
                            </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-1" style="background:#FFFFFF;margin-top:5rem;">
            <div class="content" style="margin-top:5rem;">
                <p class="content-p1">
                    <!-- 适用场景 -->
                    {{ $t('index.cj') }}
                </p>
                <p class="content-p2">
                    <!-- 多角度支持各大场景，多种方案智能营销。 -->
                    {{ $t('index.cj1') }}
                </p>
            </div>
        </div>
        <div class="section1-content">
            <div class="section1-content-1">
                <ul style="width:105rem;display:flex;justify-content:space-around;margin:0 auto;">
                    <li class="li-1">
                        <div class="li-content">
                            <p class="li-p1">
                                零售行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                Retail
                            </p>
                        </div>
                    </li>
                    <li class="li-2">
                        <div class="li-content">
                            <p class="li-p1">
                                餐饮行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                Restaurant
                            </p>
                        </div>
                    </li>
                    <li class="li-3">
                        <div class="li-content">
                            <p class="li-p1">
                                美妆行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                Beauty makeup
                            </p>
                        </div>
                    </li>
                    <li class="li-4">
                        <div class="li-content">
                            <p class="li-p1">
                                烘焙行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                Baking
                            </p>
                        </div>
                    </li>
                    <li class="li-5">
                        <div class="li-content">
                            <p class="li-p1">
                                娱乐行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                Ntertainment
                            </p>
                        </div>
                    </li>
                </ul>
                <ul style="width:105rem;display:flex;justify-content:space-around;margin:0 auto;margin-top:3rem">
                    <li class="li-6">
                        <div class="li-content">
                            <p class="li-p1">
                                珠宝行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                Jewellery
                            </p>
                        </div>
                    </li>
                    <li class="li-7">
                        <div class="li-content">
                            <p class="li-p1">
                                教育行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                Education
                            </p>
                        </div>
                    </li>
                    <li class="li-8">
                        <div class="li-content">
                            <p class="li-p1">
                                服饰行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                dress
                            </p>
                        </div>
                    </li>
                    <li class="li-9">
                        <div class="li-content">
                            <p class="li-p1">
                                母婴行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                baby
                            </p>
                        </div>
                    </li>
                    <li class="li-10">
                        <div class="li-content">
                            <p class="li-p1">
                                其他行业
                            </p>
                            <p class="li-border"></p>
                            <p class="li-p2">
                                Other
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bg">
            <div class="bg-box">
                <div :class="$i18n.locale == 'en' ? 'bg-content1' : 'bg-content'">
                    <p class="bg-p1">
                        <!-- 合作伙伴 -->
                        {{ $t('index.hz') }}
                    </p>
                    <p class="bg-p2">
                        <!-- 唐库收银将与众多合作伙伴携手，为您提供更专业的系统服务 -->
                        {{ $t('index.hz1') }}
                    </p>
                </div>
                <ul style="width:110rem;display:flex;justify-content:space-around;">
                    <li>
                        <img src="../assets/css/img/logo_01.png">
                        <p>贵州农信</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_02.png">
                        <p>农业银行</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_03.png">
                        <p>民生银行</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_04.png">
                        <p>航天税控</p>
                    </li>
                </ul>
                <ul style="width:110rem;display:flex;justify-content:space-around;">
                    <li>
                        <img src="../assets/css/img/logo_05.png">
                        <p>拉卡拉</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_06.png">
                        <p>随行付</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_07.png" alt="">
                        <p>捷付睿通</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_08.png">
                        <p>乌兹别克斯坦</p>
                    </li>
                </ul>
                <ul style="width:110rem;display:flex;justify-content:space-around;">
                    <li>
                        <img src="../assets/css/img/logo_09.png">
                        <p>百富</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_10.png">
                        <p>惠尔丰</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_11.png">
                        <p>联迪</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_12.png">
                        <p>商米</p>
                    </li>
                </ul>
                <ul style="width:110rem;display:flex;justify-content:space-around;">
                    <li>
                        <img src="../assets/css/img/logo_13.png">
                        <p>太平洋咖啡</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_14.png">
                        <p>金沙酒店</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_15.png">
                        <p>沙县小吃</p>
                    </li>
                    <li>
                        <img src="../assets/css/img/logo_16.png">
                        <p>文思海辉</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
export default {
    name: 'index',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
        //轮播图
        new Swiper('.swiper-container', {
            //direction: 'vertical', // 垂直切换选项
            loop: true, // 循环模式选项
            autoplay: {
                delay: 3000,//3秒切换一次
            },
            // 如果需要分页器
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            }
        })
    }
}
</script>
<style lang='less' scoped>
.active{
    display:inline;
}
.active1{
    display:none;
}
.zh {
    width: 21rem;
    font-size: 1.4rem;
    line-height: 3.5rem;
    color: #515B6B;
    // border:solid red 1px;
    margin: 0 auto;
}

/deep/ .swiper-pagination .swiper-pagination-bullet {
    width: 3rem;
    height: 0.9rem;
    border-radius: 1rem;
    display: inline-block;
    opacity: 1;
    background: #FFFFFF;
}

/deep/ .swiper-pagination .swiper-pagination-bullet-active {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 0.7rem;
    display: inline-block;
    opacity: 1;
    background: #FC8D00;
}

/deep/ .swiper-slide {
    width: 100%;

    img {
        width: 100%;
    }
}

//合作伙伴
.bg {
    // width:100%;
    background: url(../assets/css/img/bg.png) no-repeat;
    // background-position:center center;
    // height: 70rem;

    .bg-box {
        width: 110rem;
        margin: 0 auto;
        border-top: solid 1px #FFFFFF;

        .bg-content {
            width: 110rem;
            margin: 0 auto;
            margin-top: 5rem;
            margin-bottom: 5rem;
            text-align: center;

            .bg-p1 {
                font-size: 3.6rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                margin-bottom: 0rem;
            }

            .bg-p2 {
                font-size: 2.2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
        }

        .bg-content1 {
            width: 110rem;
            margin: 0 auto;
            margin-top: 5rem;
            margin-bottom: 2rem;
            text-align: center;

            .bg-p1 {
                font-size: 3.6rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                margin-bottom: 0rem;
            }

            .bg-p2 {
                font-size: 2.2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }

    ul {
        margin: 0 auto;
    }

    li {
        width: 25.5rem;
        height: 11rem;
        border-radius: 0.6rem;
        overflow: hidden;
        // padding-top: 0.5rem;
        background: #FFFFFF;
        margin-bottom: 2rem;
        text-align: center;

        img {
            width: 100%;
        }

        p {
            font-size: 1.8rem;
            font-family: Source Han Sans CN;
            font-weight: 700;
            color: #000000;
        }
    }
}

//场景
.section1-content {
    width: 100%;
    height: 60rem;
    background: #FFFFFF;

    .section1-content-1 {
        width: 120rem;
        height: 50rem;
        margin: 0 auto;
        margin-top: -5rem;
        padding-top: 5rem;

        li {
            width: 18rem;
            height: 18rem;
            padding-top: 8rem;
        }

        .li-1 {
            background: url(../assets/css/img/icon11.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }
            }
        }

        .li-1:hover {
            background: url(../assets/css/img/icon11_11.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }

        .li-2 {
            background: url(../assets/css/img/icon15.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }
            }
        }

        .li-2:hover {
            background: url(../assets/css/img/icon15_15.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }

        .li-3 {
            background: url(../assets/css/img/icon2.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }
            }
        }

        .li-3:hover {
            background: url(../assets/css/img/icon2_2.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }

        .li-4 {
            background: url(../assets/css/img/icon12.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }

            }
        }

        .li-4:hover {
            background: url(../assets/css/img/icon12_12.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }

        .li-5 {
            background: url(../assets/css/img/icon5.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }
            }
        }

        .li-5:hover {
            background: url(../assets/css/img/icon5_5.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }

        .li-6 {
            background: url(../assets/css/img/icon6.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }
            }
        }

        .li-6:hover {
            background: url(../assets/css/img/icon6_6.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }

        .li-7 {
            background: url(../assets/css/img/icon7.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }
            }
        }

        .li-7:hover {
            background: url(../assets/css/img/icon7_7.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }

        .li-8 {
            background: url(../assets/css/img/icon13.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }
            }
        }

        .li-8:hover {
            background: url(../assets/css/img/icon13_13.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }

        .li-9 {
            background: url(../assets/css/img/icon14.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }
            }
        }

        .li-9:hover {
            background: url(../assets/css/img/icon14_14.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }

        .li-10 {
            background: url(../assets/css/img/icon10.png) no-repeat;
            background-position: 50% 25%;
            background-color: #F2F3F4;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #121212;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #646464;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #D5D5D5;
                    margin: 0 auto;
                }
            }
        }

        .li-10:hover {
            background: url(../assets/css/img/icon10_10.png) no-repeat;
            background-position: 50% 25%;
            background-color: #4392FE;

            .li-content {
                width: 18rem;
                height: 3rem;
                text-align: center;

                .li-p1 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }

                .li-p2 {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.2rem;
                }

                .li-border {
                    width: 2rem;
                    border: solid 1px #FFFFFF;
                    margin: 0 auto;
                }
            }
        }
    }
}

.swiper-container {
    width: 100%;
    overflow: hidden;
    // height: 50rem;
    margin: 0 auto;
}

.swiper-image1 {
    width: 100%;
    // max-width: 169rem;
    margin: 0 auto;
    text-align: center;
    position: relative;
    overflow: hidden;

    .swiper-box {
        width: 100rem;
        height: 30rem;
        position: absolute;
        left: 35rem;
        top: 2rem;

        .div-border {
            width: 26rem;
            height: 0.1rem;
            border: solid 1px #A0A7AC;
            margin: 0 auto;
            margin-top: 3.5rem;
        }

        .content-p1 {
            font-size: 4.5rem;
            font-weight: 700;
            margin-bottom: 2rem;

            .p1-span1 {
                margin-left: 11rem;
                margin-right: 2rem;
                color: #FFFFFF;
            }

            .p1-span2 {
                color: #602D29;
            }
        }

        .content-p1-1 {
            width: 110rem;
            font-size: 3.7rem;
            font-weight: 700;
            margin-bottom: 2rem;

            .p1-span1 {
                margin-left: -2.5rem;
                margin-right: 2rem;
                color: #FFFFFF;
            }

            .p1-span2 {
                color: #602D29;
            }
        }

        .content-p2 {
            font-size: 2.3rem;
            font-weight: 700;
            margin-bottom: 1rem;

            .p2-span1 {
                margin-left: -2rem;
                margin-right: 3rem;
                color: #FFFFFF;
            }

            .p2-span2 {
                color: #602D29;
            }
        }
    }
}

.swiper-image2 {
    width: 100%;
    margin: 0 auto;
    position: relative;

    .content {
        position: absolute;
        left: 110rem;
        top: 17rem;
        width: 50rem;
        height: 30rem;
        text-align: center;

        .content-p1 {
            font-size: 5rem;
            letter-spacing: 0.8rem;
            font-weight: 700;
            color: #7c4505;
        }

        .content-p1-1 {
            // border:solid;
            font-size: 4.5rem;
            letter-spacing: 0.5rem;
            font-weight: 700;
            color: #7c4505;
        }

        .content-p2 {
            font-size: 2.3rem;
            color: #A68260;
        }

        .p-border {
            width: 36rem;
            height: 0.4rem;
            background: #ECDFD0;
            margin: 0 auto;
            margin-top: 4rem;
            margin-bottom: 2rem;
        }
    }
}

.swiper-image3 {
    width: 100%;
    margin: 0 auto;
    position: relative;

    .content {
        position: absolute;
        left: 120rem;
        top: 20rem;
        width: 40rem;
        height: 30rem;
        text-align: center;

        .content-p1 {
            font-size: 4.2rem;
            letter-spacing: 0.4rem;
            font-weight: 700;
            color: #FFFFFF;
        }
        .content-p1-1{
            // width:45rem;
            font-size:3.3rem;
            letter-spacing:0.4rem;
            font-weight:700;
            color:#FFFFFF;
        }
        .content-p2 {
            font-size: 2.3rem;
            color: #FFFFFF;
        }

        .p-border {
            width: 33rem;
            height: 0.4rem;
            background: #ECDFD0;
            margin: 0 auto;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }
}

.section-1 {
    width: 100%;
    margin: 0 auto;
    background: #F3F4F5;
    // padding-top: 2rem;

    //header 内容
    .content {
        width: 100rem;
        // height: 17rem;
        text-align: center;
        margin: 0 auto;
        margin-top: 5rem;
        margin-bottom: 3rem;

        .content-p1 {
            font-size: 3.6rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #121212;
            margin-bottom: 0rem;
        }

        .content-p2 {
            font-size: 2.2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
        }
    }
}

.section-2 {
    width: 100%;
    background: #F3F4F5;
    margin: 0 auto;
}

.box {
    width: 120rem;
    height: 45rem;
    background: #F3F4F5;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .box-1 {
        width: 24rem;
        height: 43rem;
        border: solid 1px#E6E5E5;
        background: #FFFFFF;

        img {
            width: 100%;
        }

        .box-content {
            width: 24rem;
            text-align: center;

            // margin-left:1rem;
            .box-p1 {
                font-size: 1.8rem;
                font-weight: 700;
                margin-top: 3.5rem;
                margin-bottom: 2rem;
            }

            .box-p2 {
                font-size: 1.4rem;
                line-height: 2.5rem;
                color: #515B6B;
            }
        }

    }

    .box-1:hover {
        transition: all .5s;
        transform: scale(1.2);
        box-shadow: 1px 0px 5px 1px #E5E6E7;
    }

}

@media screen and (max-width:1556px) {
    .swiper-container {
        width: 100%;
        // height: 50rem;
        margin: 0 auto;
    }

    .swiper-image1 {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        position: relative;

        .swiper-box {
            width: 100rem;
            height: 30rem;
            position: absolute;
            left: 15%;
            top: 2rem;

            .div-border {
                width: 26rem;
                height: 0.1rem;
                border: solid 1px #A0A7AC;
                margin: 0 auto;
                margin-top: 3.5rem;
            }

            .content-p1 {
                font-size: 4.5rem;
                font-weight: 700;
                margin-bottom: 2rem;

                .p1-span1 {
                    margin-left: 11rem;
                    margin-right: 2rem;
                    color: #FFFFFF;
                }

                .p1-span2 {
                    color: #602D29;
                }
            }

            .content-p1-1 {
                width: 110rem;
                font-size: 3.7rem;
                font-weight: 700;
                margin-bottom: 2rem;

                .p1-span1 {
                    margin-left: -2.5rem;
                    margin-right: 2rem;
                    color: #FFFFFF;
                }

                .p1-span2 {
                    color: #602D29;
                }
            }

            .content-p2 {
                font-size: 1.8rem;
                font-weight: 700;
                margin-bottom: 1rem;

                .p2-span1 {
                    margin-left: -2rem;
                    margin-right: 3rem;
                    color: #FFFFFF;
                }

                .p2-span2 {
                    color: #602D29;
                }
            }
        }
    }

    .swiper-image2 {
        width: 100%;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 60%;
            top: 20%;
            width: 45rem;
            height: 30rem;
            text-align: center;

            .content-p1 {
                font-size: 5rem;
                letter-spacing: 0.8rem;
                font-weight: 700;
                color: #7c4505;
            }

            .content-p1-1 {
                // border:solid;
                font-size: 4rem;
                letter-spacing: 0.5rem;
                font-weight: 700;
                color: #7c4505;
            }

            .content-p2 {
                font-size: 2.3rem;
                color: #A68260;
            }

            .p-border {
                width: 36rem;
                height: 0.4rem;
                background: #ECDFD0;
                margin: 0 auto;
                margin-top: 4rem;
                margin-bottom: 2rem;
            }
        }
    }

    .swiper-image3 {
        width: 100%;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 70%;
            top: 25%;
            width: 40rem;
            height: 30rem;
            text-align: center;

            .content-p1 {
                font-size: 4.2rem;
                letter-spacing: 0.4rem;
                font-weight: 700;
                color: #FFFFFF;
            }

            .content-p2 {
                font-size: 2rem;
                color: #FFFFFF;
            }

            .p-border {
                width: 33rem;
                height: 0.4rem;
                background: #9CA79D;
                margin: 0 auto;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }
    }
}

@media screen and (max-width:1320px) {
    .swiper-image1 {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        position: relative;

        .swiper-box {
            .content-p1-1 {
                width: 110rem;
                font-size: 3.3rem;
                font-weight: 700;
                margin-bottom: 2rem;

                .p1-span1 {
                    margin-left: -2.5rem;
                    margin-right: 2rem;
                    color: #FFFFFF;
                }

                .p1-span2 {
                    color: #602D29;
                }
            }
        }
    }
    .swiper-image3{
        .content-p1-1{
            // width:45rem;
            font-size:3rem;
            letter-spacing:0.4rem;
            font-weight:700;
            color:#FFFFFF;
        }
    }
}

@media screen and (max-width:1216px) {
    .swiper-image3 {
        width: 100%;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 65%;
            top: 25%;
            width: 40rem;
            height: 30rem;
            text-align: center;

            .content-p1 {
                font-size: 4.2rem;
                letter-spacing: 0.4rem;
                font-weight: 700;
                color: #FFFFFF;
            }

            .content-p2 {
                font-size: 2rem;
                color: #FFFFFF;
            }

            .p-border {
                width: 33rem;
                height: 0.4rem;
                background: #9CA79D;
                margin: 0 auto;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }
    }

    .swiper-image2 {
        width: 100%;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 60%;
            top: 20%;
            width: 40rem;
            height: 30rem;
            text-align: center;

            .content-p1 {
                font-size: 4.5rem;
                letter-spacing: 0.6rem;
                font-weight: 700;
                color: #7c4505;
            }

            .content-p1-1 {
                // border:solid;
                font-size: 3.7rem;
                letter-spacing: 0.5rem;
                font-weight: 700;
                color: #7c4505;
            }

            .content-p2 {
                font-size: 2rem;
                color: #A68260;
            }

            .p-border {
                width: 36rem;
                height: 0.4rem;
                background: #ECDFD0;
                margin: 0 auto;
                margin-top: 4rem;
                margin-bottom: 2rem;
            }
        }
    }

    .swiper-image1 {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        position: relative;

        .swiper-box {
            width: 100rem;
            height: 30rem;
            position: absolute;
            left: 8%;
            top: 2rem;

            .div-border {
                width: 26rem;
                height: 0.1rem;
                border: solid 1px #A0A7AC;
                margin: 0 auto;
                margin-top: 1.5rem;
            }

            .content-p1 {
                font-size: 4.5rem;
                font-weight: 700;
                margin-bottom: 1.5rem;

                .p1-span1 {
                    margin-left: 11rem;
                    margin-right: 2rem;
                    color: #FFFFFF;
                }

                .p1-span2 {
                    color: #602D29;
                }
            }

            .content-p1-1 {
                width: 110rem;
                font-size: 3.5rem;
                font-weight: 700;
                margin-bottom: 2rem;

                .p1-span1 {
                    margin-left: -2.5rem;
                    margin-right: 2rem;
                    color: #FFFFFF;
                }

                .p1-span2 {
                    color: #602D29;
                }
            }

            .content-p2 {
                font-size: 1.8rem;
                font-weight: 700;
                margin-bottom: 1rem;

                .p2-span1 {
                    margin-left: -1.3rem;
                    margin-right: 3rem;
                    color: #FFFFFF;
                }

                .p2-span2 {
                    color: #602D29;
                }
            }
        }
    }

    .section-1 {
        width: 100%;
        background: #F3F4F5;
    }

    .section-2 {
        width: 100%;
        background: #F3F4F5;
    }

    .box {
        width: 100%;
        height: 45rem;
        background: #F3F4F5;
        display: flex;
        justify-content: space-between;

        .box-1 {
            width: 24%;
            height: 43rem;
            border: solid 1px#E6E5E5;

            img {
                width: 100%;
            }

            .box-content {
                width: 100%;
                // height: 20rem;
                overflow: hidden;
                text-align: center;

                .box-p1 {
                    font-size: 1.8rem;
                    font-weight: 700;
                    margin-top: 3.5rem;
                    margin-bottom: 2rem;
                }

                .box-p2 {
                    font-size: 1.4rem;
                    line-height: 2.5rem;
                    color: #515B6B;
                }
            }
        }

        .box-1:hover {
            transition: all .5s;
            transform: scale(1.1);
            box-shadow: 1px 0px 5px 1px #E5E6E7;
        }
    }

}

@media screen and (max-width:1164px) {
    .swiper-container {
        width: 1164;
        // height: 50rem;
        margin: 0 auto;
    }

    .swiper-image1 {
        width: 116.3rem;
        margin: 0 auto;
        text-align: center;
        position: relative;

        .swiper-box {
            width: 100rem;
            height: 30rem;
            position: absolute;
            left: 2rem;
            top: 2rem;

            .div-border {
                width: 26rem;
                height: 0.1rem;
                border: solid 1px #A0A7AC;
                margin: 0 auto;
                margin-top: 3.5rem;
            }

            .content-p1 {
                font-size: 4.5rem;
                font-weight: 700;
                margin-bottom: 2rem;

                .p1-span1 {
                    margin-left: 11rem;
                    margin-right: 2rem;
                    color: #FFFFFF;
                }

                .p1-span2 {
                    color: #602D29;
                }
            }

            .content-p1-1 {
                width: 110rem;
                font-size: 3.6rem;
                font-weight: 700;
                margin-bottom: 2rem;

                .p1-span1 {
                    margin-left: -2.5rem;
                    margin-right: 2rem;
                    color: #FFFFFF;
                }

                .p1-span2 {
                    color: #602D29;
                }
            }

            .content-p2 {
                font-size: 1.8rem;
                font-weight: 700;
                margin-bottom: 1rem;

                .p2-span1 {
                    margin-left: -1.3rem;
                    margin-right: 3rem;
                    color: #FFFFFF;
                }

                .p2-span2 {
                    color: #602D29;
                }
            }
        }
    }

    .swiper-image2 {
        width: 116.3rem;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 60%;
            top: 10rem;
            width: 35rem;
            height: 30rem;
            text-align: center;

            .content-p1 {
                font-size: 3rem;
                letter-spacing: 0.8rem;
                font-weight: 700;
                color: #7c4505;
            }

            .content-p1-1 {
                // border:solid;
                font-size: 3rem;
                letter-spacing: 0.5rem;
                font-weight: 700;
                color: #7c4505;
            }

            .content-p2 {
                font-size: 1.6rem;
                color: #A68260;
            }

            .p-border {
                width: 25rem;
                height: 0.4rem;
                background: #ECDFD0;
                margin: 0 auto;
                margin-top: 4rem;
                margin-bottom: 2rem;
            }
        }
    }

    .swiper-image3 {
        width: 116.3rem;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 60%;
            top: 25%;
            width: 40rem;
            height: 30rem;
            text-align: center;

            .content-p1 {
                font-size: 3rem;
                letter-spacing: 0.4rem;
                font-weight: 700;
                color: #FFFFFF;
            }

            .content-p2 {
                font-size: 1.8rem;
                color: #FFFFFF;
            }

            .p-border {
                width: 30rem;
                height: 0.4rem;
                background: #9CA79D;
                margin: 0 auto;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }
    }

}

@media screen and (max-width:760px) {
    .box {
        width: 76rem;
        height: 45rem;
        background: #F3F4F5;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .box-1 {
            width: 24%;
            height: 40rem;
            border: solid 1px#E6E5E5;
            background: #FFFFFF;

            img {
                width: 100%;
            }

            .box-content {
                width: 100%;
                text-align: center;

                .box-p1 {
                    font-size: 1.8rem;
                    font-weight: 700;
                    margin-top: 3.5rem;
                    margin-bottom: 2rem;
                }

                .box-p2 {
                    font-size: 1.4rem;
                    line-height: 2.5rem;
                    color: #515B6B;
                }
            }

        }

        .box-1:hover {
            transition: all .5s;
            transform: scale(1.1);
            box-shadow: 1px 0px 5px 1px #E5E6E7;
        }

    }
}

@media screen and (min-width:1710px) {
    .swiper-image1 {
        width: 100%;
        // max-width: 169rem;
        margin: 0 auto;
        text-align: center;
        position: relative;
        overflow: hidden;

        .swiper-box {
            width: 100rem;
            height: 30rem;
            position: absolute;
            left: 24%;
            top: 2rem;

            .div-border {
                width: 26rem;
                height: 0.1rem;
                border: solid 1px #A0A7AC;
                margin: 0 auto;
                margin-top: 3.5rem;
            }

            .content-p1 {
                font-size: 4.5rem;
                font-weight: 700;
                margin-bottom: 2rem;

                .p1-span1 {
                    margin-left: 11rem;
                    margin-right: 2rem;
                    color: #FFFFFF;
                }

                .p1-span2 {
                    color: #602D29;
                }
            }

            .content-p2 {
                font-size: 2.3rem;
                font-weight: 700;
                margin-bottom: 1rem;

                .p2-span1 {
                    margin-left: -1.3rem;
                    margin-right: 3rem;
                    color: #FFFFFF;
                }

                .p2-span2 {
                    color: #602D29;
                }
            }
        }
    }

    .swiper-image2 {
        width: 100%;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 66%;
            top: 30%;
            width: 50rem;
            height: 30rem;
            text-align: center;

            .content-p1 {
                font-size: 5rem;
                letter-spacing: 0.8rem;
                font-weight: 700;
                color: #7c4505;
            }

            .content-p2 {
                font-size: 2.3rem;
                color: #A68260;
            }

            .p-border {
                width: 36rem;
                height: 0.4rem;
                background: #ECDFD0;
                margin: 0 auto;
                margin-top: 4rem;
                margin-bottom: 2rem;
            }
        }
    }

    .swiper-image3 {
        width: 100%;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 70%;
            top: 20rem;
            width: 40rem;
            height: 30rem;
            text-align: center;

            .content-p1 {
                font-size: 4.2rem;
                letter-spacing: 0.4rem;
                font-weight: 700;
                color: #FFFFFF;
            }

            .content-p2 {
                font-size: 2.3rem;
                color: #FFFFFF;
            }

            .p-border {
                width: 33rem;
                height: 0.4rem;
                background: #9CA79D;
                margin: 0 auto;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }
    }

}</style>