<template>
    <div class="section" style="text-align: center;">
        <div class="box-section">
            <img src="../assets/css/img/2-3-1.png" style="width:100%;" />
            <div class="box">
                <p :class="$i18n.locale == 'en' ? 'box-p1-1' : 'box-p1'">
                    <!-- 厨房系统 -->
                    {{ $t('kitchen.content1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'box-p1-1' : 'box-p1'">
                    <!-- 分厨打印，高效 -->
                    {{ $t('kitchen.content2') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'box-p2-1' : 'box-p2'">
                    <!-- 智能控菜，杜绝漏单 -->
                    {{ $t('kitchen.content3') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'box-sub-1' : 'box-sub'">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                        height="25px" style="vertical-align: middle;">
                        <path fill-rule="evenodd" fill="rgb(242, 243, 244)"
                            d="M22.005,10.070 L15.714,10.070 L15.714,0.640 L6.280,0.640 L6.280,10.070 L-0.010,10.070 L10.997,21.071 L22.002,10.070 L22.005,10.070 ZM-0.010,24.214 L-0.010,27.358 L22.005,27.358 L22.005,24.214 L-0.010,24.214 Z" />
                    </svg>
                    <span>
                        <!-- 软件下载 -->
                        {{ $t('kitchen.content4') }}
                    </span>
                </p>
            </div>
        </div>
        <div class="content">
            <p class="content-p1">
                <!-- 多种菜品显示模式 -->
                {{ $t('kitchen.cp') }}
            </p>
            <p class="content-p2">
                <!-- 可选择多种不同方式菜品显示：按订单展示、按菜品展示和按房间展示 -->
                {{ $t('kitchen.cp1') }}

            </p>
            <p class="content-p2">
                <!-- 将同样的菜按时间顺序自动汇总，厨师只需按照显示的数量制作，告别重复劳动，效率大大提升。 -->
                {{ $t('kitchen.cp2') }}
            </p>
        </div>
        <div class="image-1">
            <img src="../assets/css/img/2-3-2.png" />
        </div>
        <div class="content">
            <p class="content-p1">
                <!-- 点击改变菜品进度，提高工作效率 -->
                {{ $t('kitchen.cp3') }}
            </p>
            <p class="content-p2">
                <!-- 出菜超过预设时间，系统自动催菜，厨师单击改变菜品的制作进度， -->
                {{ $t('kitchen.cp4') }}
            </p>
            <p class="content-p2">
                <!-- 厨房系统（KDS）,配菜系统（ADS）数据同步显示 -->
                {{ $t('kitchen.cp5') }}
            </p>
        </div>
        <img src="../assets/css/img/2-3-3.png" class="img1" style="margin-top:1rem;" />
        <div class="content">
            <p class="content-p1">
                <!-- 大屏幕叫号 -->
                {{ $t('kitchen.dp') }}
            </p>
            <p class="content-p2">
                <!-- 桌位管理 - 电子厨显 - 叫号系统 - 叫号系统 - 智能控菜 - 餐品智能分类 -->
                {{ $t('kitchen.dp1') }}

            </p>
            <p class="content-p2">
                <!-- 订单智能动态 - 桌位一键呼叫 - 餐品分时库存 -->
                {{ $t('kitchen.dp2') }}

            </p>
        </div>
        <div class="image-1" style="margin-bottom:3rem">
            <img src="../assets/css/img/2-3-4.png" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'kitchen',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang="less" scoped>
.section {
    width: 100%;
    height: 100%;
    // position: relative;
}

.image-1 {
    width: 70rem;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 5rem;

    img {
        width: 100%;
    }
}

.box-section {
    width: 100%;
    // max-width:170rem;
    margin: 0 auto;
    position: relative;
}

.box {
    width: 50rem;
    height: 40rem;
    // border: solid;
    position: absolute;
    left: 30rem;
    top: 15rem;
    margin: 0 auto;

    .box-p1 {
        font-size: 4rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 7rem;
        text-align: center;
    }

    .box-p1-1 {
        font-size: 4rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 5rem;
        text-align: center;
    }

    .box-p2-1 {
        margin: 0 auto;
        margin-top: 1.5rem;
        width: 35rem;
        font-size: 2.3rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 5rem;
        text-align: center;
        color: #FFFFFF;
    }

    .box-sub-1 {
        width: 25rem;
        height: 4.5rem;
        border-radius: 1.1rem;
        background-color: #4591fe;
        font-size: 2.2rem;
        color: #FFFFFF;
        line-height: 4.5rem;
        margin: 0 auto;
        margin-top: 4rem;
    }

    .box-p2 {
        font-size: 2.6rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 7rem;
        text-align: center;
    }

    .box-sub {
        width: 15rem;
        height: 4.5rem;
        border-radius: 1.1rem;
        background-color: #4591fe;
        font-size: 2.2rem;
        color: #FFFFFF;
        line-height: 4.5rem;
        margin: 0 auto;
        margin-top: 4rem;
    }
}

.img1 {
    width: 100%;
}

.content {
    width: 100rem;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 5rem;

    .content-p1 {
        font-size: 3.6rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        line-height: 6rem;
    }

    .content-p2 {
        font-size: 2.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
    }
}

//1456px屏幕大小的变化
@media screen and (max-width:1456px) {
    .img1 {
        width: 100%;
    }


    .box-section {
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
    .box {
        width: 20%;
        height: 50%;
        position: absolute;
        left: 22.5%;
        top: 20%;
        margin: 0 auto;

        .box-p1 {
            font-size: 3rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 5rem;
            text-align: center;
        }

        .box-p1-1 {
            font-size: 3rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 4rem;
            text-align: center;
        }

        .box-p2-1 {
            margin: 0 auto;
            margin-top: 1.5rem;
            margin-left:-2.9rem;
            width: 35rem;
            font-size: 1.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 5rem;
            text-align: center;
            color: #FFFFFF;
        }

        .box-sub-1 {
            width: 25rem;
            height: 4.5rem;
            border-radius: 1.1rem;
            background-color: #4591fe;
            font-size: 2rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            margin: 0 auto;
            margin-top: 4rem;
        }

        .box-p2 {
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4rem;
            text-align: center;
        }

        .box-sub {
            font-size: 2rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            // padding-left: 4.5rem;
            margin: 0 auto;
            margin-top: 4rem;
        }
    }
}
@media screen and (max-width:1300px){
    .box {
        .box-p1-1 {
            font-size: 2.5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 4rem;
            text-align: center;
        }
        .box-p2-1 {
            margin: 0 auto;
            margin-top: 1.5rem;
            margin-left:-0.5rem;
            width: 25rem;
            font-size: 1.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 3rem;
            text-align: center;
            color: #FFFFFF;
        }
        .box-sub-1 {
            width: 20rem;
            height: 4rem;
            border-radius: 1.1rem;
            background-color: #4591fe;
            font-size: 1.6rem;
            color: #FFFFFF;
            line-height: 4rem;
            margin: 0 auto;
            margin-top: 2rem;
        }
        .box-p2 {
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4rem;
            text-align: center;
        }
    }
}
@media screen and (max-width:1200px) {
    .img1 {
        width: 118rem;
    }
    .box {
        .box-p1-1 {
            font-size: 2.5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 4rem;
            text-align: center;
        }
        .box-p2-1 {
            margin: 0 auto;
            margin-top: 1.5rem;
            margin-left:-0.5rem;
            width: 25rem;
            font-size: 1.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 3rem;
            text-align: center;
            color: #FFFFFF;
        }
        .box-sub-1 {
            width: 20rem;
            height: 4rem;
            border-radius: 1.1rem;
            background-color: #4591fe;
            font-size: 1.6rem;
            color: #FFFFFF;
            line-height: 4rem;
            margin: 0 auto;
            margin-top: 2rem;
        }
        .box-p2 {
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4rem;
            text-align: center;
        }
    }
}

// 1136 屏幕大小的变化
@media screen and (max-width:1136px) {
    .box {
        position: absolute;
        left: 22.5%;
        top: 5rem;

        .box-p1 {
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 5rem;
            text-align: center;
        }

        .box-p2 {
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4rem;
            text-align: center;
        }

        .box-sub {
            width: 13rem;
            font-size: 1.5rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            // padding-left: 4.5rem;
            margin: 0 auto;
            margin-top: 3rem;
        }
    }
}

// 1014 屏幕大小的变化
@media screen and (max-width:1015px) {
    .box {
        position: absolute;
        left: 23%;
        top: 5rem;

        .box-p1 {
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 5rem;
            text-align: center;
        }

        .box-p2 {
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4rem;
            text-align: center;
        }

        .box-sub {
            width: 13rem;
            font-size: 1.5rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            // padding-left: 4.5rem;
            margin: 0 auto;
            margin-top: 3rem;
        }
    }
}

@media screen and (min-width:1710px) {
    .box {
        width: 50rem;
        height: 40rem;
        // border: solid;
        position: absolute;
        left: 20%;
        top: 25%;
        margin: 0 auto;

        .box-p1 {
            font-size: 4.5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 7.5rem;
            text-align: center;
        }

        .box-p2 {
            font-size: 2.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 7.5rem;
            text-align: center;
        }

        .box-sub {
            width: 15rem;
            height: 4.5rem;
            border-radius: 1.1rem;
            // background: url('../assets/css/img/download.png') no-repeat;
            // background-size: 15%;
            // background-position: 1.5rem;
            background-color: #4591fe;
            font-size: 2.2rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            // padding-left: 4.5rem;
            margin: 0 auto;
            margin-top: 4rem;
        }
    }
}

@media screen and (min-width:1900px) {
    .box {
        width: 50rem;
        height: 40rem;
        // border: solid;
        position: absolute;
        left: 20%;
        top: 25%;
        margin: 0 auto;

        .box-p1 {
            font-size: 5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 7.5rem;
            text-align: center;
        }

        .box-p2 {
            font-size: 2.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 7.5rem;
            text-align: center;
        }

        .box-sub {
            width: 15rem;
            height: 4.5rem;
            border-radius: 1.1rem;
            // background: url('../assets/css/img/download.png') no-repeat;
            // background-size: 15%;
            // background-position: 1.5rem;
            background-color: #4591fe;
            font-size: 2.2rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            // padding-left: 4.5rem;
            margin: 0 auto;
            margin-top: 4rem;
        }
    }
}</style>
