<template>
    <div class="section">
        <div>
            <div class="box">
                <img src="../assets/css/img/2-2-1.png" style="width:100%">
                <div class="box-content">
                    <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                        <!-- 点餐宝 -->
                        {{ $t('order.swiper1') }}
                    </p>
                    <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                        <!-- 手机点餐更快捷 -->
                        {{ $t('order.swiper2') }}
                    </p>
                    <p :class="$i18n.locale=='en'?'content-p2-1':'content-p2'" style="margin-top:10px;">
                        <!-- 一部手机 -->
                        {{ $t('order.swiper3') }}
                        <span></span>
                    </p>
                    <p :class="$i18n.locale=='en'?'content-p2-1':'content-p2'" style="margin-bottom:30px;">
                        <!-- 就可完成点餐，结账 -->
                        {{ $t('order.swiper4') }}
                    </p>
                    <p :class="$i18n.locale == 'en' ? 'content-sub-1' : 'content-sub'">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                            height="25px" style="vertical-align: middle;">
                            <path fill-rule="evenodd" fill="rgb(242, 243, 244)"
                                d="M22.005,10.070 L15.714,10.070 L15.714,0.640 L6.280,0.640 L6.280,10.070 L-0.010,10.070 L10.997,21.071 L22.002,10.070 L22.005,10.070 ZM-0.010,24.214 L-0.010,27.358 L22.005,27.358 L22.005,24.214 L-0.010,24.214 Z" />
                        </svg>
                        <span>
                            <!-- 软件下载 -->
                            {{ $t('order.swiper5') }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 桌台使用情况一目了然 -->
                {{ $t('order.zt') }}
            </p>
            <p class="content-p2" style="margin-bottom: 10px">
                <!-- 桌台不同状态使用不用颜色区分 -->
                {{ $t('order.zt1') }}
            </p>
            <p class="content-p2">
                <!-- 空闲 占用 预约  -->
                {{ $t('order.zt2') }}
            </p>
        </div>
        <div class="content-image">
            <div class="image-div">
                <p class="image-div-p1">
                    <!-- 不同状态桌台颜色区分 -->
                    {{ $t('order.ztzt') }}
                </p>
                <p class="image-div-p2">
                    <!-- 空闲 &nbsp;&nbsp;&nbsp;占用 &nbsp;&nbsp;&nbsp; 预留 -->
                    {{ $t('order.ztzt1') }}&nbsp;&nbsp;&nbsp;
                    {{ $t('order.ztzt2') }}&nbsp;&nbsp;&nbsp;
                    {{ $t('order.ztzt3') }}
                </p>
            </div>
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 快速点餐 节约时间 -->
                {{ $t('order.dc') }}
            </p>
            <p class="content-p2">
                <!-- 商品分组清晰，快速查找商品，点餐更方便 -->
                {{ $t('order.dc1') }}
            </p>
        </div>
        <div class="content2">
            <div class="content2-div">
                <div class="content">
                    <p class="content-p1">
                        <!-- 让点餐更加简单便捷 -->
                        {{ $t('order.content1') }}
                    </p>
                    <p class="content-p2">
                        <!-- 多场景全流程支持，满足不同场景的业务需要及个性化定制，让点餐 -->
                        {{ $t('order.content2') }}
                        <br />
                        <!-- 很简单；点餐宝应用场景; -->
                        {{ $t('order.content3') }}
                        <br />
                        <!-- 1.堂食点餐，代客点餐，收银台； 2.预定点餐（堂食、打包） -->
                        {{ $t('order.content4') }}
                    </p>
                </div>
            </div>
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 收银机接单，数据同步 -->
                {{ $t('order.data') }}
            </p>
            <p class="content-p2" style="margin-bottom: 10px">
                <!-- 在收银机上同步处理订单无需手动处理，下单后自动打印到厨房，还支持一张订单分厨房分单打印 -->
                {{ $t('order.data1') }}
            </p>
            <p class="content-p2">
                <!-- 支持开台、下单、加菜、退菜等功能，大大提升服务员效率，可管理台位数量 -->
                {{ $t('order.data2') }}
            </p>
        </div>
        <div class="image-1">
            <img src="../assets/css/img/zhuotai.png" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'cash',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang="less" scoped>
.section {
    width: 100%;
    height: 100%;
    // border:solid;
    overflow: hidden;
}

.image-1 {
    width: 50rem;
    // border:solid;
    height: 50rem;
    margin: 0 auto;
    text-align: center;

    img {
        height: 100%;
    }

    margin-bottom:100px;
}

.content3 {
    width: 1200px;
    height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

    div {
        width: 280px;
        height: 460px;
        border-radius: 30px;
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 30px;
    }

    .image2 {
        background: linear-gradient(0deg,
                #f4f7fd,
                #ebf2ff,
                #ebf2ff,
                #f4f7ff,
                #ebf2ff);
    }

    .image1 {
        background: url('../assets/css/img/2-1-2-2.png') no-repeat;
        background-size: cover;
    }

    .image3 {
        background: url('../assets/css/img/2-1-2-1.png') no-repeat;
        background-size: cover;
    }

    .title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        line-height: 33px;
        margin-bottom: 30px;
    }

    .content-P {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #121212;
        line-height: 40px;
    }

    .title1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 33px;
        margin-bottom: 30px;
    }

    .content-P1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
    }
}

.content2 {
    width: 100%;
    height: 500px;
    background: url("../assets/css/img/2-2-3.png") no-repeat;
    background-size: 100%;
    padding-top: 130px;

    .content2-div {
        width: 900px;
        margin: 0 auto;

        .content {
            width: 600px;
            height: 210px;
            margin-left: 350px;

            .content-p1 {
                font-size: 30px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f2f7ff;
                margin-bottom: 35px;
            }

            .content-p2 {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 35px;
                color: #ffffff;
            }
        }
    }

}

.content-image {
    width: 100rem;
    height: 37rem;
    margin: 0 auto;
    background: url('../assets/css/img/2-2-2.png') no-repeat;
    background-size: 100%;
    position: relative;

    .image-div {
        width: 50rem;
        height: 20rem;
        text-align: center;
        position: absolute;
        left: 40rem;
        top: 3rem;

        .image-div-p1 {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 94px;
        }

        .image-div-p2 {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 74px;
        }
    }
}

// 内容样式
.content1 {
    width: 100rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 3rem;

    .content-p1 {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        margin-bottom: 1rem;
    }

    .content-p2 {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
    }
}

.box1 {
    width: 100%;
    height: 450px;
    background: #0A1C2B;
}

.box {
    width: 100%;
    border-top: solid 1px #ffffff;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: center;

    .box-content {
        position: absolute;
        left: 300px;
        top: 130px;
        width: 350px;

        .content-p1 {
            text-align: center;
            font-size: 40px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 60px;
        }

        .content-p1-1 {
            text-align: center;
            font-size: 3.5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 4rem;
        }

        .content-p2-1 {
            position: relative;
            font-size: 2.4rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 46px;
            text-align: center;
        }

        .content-sub-1 {
            margin: 0 auto;
            margin-top: 10px;
            margin-left: 4rem;
            width: 250px;
            height: 50px;
            background-color: #4591fe;
            border-radius: 10px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 50px;

            span {
                margin-left: 0.3rem;
            }
        }

        .content-p2 {
            position: relative;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 43px;
            text-align: center;
        }

        .content-sub {
            margin: 0 auto;
            margin-top: 10px;
            width: 150px;
            height: 50px;
            background-color: #4591fe;
            border-radius: 10px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 50px;

            span {
                margin-left: 0.3rem;
            }
        }
    }
}

@media screen and (max-width:1500px) {
    .section {
        overflow: hidden;
    }

    .box {
        width: 100%;
        border-top: solid 1px #ffffff;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        text-align: center;

        .box-content {
            position: absolute;
            left: 13%;
            top: 15%;
            width: 350px;

            .content-p1 {
                text-align: center;
                font-size: 40px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 60px;
            }

            .content-p2 {
                position: relative;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 43px;
                text-align: center;
            }

            .content-sub {
                // margin-left: 150px;
                margin: 0 auto;
                margin-top: 10px;
                width: 150px;
                height: 50px;
                background-color: #4591fe;
                // background-image: url("../assets/css/img/download.png");
                // background-repeat: no-repeat;
                // background-size: 12%;
                // background-position: 15px;
                border-radius: 10px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 50px;

                // padding-left: 50px;
                span {
                    margin-left: 0.3rem;
                }
            }
        }
    }

    .content2 {
        width: 100%;
        height: 35rem;
        background: url("../assets/css/img/2-2-3.png") no-repeat;
        background-size: 100%;
        padding-top: 10vh;

        .content2-div {
            margin: 0 auto;

            .content {
                margin-left: 33rem;

                .content-p1 {
                    font-size: 2.5rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #f2f7ff;
                    margin-bottom: 3.5rem;
                }

                .content-p2 {
                    font-size: 1.8rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 3.5rem;
                    color: #ffffff;
                }
            }

        }
    }

    // .content-image {
    //     width: 100%;
    //     height: ;
    //     // margin:0 auto;
    //     background: url('../assets/css/img/2-2-2.png') no-repeat;
    //     background-size: 100%;
    //     position: relative;

    //     .image-div {
    //         width: 25%;
    //         height: 200px;
    //         text-align: center;
    //         position: absolute;
    //         left: 42%;
    //         top: 30px;

    //         .image-div-p1 {
    //             font-size: 20px;
    //             font-family: Microsoft YaHei;
    //             font-weight: 400;
    //             color: #ffffff;
    //             line-height: 94px;
    //         }

    //         .image-div-p2 {
    //             font-size: 30px;
    //             font-family: Microsoft YaHei;
    //             font-weight: bold;
    //             color: #ffffff;
    //             line-height: 74px;
    //         }
    //     }
    // }


    .content3 {
        width: 100%;
        height: 500px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        div {
            width: 23%;
            height: 460px;
            border-radius: 30px;
            padding-top: 40px;
            padding-left: 3%;
            padding-right: 3%;
        }

        .image2 {
            background: linear-gradient(0deg,
                    #f4f7fd,
                    #ebf2ff,
                    #ebf2ff,
                    #f4f7ff,
                    #ebf2ff);
        }

        .image1 {
            background: url('../assets/css/img/2-1-2-2.png') no-repeat;
            background-size: cover;
        }

        .image3 {
            background: url('../assets/css/img/2-1-2-1.png') no-repeat;
            background-size: cover;
        }

        .title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #121212;
            line-height: 33px;
            margin-bottom: 30px;
        }

        .content-P {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #121212;
            line-height: 40px;
        }

        .title1 {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
            margin-bottom: 30px;
        }

        .content-P1 {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
        }
    }
}

@media screen and (max-width:1200px) {
    .box {
        width: 120rem;
        border-top: solid 1px #ffffff;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        text-align: center;

        .box-content {
            position: absolute;
            left: 13%;
            top: 15%;
            width: 350px;

            .content-p1 {
                text-align: center;
                font-size: 40px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 60px;
            }

            .content-p2 {
                position: relative;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 43px;
                text-align: center;
            }

            .content-sub {
                // margin-left: 150px;
                margin: 0 auto;
                margin-top: 10px;
                width: 150px;
                height: 50px;
                background-color: #4591fe;
                // background-image: url("../assets/css/img/download.png");
                // background-repeat: no-repeat;
                // background-size: 12%;
                // background-position: 15px;
                border-radius: 10px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 50px;

                // padding-left: 50px;
                span {
                    margin-left: 0.3rem;
                }
            }
        }
    }
}

@media screen and (max-width:673px) {
    .content3 {
        width: 670px;
        height: 500px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        div {
            width: 23%;
            height: 460px;
            border-radius: 30px;
            padding-top: 40px;
            padding-left: 3%;
            padding-right: 3%;
        }
    }
}

@media screen and (min-width:1710px) {
    .box {
        width: 100%;
        border-top: solid 1px #ffffff;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        text-align: center;

        .box-content {
            position: absolute;
            left: 20%;
            top: 25%;
            width: 350px;

            .content-p1 {
                text-align: center;
                font-size: 5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 65px;
            }

            .content-p2-1 {
                position: relative;
                font-size: 2.4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 46px;
                text-align: center;
            }

            .content-p2 {
                position: relative;
                font-size: 3rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 46px;
                text-align: center;
            }

            .content-sub {
                margin: 0 auto;
                margin-top: 10px;
                width: 150px;
                height: 50px;
                background-color: #4591fe;
                // background-image: url("../assets/css/img/download.png");
                // background-repeat: no-repeat;
                // background-size: 12%;
                // background-position: 15px;
                border-radius: 10px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 50px;

                // padding-left: 50px;
                span {
                    margin-left: 0.3rem;
                }
            }
        }
    }

    .content2 {
        width: 100%;
        height: 60rem;
        background: url("../assets/css/img/2-2-3.png") no-repeat;
        background-size: 100%;
        padding-top: 15rem;

        .content2-div {
            margin: 0 auto;

            .content {
                margin-left: 33rem;

                .content-p1 {
                    font-size: 2.7rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #f2f7ff;
                    margin-bottom: 3.5rem;
                }

                .content-p2 {
                    font-size: 2rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 3.5rem;
                    color: #ffffff;
                }
            }

        }
    }
}</style>
