import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Cash from '../views/Cash.vue'
import Order from '../views/Order.vue'
import Utility from '../views/Utility.vue'
import Kitchen from '../views/Kitchen.vue'
import Assistant from '../views/Assistant.vue'
import Stock from '../views/Stock.vue'
import Index from '../views/Index.vue';
import AboutUs from '../views/AboutUs.vue';
import Repast from '../views/Repast.vue';
import Fruits from '../views/Fruits.vue';
import Retail from '../views/Retail.vue';
import DownLoad from '../views/downLoad.vue';
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/index',
    children: [
      { path: '/index', name: 'index', component: Index },
      { path: '/retail', name: 'retail', component: Retail },
      { path: '/fruits', name: 'fruits', component: Fruits },
      { path: '/repast', name: 'repast', component: Repast },
      { path: '/aboutus', name: 'aboutus', component: AboutUs },
      { path: '/cash', name: 'cash', component: Cash },
      { path: '/order', name: 'order', component: Order },
      { path: '/utility', name: 'utility', component: Utility },
      { path: '/kitchen', name: 'kitchen', component: Kitchen },
      { path: '/assistant', name: 'assistant', component: Assistant },
      { path: '/stock', name: 'stock', component: Stock },
    ]
  }, {
    path: '/download', name: 'download', component: DownLoad,
    // beforeEnter: (to, from, next) => {
    //   document.documentElement.style.fontSize = '16px';
    //   next();
    // }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
