<template>
    <div class="section">
        <div class="box">
            <img src="../assets/css/img/1-1-1-1.png">
            <div class="content1">
                <p :class="$i18n.locale == 'en' ? 'content1-p1-1' : 'content1-p1'">
                    <!-- 智能后厨系统 -->
                    {{ $t('repast.content1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content1-p1-1' : 'content1-p1'">
                    <!-- 不同菜品展示模式 杜绝漏单 -->
                    {{ $t('repast.content2') }}
                </p>
                <div class="content"></div>
                <p :class="$i18n.locale == 'en' ? 'content1-p2-1' : 'content1-p2'">
                    <!-- 扫码点餐，节省人力 -->
                    {{ $t('repast.content3') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content1-p2-1' : 'content1-p2'">
                    <!-- 顾客通过微信自助点餐，更方便快捷 -->
                    {{ $t('repast.content4') }}
                </p>
            </div>
        </div>
        <div class="content-1">
            <p class="content-p1">
                <!-- 适用多种餐饮门店 -->
                {{ $t('repast.cy') }}
            </p>
            <p class="content-p2">
                <!-- 快餐、正餐、咖啡蛋糕奶茶 -->
                {{ $t('repast.cy1') }}
            </p>
            <p class="content-p2">
                <!-- 多行业适用 -->
                {{ $t('repast.cy2') }}
            </p>
        </div>
        <div class="content-2">
            <ul style="width:105rem;display:flex;justify-content:space-around;margin:0 auto;">
                <li class="li-image1">
                    <div class="li-content">
                        <p class="li-p1">
                            正餐
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Dinner
                        </p>
                    </div>
                </li>
                <li class="li-image2">
                    <div class="li-content">
                        <p class="li-p1">
                            快餐
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Fast food
                        </p>
                    </div>
                </li>
                <li class="li-image3">
                    <div class="li-content">
                        <p class="li-p1">
                            烘焙
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Baking
                        </p>
                    </div>
                </li>
                <li class="li-image4">
                    <div class="li-content">
                        <p class="li-p1">
                            酒吧
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Bar
                        </p>
                    </div>
                </li>
                <li class="li-image5">
                    <div class="li-content">
                        <p class="li-p1">
                            食堂
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Canteen
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="content-1" style="margin-bottom:0rem;">
            <p class="content-p1">
                <!-- 收银机/桌台/小程序点餐 -->
                {{ $t('repast.sy') }}
            </p>
            <p class="content-p2">
                <!-- 支持先付款或后付款，堂食，外带  -->
                {{ $t('repast.sy1') }}
            </p>
        </div>
        <div class="image-1">
            <img src="../assets/css/img/3-1-2.png" style="width:100%;">
            <div class="content-image">
                <div class="content-1-1">
                    <p class="content-1-1-p1">
                        <!-- 收银机点餐：  -->
                        {{ $t('repast.dc') }}
                    </p>
                    <p class="content-1-1-p2">
                        <!-- 适合快餐、咖啡店、酒吧 ，直接点餐-结账-传到后厨-等待叫号-取餐 -->
                        <span :class="$i18n.locale == 'en' ? 'active' : ''">
                            {{ $t('repast.dc1') }}
                        </span>
                    </p>
                </div>
                <div class="content-1-2">
                    <p class="content-1-1-p1">
                        <!-- 桌台/小程序点餐：  -->
                        {{ $t('repast.dc2') }}
                    </p>
                    <p class="content-1-1-p2">
                        <!-- 适合正餐，店员使用点餐宝点餐，数据同步收银机，后厨、收银机后结账 -->
                        <span :class="$i18n.locale == 'en' ? 'active' : ''">
                            {{ $t('repast.dc3') }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="content-1" style="margin-top:5rem;margin-bottom:2rem;">
            <p class="content-p1">
                <!-- 后厨接单 -->
                {{ $t('repast.hc') }}
            </p>
            <p class="content-p2">
                <!-- 收银机下单，后厨接单，小票打印，订单完成后，大屏幕叫号 -->
                {{ $t('repast.hc1') }}
            </p>
        </div>
        <div class="image-2">
            <div class="image-content">
                <img src="../assets/css/img/3-1-3.png">
            </div>
        </div>
        <div :class="$i18n.locale == 'en' ? 'section-t4-1' : 'section-t4'">
            <div class="content-1">
                <p class="content-p1">
                    <!-- 硬件配套方案 -->
                    {{ $t('repast.yj') }}
                </p>
                <p class="content-p2">
                    <!-- 收银机、自助机、扫码枪、扫码盒子、钱箱、厨房平板设备、叫号设备 -->
                    {{ $t('repast.yj1') }}
                </p>
                <p class="content-p2">
                    <!-- 不同门店支持不同配置 -->
                    {{ $t('repast.yj2') }}
                </p>
            </div>
            <div class="content-4">
                <div class="content-4-1">
                    <p :class="$i18n.locale == 'en' ? 'active3' : ''">
                        <!-- 自助点餐设备 -->
                        {{ $t('repast.zz') }}
                    </p>
                    <div class="content-1-1">
                        <img src="../assets/css/img/tucheng1029.png">
                    </div>
                </div>
                <div class="content-4-2">
                    <div class="content-2-1">
                        <p :class="$i18n.locale == 'en' ? 'active3' : ''">
                            <!-- 收银机 -->
                            {{ $t('repast.syj') }}
                        </p>
                        <div class="content-2-1-1">
                            <img src="../assets/css/img/zhuotaikaobei.png">
                        </div>
                    </div>
                    <div class="content-2-2">
                        <p :class="$i18n.locale == 'en' ? 'active3' : ''">
                            <!-- 手持设备 -->
                            {{ $t('repast.sc') }}
                        </p>
                        <div class="content-2-1-1">
                            <img src="../assets/css/img/diancan.png">
                        </div>
                    </div>
                </div>
                <div class="content-4-3">
                    <div class="content-3-1">
                        <p :class="$i18n.locale == 'en' ? 'active3' : ''">
                            <!-- 安卓平板（KDS） -->
                            {{ $t('repast.azk') }}
                        </p>
                        <div class="content-2-1-1">
                            <img src="../assets/css/img/kds.png">
                        </div>
                    </div>
                    <div class="content-3-2">
                        <p :class="$i18n.locale == 'en' ? 'active3' : ''">
                            <!-- 安卓平板（ADS） -->
                            {{ $t('repast.aza') }}
                        </p>
                        <div class="content-2-1-1">
                            <img src="../assets/css/img/ADS1.png">
                        </div>
                    </div>
                    <div class="content-3-3">
                        <p :class="$i18n.locale == 'en' ? 'active3' : ''">
                            <!-- 电视（TV） -->
                            {{ $t('repast.tv') }}
                        </p>
                        <div class="content-2-1-1">
                            <img src="../assets/css/img/tuceng1016.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-1">
            <p class="content-p1">
                <!-- 软件应用 -->
                {{ $t('repast.rj') }}
            </p>
            <p class="content-p2">
                <!-- 唐库收银、唐库辅助工具、唐库盘点系统 -->
                {{ $t('repast.rj1') }}
            </p>
        </div>
        <div class="content-3" style="margin-bottom:7rem;">
            <div>
                <img src="../assets/css/img/tangkushouyin@2x.png">
                <p class="img-p1">
                    <!-- 唐库收银 -->
                    {{ $t('repast.tksy') }}
                </p>
                <p class="img-p2">
                    <!-- 唐库智慧门店，线下收银，适配各种收银机 -->
                    {{ $t('repast.tksy1') }}
                </p>
            </div>
            <div>
                <img src="../assets/css/img/tk_KDS_2@2x.png">
                <p class="img-p1">
                    <!-- 唐库厨房 -->
                    {{ $t('repast.tkcf') }}
                </p>
                <p class="img-p2">
                    <!-- 为后厨人员开发的智能系统 -->
                    {{ $t('repast.tkcf1') }}
                </p>
            </div>
            <div>
                <img src="../assets/css/img/tk_waiter_1@2x.png">
                <p class="img-p1">
                    <!-- 唐库点餐 -->
                    {{ $t('repast.tkdc') }}
                </p>
                <p class="img-p2">
                    <!-- 小巧方便的手持点餐设备 -->
                    {{ $t('repast.tkdc1') }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'retail',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang="less" scoped>
.active {
    display: block;
    width: 70rem;
    font-size: 1.8rem;
}

.section {
    width: 100%;
}

.section-t4 {
    width: 100%;
    height: 83rem;
    border: solid 1px #F2F3F4;
    background: #F2F3F4;
}

.section-t4-1 {
    width: 100%;
    height: 85rem;
    border: solid 1px #F2F3F4;
    background: #F2F3F4;
}

.content-4 {
    width: 110rem;
    height: 60rem;
    margin: 0 auto;
    // margin-top: 5rem;
    // margin-bottom: 10rem;
    display: flex;
    justify-content: space-between;

    div {
        p {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #121212;
            line-height: 22px;
            margin-left: 3rem;
        }

        .active3 {
            font-size: 1.8rem;
        }
    }

    .content-4-1 {
        width: 35rem;
        height: 60rem;
        background: #FFFFFF;
        border-radius: 10px;
        position: relative;
        padding-top: 3rem;

        .content-1-1 {
            width: 16rem;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin-top: 4rem;
        }
    }

    .content-4-2 {
        width: 35rem;
        height: 60rem;
        background: #F2F3F4;

        div {
            width: 35rem;
            height: 29rem;
            background: #FFFFFF;
            border-radius: 10px;
        }

        .content-2-1 {
            margin-bottom: 2.2rem;
            padding-top: 3rem;
            position: relative;

            .content-2-1-1 {
                width: 18rem;
                height: 18rem;
                text-align: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                margin-top: 3rem;

                img {
                    width: 100%;
                }
            }
        }

        .content-2-2 {
            margin-bottom: 2.2rem;
            padding-top: 3rem;
            position: relative;

            .content-2-1-1 {
                width: 18rem;
                height: 18rem;
                text-align: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                margin-top: 3rem;

                img {
                    width: 100%;
                }
            }
        }
    }

    .content-4-3 {
        width: 35rem;
        height: 60rem;
        background: #F2F3F4;

        div {
            width: 35rem;
            height: 18.5rem;
            background: #FFFFFF;
            border-radius: 10px;
        }

        .content-3-1,
        .content-3-2 {
            margin-bottom: 2.3rem;
        }

        .content-3-1 {
            padding-top: 3rem;
            position: relative;

            .content-2-1-1 {
                width: 16rem;
                height: 5rem;
                text-align: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                img {
                    width: 100%;
                }
            }
        }

        .content-3-2 {
            padding-top: 3rem;
            position: relative;

            .content-2-1-1 {
                width: 16rem;
                height: 5rem;
                text-align: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                img {
                    width: 100%;
                }
            }
        }

        .content-3-3 {
            padding-top: 3rem;
            position: relative;

            .content-2-1-1 {
                width: 16rem;
                height: 5rem;
                text-align: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                img {
                    width: 100%;
                }
            }
        }
    }

}

.content-3 {
    width: 110rem;
    height: 20rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;

    div {
        width: 35rem;
        text-align: center;

        // border:solid;
        img {
            width: 20%;
            margin-bottom: 2rem;
        }
    }

    .img-p1 {
        font-size: 2.1rem;
        font-family: Microsoft YaHei;
        font-weight: 700;
        color: #121212;
        line-height: 22px;
        margin-bottom: 0.7rem;
    }

    .img-p2 {
        font-size: 1.8rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 2rem;
    }
}

.image-3 {
    width: 100rem;
    margin: 0 auto;
    margin-bottom: 10rem;
    margin-top: 7rem;

    .image-content {
        width: 100rem;
        margin: 0 auto;
        position: relative;

        img {
            width: 100%;
        }

        div {
            font-size: 1.8rem;
        }

        .content-position-1 {
            position: absolute;
            left: 11.4rem;
            top: 1rem;
        }

        .content-position-2 {
            position: absolute;
            left: 29rem;
            top: 1rem;
        }

        .content-position-3 {
            position: absolute;
            left: 46.5rem;
            top: 1rem;
        }

        .content-position-4 {
            position: absolute;
            left: 64rem;
            top: 1rem;
        }

        .content-position-5 {
            position: absolute;
            left: 81.5rem;
            top: 1rem;
        }

        .content-position-6 {
            position: absolute;
            left: 14rem;
            top: 19.4rem;
        }

        .content-position-7 {
            position: absolute;
            left: 45rem;
            top: 16.5rem;
        }

        .content-position-8 {
            position: absolute;
            left: 41.9rem;
            top: 22.5rem;
        }

        .content-position-9 {
            position: absolute;
            left: 82.6rem;
            top: 19.4rem;
        }

        .content-position-10 {
            position: absolute;
            left: 29rem;
            top: 38rem;
        }

        .content-position-11 {
            position: absolute;
            left: 45.5rem;
            top: 38rem;
        }

        .content-position-12 {
            position: absolute;
            left: 64rem;
            top: 38rem;
        }
    }
}

.image-2 {
    width: 100%;
    margin: 0 auto;
    // margin-bottom: 5rem;
    margin-top: 2rem;

    .image-content {
        width: 100%;
        margin: 0 auto;
        position: relative;

        img {
            width: 100%;
        }
    }

}

.image-1 {
    width: 100%;
    // max-width: 170rem;
    margin: 0 auto;
    // margin-top:-2rem;
    position: relative;

    .content-image {
        width: 67rem;
        height: 45rem;
        padding-top: 1rem;
        color: #FFFFFF;
        position: absolute;
        left: 80rem;
        top: 9rem;

        .content-1-1-p1 {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #F2F7FF;
            line-height: 94px;
        }

        .content-1-1-p2 {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 33px;
        }
    }
}

.content-2 {
    width: 120rem;
    margin: 0 auto;
    // margin-top: 5rem;

    li {
        width: 18rem;
        height: 18rem;
    }

    .li-image1 {
        background: url(../assets/css/img/dinner.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image1:hover {
        background: url(../assets/css/img/dinner01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image2 {
        background: url(../assets/css/img/Fastfood.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image2:hover {
        background: url(../assets/css/img/Fastfood01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image3 {
        background: url(../assets/css/img/Baking.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image3:hover {
        background: url(../assets/css/img/Baking01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image4 {
        background: url(../assets/css/img/Bar.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image4:hover {
        background: url(../assets/css/img/Bar01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image5 {
        background: url(../assets/css/img/Canteen.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image5:hover {
        background: url(../assets/css/img/Canteen01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }
}

.content-1 {
    width: 100rem;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 3rem;

    .content-p1 {
        text-align: center;
        font-size: 3.6rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        line-height: 6rem;
    }

    .content-p2 {
        text-align: center;
        font-size: 2.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
    }
}

.box {
    width: 100%;
    // max-width: 169rem;
    margin: 0 auto;
    position: relative;

    img {
        width: 100%;
        // max-width: 169rem;
    }

    .content {
        width: 40rem;
        height: 0.3rem;
        background: #FFFFFF;
        margin: 0 auto;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }

    .content1 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 65rem;
        height: 25rem;
        .content1-p1 {
            text-align: center;
            font-size: 5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 7rem;
        }
        .content1-p1-1 {
            // border:solid;
            text-align: center;
            font-size: 4.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 4rem;
        }

        .content1-p2-1 {
            text-align: center;
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 2.5rem;
        }

        .content1-p2 {
            text-align: center;
            font-size: 2.3rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4rem;
        }
    }
}

// 屏幕1560px时的样式
@media screen and (max-width:1560px) {
    .box {
        width: 100%;
        // max-width: 169rem;
        margin: 0 auto;
        position: relative;

        .content {
            width: 40rem;
            height: 0.3rem;
            background: #FFFFFF;
            margin: 0 auto;
            margin-top: 3rem;
            margin-bottom: 1rem;
        }

        .content1 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 65rem;
            height: 20rem;

            .content1-p1 {
                text-align: center;
                font-size: 4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 5rem;
            }

            .content1-p1-1 {
                // border:solid;
                text-align: center;
                font-size: 4.2rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 4rem;

            }

            .content1-p2-1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.5rem;
            }

            .content1-p2 {
                text-align: center;
                font-size: 2.3rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 3rem;
            }
        }

        img {
            width: 100%;
            max-width: 169rem;
        }
    }
}

// 屏幕1450px时的样式
@media screen and (max-width:1456px) {
    .active {
        display: block;
        width: 60rem;
        font-size: 1.5rem;
    }

    .image-1 {
        width: 100%;
        // max-width: 170rem;
        margin: 0 auto;
        position: relative;

        .content-image1 {
            width: 67rem;
            height: 45rem;
            padding-top: 1rem;
            color: #FFFFFF;
            position: absolute;
            left: 50rem;
            top: 3rem;
        }

        .content-image {
            width: 67rem;
            height: 45rem;
            padding-top: 1rem;
            color: #FFFFFF;
            position: absolute;
            left: 65rem;
            top: 5rem;

            .content-1-1-p1 {
                font-size: 2.3rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #F2F7FF;
                line-height: 8.4rem;
            }

            .content-1-1-p2 {
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 3rem;
            }
        }
    }
}

// 屏幕1300px时的样式
// @media screen and (max-width:1300px){
//     .active {
//         display: block;
//         width: 60rem;
//         font-size: 1.5rem;
//     }
//     .image-1 {
//         width: 100%;
//         // max-width: 170rem;
//         margin: 0 auto;
//         position: relative;
//         .content-image1 {
//             width: 67rem;
//             height: 45rem;
//             border:solid;
//             padding-top: 1rem;
//             color: #FFFFFF;
//             position: absolute;
//             left: 10rem;
//             top: 3rem;
//         }
//     }
// }
// 屏幕1200px时的样式
@media screen and (max-width:1200px) {
    .active {
        display: block;
        width: 60rem;
        font-size: 1.5rem;
    }

    .image-1 {
        width: 120rem;
        // max-width: 170rem;
        margin: 0 auto;
        position: relative;

        .content-image1 {
            width: 67rem;
            height: 45rem;
            padding-top: 1rem;
            color: #FFFFFF;
            position: absolute;
            left: 50rem;
            top: 3rem;
        }

        .content-image {
            width: 67rem;
            height: 45rem;
            padding-top: 1rem;
            color: #FFFFFF;
            position: absolute;
            left: 60rem;
            top: 3rem;

            .content-1-1-p1 {
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #F2F7FF;
                line-height: 8.4rem;
            }

            .content-1-1-p2 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 3rem;
            }
        }
    }

    .box {
        width: 120rem;
        // max-width: 169rem;
        margin: 0 auto;
        position: relative;

        .content {
            width: 40rem;
            height: 0.3rem;
            background: #FFFFFF;
            margin: 0 auto;
            margin-top: 3rem;
            margin-bottom: 1rem;
        }

        .content1 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 65rem;
            height: 20rem;

            .content1-p1 {
                text-align: center;
                font-size: 4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 5rem;
            }

            .content1-p1-1 {
                // border:solid;
                text-align: center;
                font-size: 4.2rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 4rem;
            }

            .content1-p2-1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.5rem;
            }

            .content1-p2 {
                text-align: center;
                font-size: 2.3rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 3rem;
            }
        }

        img {
            width: 100%;
            max-width: 169rem;
        }
    }
}

@media screen and (min-width:1710px) {
    .image-1 {
        width: 100%;
        // max-width: 170rem;
        margin: 0 auto;
        position: relative;

        .content-image {
            width: 67rem;
            height: 45rem;
            padding-top: 1rem;
            color: #FFFFFF;
            position: absolute;
            left: 50%;
            top: 10%;

            .content-1-1-p1 {
                font-size: 4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #F2F7FF;
                line-height: 10rem;
            }

            .content-1-1-p2 {
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 6rem;
            }
        }
    }


}</style>