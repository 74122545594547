<template>
    <div class="section">
        <div class="section-1">
            <img src="../assets/css/img/2-4-1.png" style="width:100%;">
            <div class="box">
                <p :class="$i18n.locale == 'en' ? 'box-p1-1' : 'box-p1'">
                    <!-- 盘点系统 -->
                    {{ $t('stock.swiper1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'box-p1-1' : 'box-p1'">
                    <!-- 扫码盘点省时省力 -->
                    {{ $t('stock.swiper2') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'box-p2-1' : 'box-p2'">
                    <!-- 盘亏盘盈 一目了然 -->
                    {{ $t('stock.swiper3') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'box-p2-1' : 'box-p2'">
                    <!-- 更好掌握库存动态 -->
                    {{ $t('stock.swiper4') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'box-sub-1' : 'box-sub'">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                        height="25px" style="vertical-align: middle;">
                        <path fill-rule="evenodd" fill="rgb(242, 243, 244)"
                            d="M22.005,10.070 L15.714,10.070 L15.714,0.640 L6.280,0.640 L6.280,10.070 L-0.010,10.070 L10.997,21.071 L22.002,10.070 L22.005,10.070 ZM-0.010,24.214 L-0.010,27.358 L22.005,27.358 L22.005,24.214 L-0.010,24.214 Z" />
                    </svg>
                    <span>
                        <!-- 软件下载 -->
                        {{ $t('stock.swiper5') }}
                    </span>
                </p>
            </div>
        </div>
        <div class="content">
            <p class="content-p1">
                <!-- 多人盘点，团队协作更速度 -->
                {{ $t('stock.pd') }}
            </p>
            <p class="content-p2">
                <!-- 创建盘点单，多人加入盘点，盘亏盘盈纠正，一键提交，核实后修改库存 -->
                {{ $t('stock.pd1') }}
            </p>
        </div>
        <div class="content-box">
            <div>
                <div class="content-img">
                    <img src="../assets/css/img/icon_01.png" />
                </div>
                <div class="box-content">
                    <!-- 多人同时盘点 -->
                    {{ $t('stock.content1') }}
                </div>
            </div>
            <div>
                <div class="content-img">
                    <img src="../assets/css/img/icon_02.png" />
                </div>
                <div class="box-content">
                    <!-- 按商品品类盘点 -->
                    {{ $t('stock.content2') }}
                </div>
            </div>
            <div>
                <div class="content-img">
                    <img src="../assets/css/img/icon_03.png" />
                </div>
                <div class="box-content">
                    <!-- 可分多天进行盘点 -->
                    {{ $t('stock.content3') }}
                </div>
            </div>
            <div>
                <div class="content-img">
                    <img src="../assets/css/img/icon_04.png" />
                </div>
                <div class="box-content">
                    <!-- 未盘点商品不影响销售 -->
                    {{ $t('stock.content4') }}
                </div>
            </div>
        </div>
        <div class="content">
            <p class="content-p1">
                <!-- 扫码盘点，精准、迅速 -->
                {{ $t('stock.smpd') }}
            </p>
            <p class="content-p2">
                <!-- 扫商品条码，输入或加减商品数量，一次可盘多件商品 -->
                {{ $t('stock.smpd1') }}
            </p>
        </div>
        <img src="../assets/css/img/2-4-2.png" style="width:100%;display:block;margin:0 auto;margin-bottom:5rem;" />
        <div class="content">
            <p class="content-p1">
                <!-- 数据精准核实，拒绝出错 -->
                {{ $t('stock.data') }}
            </p>
            <p class="content-p2">
                <!-- 盘点数据多次核对，拒绝出错，核实审核，修改库存 -->
                {{ $t('stock.data1') }}
            </p>
        </div>
        <div class="content-image">
            <div class="image-1">
                <p :class="$i18n.locale == 'en' ? 'active' : ''">
                    <!-- 盘点漏盘商品 -->
                    {{ $t('stock.lp') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'p-1' : ''">
                    <!-- 在未盘中可重新盘点 -->
                    {{ $t('stock.lp1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'p-1' : ''">
                    <!-- 漏盘商品 -->
                    {{ $t('stock.lp2') }}
                </p>
            </div>
            <div class="image-2">
                <p :class="$i18n.locale == 'en' ? 'active' : ''">
                    <!-- 校正商品数量 -->
                    {{ $t('stock.jz') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'p-1' : ''">
                    <!-- 提交盘点结果前可更改 -->
                    {{ $t('stock.jz1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'p-1' : ''">
                    <!-- 盘点数量，拒绝出错 -->
                    {{ $t('stock.jz2') }}
                </p>
            </div>
            <div class="image-3">
                <p :class="$i18n.locale == 'en' ? 'active' : ''">
                    <!-- 数据同步 -->
                    {{ $t('stock.data2') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'p-1' : ''">
                    <!-- 盘点数据同步后台 -->
                    {{ $t('stock.data3') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'p-1' : ''">
                    <!-- 审核后，库存更改 -->
                    {{ $t('stock.data4') }}
                </p>
            </div>
            <div class="image-4">
                <p :class="$i18n.locale == 'en' ? 'active' : ''">
                    <!-- 多端操作 -->
                    {{ $t('stock.dd') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'p-1' : ''">
                    <!-- 支持手机、移动终端、 -->
                    {{ $t('stock.dd1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'p-1' : ''">
                    <!-- 收银机 -->
                    {{ $t('stock.dd2') }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'stock',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang='less' scoped>
.section {
    width: 100%;
    height: 100%;
}

.content-image {
    width: 100rem;
    height: 25rem;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    .image-1,
    .image-2,
    .image-3,
    .image-4 {
        width: 22.7rem;
        height: 20rem;
        // padding: 3rem;
        padding-top: 9rem;

        p {
            text-align: center;
            color: #FFFFFF;
            font-size: 1.6rem;
            line-height: 3rem;
        }

        p:first-of-type {
            font-size: 1.8rem;
        }

        .active {
            width: 22.5rem;
            font-size: 1.7rem;
            line-height: 2.2rem;
            margin: 0 auto;
        }

        .p-1 {
            width: 22.5rem;
            text-align: center;
            color: #FFFFFF;
            font-size: 1.4rem;
            line-height: 2rem;
            margin: 0 auto;
        }
    }

    .image-1 {
        background: url(../assets/css/img/2-4-4.png) no-repeat;
        background-size: 100%;
    }

    .image-2 {
        background: url(../assets/css/img/2-4-5.png) no-repeat;
        background-size: 100%;
    }

    .image-3 {
        background: url(../assets/css/img/2-4-6.png) no-repeat;
        background-size: 100%;
    }

    .image-4 {
        background: url(../assets/css/img/2-4-7.png) no-repeat;
        background-size: 100%;
    }
}

.content-box {
    width: 90rem;
    height: 13.9rem;
    border-top: solid 1px #D6D6D6;
    padding-top: 0.5rem;
    margin: 0 auto;
    margin-top: -0.5rem;
    // margin-top: 5rem;
    // padding-top: 2rem;
    // margin-bottom: 10rem;
    display: flex;
    justify-content: space-between;

    div {
        width: 19rem;
        height: 13rem;

        .content-img {
            width: 9rem;
            height: 9rem;
            margin: 0 auto;
        }

        .box-content {
            text-align: center;
            font-size: 1.8rem;
            margin-top: 1rem;
        }
    }
}

.content {
    width: 100rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 3rem;

    .content-p1 {
        font-size: 3.6rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        line-height: 7rem;
    }
    .content-p2 {
        font-size: 2.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
    }
}

.section-1 {
    border-top: solid 1px #FFFFFF;
    width: 100%;
    align-items: center;
    margin: 0 auto;
    position: relative;
}

.box {
    width: 60rem;
    height: 40rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .box-p1 {
        font-size: 5rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 7rem;
        text-align: center;
    }

    .box-p1-1 {
        font-size: 4.5rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 6rem;
        text-align: center;
    }

    .box-p2-1 {
        font-size: 2.5rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 5rem;
        text-align: center;
    }

    .box-sub-1 {
        width: 27rem;
        height: 5rem;
        border-radius: 1.1rem;
        background-color: #4591fe;
        font-size: 2.2rem;
        color: #FFFFFF;
        line-height: 4.5rem;
        padding-left: 1.3rem;
        margin: 0 auto;
        margin-top: 6rem;

        span {
            margin-left: 0.3rem;
        }
    }

    .box-p2 {
        font-size: 2.8rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 4.5rem;
        text-align: center;
    }

    .box-sub {
        width: 15rem;
        height: 4.5rem;
        border-radius: 1.1rem;
        background-color: #4591fe;
        font-size: 2.2rem;
        color: #FFFFFF;
        line-height: 4.5rem;
        padding-left: 1.3rem;
        margin: 0 auto;
        margin-top: 6rem;

        span {
            margin-left: 0.3rem;
        }
    }
}

@media screen and (max-width:1300px) {
    .box {
        width: 60rem;
        height: 40rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%; -50%);

        .box-p1 {
            font-size: 5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 7rem;
            text-align: center;
        }

        .box-p1-1 {
            font-size: 4rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 5rem;
            text-align: center;
        }

        .box-p2-1 {
            font-size: 2.2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4rem;
            text-align: center;
        }

        .box-sub-1 {
            width: 27rem;
            height: 5rem;
            border-radius: 1.1rem;
            background-color: #4591fe;
            font-size: 2.2rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            padding-left: 1.3rem;
            margin: 0 auto;
            margin-top: 6rem;
            span {
                margin-left: 0.3rem;
            }
        }
        .box-p2 {
            font-size: 2.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4.5rem;
            text-align: center;
        }
        .box-sub {
            width: 15rem;
            height: 4.5rem;
            border-radius: 1.1rem;
            background-color: #4591fe;
            font-size: 2.2rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            padding-left: 1.3rem;
            margin: 0 auto;
            margin-top: 6rem;
            span {
                margin-left: 0.3rem;
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .section-1 {
        border-top: solid 1px #FFFFFF;
        width: 120rem;
        align-items: center;
        margin: 0 auto;
        position: relative;
    }
}

@media screen and (max-width:1019px) {
    .content-image {
        width: 100%;
        height: 20rem;
        margin: 0 auto;
        margin-top: 3rem;
        margin-bottom: 20rem;
        display: flex;
        transition: all .5s;
        flex-wrap: wrap;
        justify-content: space-between;

        .image-1,
        .image-2,
        .image-3,
        .image-4 {
            width: 22.7rem;
            height: 20rem;
            padding: 3rem;
            padding-top: 9rem;

            p {
                text-align: center;
                color: #FFFFFF;
                font-size: 1.6rem;
                line-height: 3rem;
            }
        }

        .image-1 {
            background: url(../assets/css/img/2-4-4.png) no-repeat;
            background-size: 100%;
        }

        .image-2 {
            background: url(../assets/css/img/2-4-5.png) no-repeat;
            background-size: 100%;
        }

        .image-3 {
            background: url(../assets/css/img/2-4-6.png) no-repeat;
            background-size: 100%;
        }

        .image-4 {
            background: url(../assets/css/img/2-4-7.png) no-repeat;
            background-size: 100%;
        }
    }

}</style>