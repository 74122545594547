<template>
    <div class="section">
        <div class="box">
            <img src="../assets/css/img/1-1-1.png">
            <div class="content"></div>
            <div class="content1">
                <p :class="$i18n.locale == 'en' ? 'content1-p1-1' : 'content1-p1'">
                    <!-- 智能零售系统 -->
                    {{ $t('retail.swiper1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content1-p1-1' : 'content1-p1'">
                    <!-- 生意红火好助手 -->
                    {{ $t('retail.swiper2') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content1-p2-1' : 'content1-p2'">
                    <!-- 多维度数据分析  -->
                    {{ $t('retail.swiper3') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content1-p2-1' : 'content1-p2'">
                    <!-- 全方位多维度大数据分析 实时监控店铺运营状态 -->
                    {{ $t('retail.swiper4') }}
                </p>
            </div>
        </div>
        <div class="content-1">
            <p class="content-p1">
                <!-- 适用多种零售门店 -->
                {{ $t('retail.md') }}
            </p>
            <p class="content-p2">
                <!-- 夫妻店、便利店、连锁门店、大型商超 -->
                {{ $t('retail.md1') }}
            </p>
            <p class="content-p2">
                <!-- 多行业适用 -->
                {{ $t('retail.md2') }}
            </p>
        </div>
        <div class="content-2">
            <ul style="width:105rem;display:flex;justify-content:space-around;margin:0 auto;">
                <li class="li-image1">
                    <div class="li-content">
                        <p class="li-p1">
                            夫妻店
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Couple Shop
                        </p>
                    </div>
                </li>
                <li class="li-image2">
                    <div class="li-content">
                        <p class="li-p1">
                            便利店
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Convenience Store
                        </p>
                    </div>
                </li>
                <li class="li-image3">
                    <div class="li-content">
                        <p class="li-p1">
                            服装店
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Clothing Store
                        </p>
                    </div>
                </li>
                <li class="li-image4">
                    <div class="li-content">
                        <p class="li-p1">
                            美妆店
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Beauty Shop
                        </p>
                    </div>
                </li>
                <li class="li-image5">
                    <div class="li-content">
                        <p class="li-p1">
                            大型商超
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Supermarket
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="content-1">
            <p class="content-p1">
                <!-- 多种功能满足需求 -->
                {{ $t('retail.gn') }}
            </p>
            <p class="content-p2">
                <!-- 多种营销、聚合支付、多种不同页面适配、报表多样化 -->
                {{ $t('retail.gn1') }}
            </p>
            <p class="content-p2">
                <!-- 反应速度灵敏 -->
                {{ $t('retail.gn2') }}
            </p>
        </div>
        <div class="image-1">
            <img src="../assets/css/img/3-2-3.png" style="width:100%;">
            <div class="image-1-content1">
                <p :class="$i18n.locale == 'en' ? 'content-1-p1-1' : 'content-1-p1'">
                    <!-- 智能收银 -->
                    {{ $t('retail.image1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-1-p2-1' : 'content-1-p2'">
                    <!-- 多种支付方式 -->
                    {{ $t('retail.image2') }}
                </p>
            </div>
            <div class="image-1-content2">
                <p :class="$i18n.locale == 'en' ? 'content-1-p1-1' : 'content-1-p1'">
                    <!-- 智慧盘点 -->
                    {{ $t('retail.image3') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-1-p2-1' : 'content-1-p2'">
                    <!-- 多人分组盘点 -->
                    {{ $t('retail.image4') }}
                </p>
            </div>
            <div class="image-1-content3">
                <p :class="$i18n.locale == 'en' ? 'content-1-p1-1' : 'content-1-p1'">
                    <!-- 采购助手 -->
                    {{ $t('retail.image5') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-1-p2-1' : 'content-1-p2'">
                    <!-- 智能采购单 -->
                    {{ $t('retail.image6') }}
                </p>
            </div>
            <div class="image-1-content4">
                <p :class="$i18n.locale == 'en' ? 'content-1-p1-1' : 'content-1-p1'">
                    <!-- 线上商城 -->
                    {{ $t('retail.image7') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-1-p2-1' : 'content-1-p2'">
                    <!-- 小程序线上购物 -->
                    {{ $t('retail.image8') }}
                </p>
            </div>
            <div class="image-1-content5">
                <p :class="$i18n.locale == 'en' ? 'content-1-p1-1' : 'content-1-p1'">
                    <!-- 连锁供应 -->
                    {{ $t('retail.image9') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-1-p2-1' : 'content-1-p2'">
                    <!-- 多门店连锁供应 -->
                    {{ $t('retail.image10') }}
                </p>
            </div>
        </div>
        <div class="content-1">
            <p class="content-p1">
                <!-- 全渠道多终端覆盖 -->
                {{ $t('retail.zd') }}
            </p>
            <p class="content-p2">
                <!-- 微商城多渠道获取客户，各种营销吸引客户 -->
                {{ $t('retail.zd1') }}
            </p>
        </div>
        <div class="image-2">
            <div class="image-content">
                <img src="../assets/css/img/3-2-5.png">
                <div :class="$i18n.locale == 'en' ? 'content-position1-1' : 'content-position1'">
                    <!-- 助手小程序 -->
                    {{ $t('retail.content1') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position2-1' : 'content-position2'">
                    <!-- 收银系统 -->
                    {{ $t('retail.content2') }}

                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position3-1' : 'content-position3'">
                    <!-- 后台 -->
                    {{ $t('retail.content3') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position4-1' : 'content-position4'">
                    <!-- 价签 -->
                    {{ $t('retail.content4') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position5-1' : 'content-position5'">
                    <!-- 盘点工具 -->
                    {{ $t('retail.content5') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position6-1' : 'content-position6'">
                    <!-- 微商城 -->
                    {{ $t('retail.content6') }}
                </div>
            </div>
        </div>
        <div class="content-1" style="margin-top:5rem;">
            <p class="content-p1">
                <!-- 采购进货 数据报表 -->
                {{ $t('retail.cg') }}
            </p>
            <p class="content-p2">
                <!-- 简单易用，轻松录入订单，进货、销售。库存。预警提醒、报表统计 -->
                {{ $t('retail.cg1') }}
            </p>
        </div>
        <div class="image-3">
            <div class="image-content">
                <img src="../assets/css/img/3-2-6.png">
                <div :class="$i18n.locale == 'en' ? 'content-position-1-1' : 'content-position-1'">
                    <!-- 出库管理 -->
                    {{ $t('retail.ck') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-2-1' : 'content-position-2'">
                    <!-- 入库管理 -->
                    {{ $t('retail.rk') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-3-1' : 'content-position-3'">
                    <!-- 门店调拨 -->
                    {{ $t('retail.mdtb') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-4-1' : 'content-position-4'">
                    <!-- 采购进货 -->
                    {{ $t('retail.cgjh') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-5-1' : 'content-position-5'">
                    <!-- 库存盘点 -->
                    {{ $t('retail.kcpd') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-6-1' : 'content-position-6'">
                    <p style="font-size:1.3rem;margin-bottom:1rem;">
                        <!-- 连锁门店一 -->
                        {{ $t('retail.lsmd') }}
                    </p>
                    <p>
                        <!-- 商品上下架 -->
                        {{ $t('retail.goods') }}
                    </p>
                </div>
                <div class="content-position-7" style="font-size:1.8rem">
                    <!-- 连锁门店 -->
                    {{ $t('retail.lsmd1') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-8-1' : 'content-position-8'" style="font-size:1.8rem">
                    <!-- 线上线下数据汇总 -->
                    {{ $t('retail.data') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-9-1' : 'content-position-9'">
                    <p style="font-size:1.3rem;margin-bottom:1rem;">
                        <!-- 连锁门店二 -->
                        {{ $t('retail.lsmd2') }}
                    </p>
                    <p>
                        <!-- 商品上下架 -->
                        {{ $t('retail.goods') }}
                    </p>
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-10-1' : 'content-position-10'">
                    <!-- 库存预警 -->
                    {{ $t('retail.kcyj') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-11-1' : 'content-position-11'">
                    <!-- 多规格商品 -->
                    {{ $t('retail.dgoods') }}
                </div>
                <div :class="$i18n.locale == 'en' ? 'content-position-12-1' : 'content-position-12'">
                    <!-- 偏好商品 -->
                    {{ $t('retail.pgoods') }}
                </div>
            </div>
        </div>
        <div class="section-t4">
            <div class="content-1">
                <p class="content-p1">
                    <!-- 硬件配套方案 -->
                    {{ $t('retail.yj') }}
                </p>
                <p class="content-p2">
                    <!-- 收银机、扫码枪、扫码盒子、钱箱、盘点手持设备 -->
                    {{ $t('retail.yj1') }}
                </p>
                <p class="content-p2">
                    <!-- 不同门店支持不同配置 -->
                    {{ $t('retail.yj2') }}
                </p>
            </div>
            <div class="content-4">
                <div>
                    <p :class="$i18n.locale == 'en' ? 'active6' : ''">
                        <!-- 收银机 -->
                        {{ $t('retail.syj') }}
                    </p>
                    <div class="img1">
                        <img src="../assets/css/img/zhuo_02.png">
                    </div>
                </div>
                <div>
                    <p :class="$i18n.locale == 'en' ? 'active6' : ''">
                        <!-- 盘点设备 -->
                        {{ $t('retail.pdq') }}
                    </p>
                    <div class="img1">
                        <img src="../assets/css/img/pandian.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="content-1">
            <p class="content-p1">
                <!-- 软件应用 -->
                {{ $t('retail.rjyy') }}
            </p>
            <p class="content-p2">
                <!-- 唐库收银、唐库辅助工具、唐库盘点系统 -->
                {{ $t('retail.rjyy1') }}
            </p>
        </div>
        <div class="content-3" style="margin-bottom:7rem;">
            <div>
                <img src="../assets/css/img/tangkushouyin@2x.png">
                <p class="img-p1">
                    <!-- 唐库收银 -->
                    {{ $t('retail.tksy') }}
                </p>
                <p class="img-p2">
                    <!-- 唐库智慧门店，线下收银，适配各种收银机 -->
                    {{ $t('retail.tksy1') }}
                </p>
            </div>
            <div>
                <img src="../assets/css/img/jiaqian@2x.png">
                <p class="img-p1">
                    <!-- 唐库辅助工具 -->
                    {{ $t('retail.tkfz') }}
                </p>
                <p class="img-p2">
                    <!-- 商品价签设置，批量打印商品价签 -->
                    {{ $t('retail.tkfz1') }}
                </p>
            </div>
            <div>
                <img src="../assets/css/img/pandian_01@2x.png">
                <p class="img-p1">
                    <!-- 唐库盘点器 -->
                    {{ $t('retail.tkpdq') }}
                </p>
                <p class="img-p2">
                    <!-- 彻底解决门店盘点难题，支持多人盘点 -->
                    {{ $t('retail.tkpdq1') }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'retail',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang="less" scoped>
.section {
    width: 100%;
}

.section-t4 {
    margin-top: 3rem;
    width: 100%;
    height: 88rem;
    background: #F2F3F4;
    border: solid 1px #F2F3F4;
}

.content-4 {
    width: 110rem;
    margin: 0 auto;
    // margin-top: 5rem;
    // margin-bottom: 10rem;
    display: flex;
    justify-content: space-between;

    div {
        width: 53rem;
        height: 65rem;
        border-radius: 1rem;
        background: #FFFFFF;
        position: relative;

        p {
            margin-left: 2rem;
            margin-top: 2rem;
            font-size: 2.2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #121212;
            line-height: 2.2rem;
        }

        .active6 {
            font-size: 1.8rem;
        }

        .img1 {
            width: 42rem;
            height: 42rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            img {
                width: 100%;
            }
        }
    }
}

.content-3 {
    width: 110rem;
    height: 18rem;
    margin: 0 auto;
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;

    div {
        width: 35rem;
        text-align: center;

        img {
            width: 20%;
            margin-bottom: 2rem;
        }
    }

    .img-p1 {
        font-size: 2.1rem;
        font-family: Microsoft YaHei;
        font-weight: 700;
        color: #121212;
        line-height: 22px;
        margin-bottom: 0.7rem;
    }

    .img-p2 {
        font-size: 1.8rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 2rem;
    }
}

.image-3 {
    width: 100rem;
    margin: 0 auto;
    // margin-bottom: 10rem;
    // margin-top: 7rem;

    .image-content {
        width: 100rem;
        margin: 0 auto;
        position: relative;

        img {
            width: 100%;
        }

        div {
            font-size: 1.8rem;
            // font-size: 1.5rem;
            font-family: Microsoft YaHei;
            font-weight: 600;
        }

        .content-position-1 {
            position: absolute;
            left: 12rem;
            top: 1.3rem;
        }

        .content-position-1-1 {
            position: absolute;
            left: 11rem;
            top: 1.3rem;
            // font-size:1.6rem;
        }

        .content-position-2 {
            position: absolute;
            left: 29.5rem;
            top: 1.3rem;
        }

        .content-position-2-1 {
            position: absolute;
            left: 27rem;
            top: 1.3rem;
        }

        .content-position-3 {
            position: absolute;
            left: 46.9rem;
            top: 1.3rem;
        }

        .content-position-3-1 {
            position: absolute;
            left: 44rem;
            top: 1.3rem;
        }

        .content-position-4 {
            position: absolute;
            left: 64.5rem;
            top: 1.3rem;
        }

        .content-position-4-1 {
            position: absolute;
            left: 63.5rem;
            top: 1.3rem;
        }

        .content-position-5 {
            position: absolute;
            left: 81.8rem;
            top: 1.3rem;
        }

        .content-position-5-1 {
            position: absolute;
            left: 80.5rem;
            top: 1.3rem;
        }

        .content-position-6 {
            position: absolute;
            left: 14rem;
            top: 19.4rem;
        }

        .content-position-6-1 {
            position: absolute;
            left: 13rem;
            top: 19.4rem;
        }

        .content-position-7 {
            position: absolute;
            left: 45rem;
            top: 16.5rem;
        }

        .content-position-8 {
            position: absolute;
            left: 41.9rem;
            top: 22.5rem;
        }

        .content-position-8-1 {
            position: absolute;
            left: 36rem;
            top: 23rem;
        }

        .content-position-9 {
            position: absolute;
            left: 82.6rem;
            top: 19.4rem;
        }

        .content-position-9-1 {
            position: absolute;
            left: 81.6rem;
            top: 19.4rem;
        }

        .content-position-10 {
            position: absolute;
            left: 29.5rem;
            top: 38.4rem;
        }

        .content-position-10-1 {
            line-height: 2rem;
            width: 15rem;
            position: absolute;
            left: 24.5rem;
            top: 37.3rem;
            text-align: center;
        }

        .content-position-11 {
            position: absolute;
            left: 46.2rem;
            top: 38.4rem;
        }

        .content-position-11-1 {
            font-size: 1.48rem;
            line-height: 2rem;
            width: 18rem;
            position: absolute;
            left: 41rem;
            top: 37.3rem;
            text-align: center;
        }

        .content-position-12 {
            position: absolute;
            left: 64.5rem;
            top: 38.4rem;
        }

        .content-position-12-1 {
            line-height: 2rem;
            width: 15rem;
            position: absolute;
            left: 63rem;
            top: 37.4rem;
        }
    }
}

.image-2 {
    width: 100rem;
    margin: 0 auto;
    margin-bottom: 5rem;
    margin-top: -3rem;

    .image-content {
        width: 40rem;
        margin: 0 auto;
        position: relative;

        img {
            width: 100%;
        }

        div {
            width: 10rem;
            font-size: 1.8rem;
        }

        .content-position1 {
            position: absolute;
            left: 30rem;
            top: 4.5rem;
        }

        .content-position1-1 {
            position: absolute;
            left: 30rem;
            top: 4.5rem;
            width: 20rem;
        }

        .content-position2 {
            position: absolute;
            left: -5rem;
            top: 12.7rem;
        }

        .content-position2-1 {
            position: absolute;
            left: -15.5rem;
            top: 12.7rem;
            width: 20rem;
        }

        .content-position3 {
            position: absolute;
            left: 22rem;
            top: 14.5rem;
        }

        .content-position3-1 {
            position: absolute;
            left: 22rem;
            top: 14rem;
            width: 20rem;
        }

        .content-position4 {
            position: absolute;
            left: 27rem;
            top: 21.5rem;
        }

        .content-position4-1 {
            position: absolute;
            left: 27rem;
            top: 20.5rem;
            width: 20rem;
        }

        .content-position5 {
            position: absolute;
            left: 38rem;
            top: 25rem;
        }

        .content-position5-1 {
            position: absolute;
            left: 38rem;
            top: 25rem;
            width: 20rem;
        }

        .content-position6 {
            position: absolute;
            left: 4.1rem;
            top: 33rem;
        }

        .content-position6-1 {
            position: absolute;
            left: 0rem;
            top: 33rem;
            width: 20rem;
        }
    }

}

.image-1 {
    width: 100%;
    margin: 0 auto;
    position: relative;

    div {
        width: 20rem;
        height: 5rem;
        text-align: center;
        // margin: 0 auto;
        // border:solid;

        .content-1-p1 {
            font-size: 2rem;
            font-weight: 700;
            line-height: 3rem;
            margin-bottom: 0.2rem;
        }

        .content-1-p1-1 {
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 2rem;
            margin-bottom: 0.2rem;
        }

        .content-1-p2-1 {
            font-size: 1.6rem;
        }

        .content-1-p2 {
            font-size: 1.8rem;
        }
    }

    .image-1-content1 {
        position: absolute;
        left: 18.5%;
        top: 55%;
    }

    .image-1-content2 {
        position: absolute;
        left: 31.3%;
        top: 55%;
    }

    .image-1-content3 {
        width: 19rem;
        // border:solid 1px;
        position: absolute;
        left: 44.3%;
        top: 55%;
    }

    .image-1-content4 {
        position: absolute;
        left: 57%;
        top: 55%;
    }

    .image-1-content5 {
        position: absolute;
        left: 69.8%;
        top: 55%;
    }
}

.content-2 {
    width: 120rem;
    margin: 0 auto;
    // margin-top: 5rem;

    li {
        width: 18rem;
        height: 18rem;
    }

    .li-image1 {
        background: url(../assets/css/img/CoupleShop.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image1:hover {
        background: url(../assets/css/img/CoupleShop01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image2 {
        background: url(../assets/css/img/ConvenienceStore.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image2:hover {
        background: url(../assets/css/img/ConvenienceStore01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image3 {
        background: url(../assets/css/img/ClothingStore.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image3:hover {
        background: url(../assets/css/img/ClothinStore01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image4 {
        background: url(../assets/css/img/BeautyShop.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image4:hover {
        background: url(../assets/css/img/BeautyShop01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image5 {
        background: url(../assets/css/img/Supermarket.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image5:hover {
        background: url(../assets/css/img/Supermarket01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }
}

.content-1 {
    width: 100rem;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 3rem;

    .content-p1 {
        text-align: center;
        font-size: 3.6rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        line-height: 6rem;
    }

    .content-p2 {
        text-align: center;
        font-size: 2.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
    }
}

.box {
    width: 100%;
    // max-width: 169rem;
    margin: 0 auto;
    position: relative;

    img {
        width: 100%;
        // max-width: 169rem;
    }

    .content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100rem;
        height: 35rem;
        border-radius: 3rem;
        opacity: 0.5;
        background: #151862;
    }

    .content1 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60rem;
        height: 25rem;

        .content1-p1 {
            text-align: center;
            font-size: 5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 7rem;
        }

        .content1-p1-1 {
            text-align: center;
            font-size: 4.2rem;
            font-family: Microsoft YaHei;
            color: #FFFFFF;
            line-height: 4.5rem;
        }

        .content1-p2-1 {
            text-align: center;
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4rem;
        }

        .content1-p2 {
            text-align: center;
            font-size: 2.3rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 2.5rem;
        }
    }
}

//屏幕1200px时的样式


// 屏幕1560px时的样式
@media screen and (max-width:1560px) {
    .box {
        width: 100%;
        // max-width: 169rem;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 90rem;
            height: 30rem;
            border-radius: 3rem;
            opacity: 0.5;
            background: #151862;
        }

        .content1 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50rem;
            height: 20rem;

            .content1-p1 {
                text-align: center;
                font-size: 4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 5rem;
            }

            .content1-p1-1 {
                text-align: center;
                font-size: 4.2rem;
                font-family: Microsoft YaHei;
                color: #FFFFFF;
                line-height: 4.5rem;
            }

            .content1-p2-1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 3rem;
            }

            .content1-p2 {
                text-align: center;
                font-size: 2.3rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 3rem;
            }
        }

        img {
            width: 100%;
            max-width: 169rem;
        }
    }
}

// 屏幕1450px时的样式
@media screen and (max-width:1450px) {
    .image-1 {
        width: 100%;
        margin: 0 auto;
        position: relative;

        div {
            width: 15rem;
            height: 5rem;
            text-align: center;
            // margin: 0 auto;
            // border:solid;

            .content-1-p1 {
                font-size: 1.8rem;
                font-weight: 700;
                line-height: 3rem;
            }

            .content-1-p1-1 {
                font-size: 1.3rem;
                font-weight: 700;
                line-height: 2rem;
                margin-bottom: 0.2rem;
            }

            .content-1-p2-1 {
                font-size: 1.3rem;
            }

            .content-1-p2 {
                font-size: 1.4rem;
            }
        }

        .image-1-content1 {
            position: absolute;
            left: 18.8%;
            top: 55%;
        }

        .image-1-content2 {
            position: absolute;
            left: 32%;
            top: 55%;
        }

        .image-1-content3 {
            width: 15rem;
            position: absolute;
            left: 44.8%;
            top: 55%;
        }

        .image-1-content4 {
            position: absolute;
            left: 57.3%;
            top: 55%;
        }

        .image-1-content5 {
            position: absolute;
            left: 70%;
            top: 55%;
        }
    }

    .box {
        .content1 {
            .content1-p1-1 {
                text-align: center;
                font-size: 4.2rem;
                font-family: Microsoft YaHei;
                color: #FFFFFF;
                line-height: 4.5rem;
            }

            .content1-p2-1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 3rem;
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .image-1 {
        width: 120rem;
        margin: 0 auto;
        position: relative;

        div {
            width: 15rem;
            height: 5rem;
            text-align: center;
            // margin: 0 auto;
            // border:solid;

            .content-1-p1 {
                font-size: 1.8rem;
                font-weight: 700;
                line-height: 3rem;
            }

            .content-1-p1-1 {
                font-size: 1.3rem;
                font-weight: 700;
                line-height: 2rem;
                margin-bottom: 0.2rem;
            }

            .content-1-p2-1 {
                font-size: 1.3rem;
            }

            .content-1-p2 {
                font-size: 1.6rem;
            }
        }

        .image-1-content1 {
            position: absolute;
            left: 18%;
            top: 54%;
        }

        .image-1-content2 {
            position: absolute;
            left: 31%;
            top: 54%;
        }

        .image-1-content3 {
            position: absolute;
            left: 43.8%;
            top: 54%;
        }

        .image-1-content4 {
            position: absolute;
            left: 56.5%;
            top: 54%;
        }

        .image-1-content5 {
            position: absolute;
            left: 69.5%;
            top: 54%;
        }
    }

    .box {
        width: 120rem;
        margin: 0 auto;
        position: relative;

        .content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 90rem;
            height: 30rem;
            border-radius: 3rem;
            opacity: 0.5;
            background: #151862;
        }

        .content1 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50rem;
            height: 20rem;

            .content1-p1 {
                text-align: center;
                font-size: 4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 5rem;
            }

            .content1-p1-1 {
                text-align: center;
                font-size: 4.2rem;
                font-family: Microsoft YaHei;
                color: #FFFFFF;
                line-height: 4.5rem;
            }

            .content1-p2-1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 3rem;
            }

            .content1-p2 {
                text-align: center;
                font-size: 2.3rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 3rem;
            }
        }

        img {
            width: 100%;
            max-width: 169rem;
        }
    }
}</style>