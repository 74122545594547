module.exports = {
    language: {
        name: 'en',

    },
    // header and footer
    home: {
        tangku:'TangKu',
        header1: 'Home',
        header2: 'Scenario',
        header3: 'Product',
        header4: 'Help',
        header5: 'About us',
        language: 'English',
        footer1: 'Contact information',
        footer2: 'Address : Room 206, District 1, Hanwei Plaza, No.186 South Fourth Ring West Road, Fengtai District, Beijing',
        footer3: 'Email : market@xingdata.com',
        footer4: 'Phone : 400-800-9406',
        footer5: 'About us',
        footer6: 'Wechat search "Tangku New retail, or directly scan the QR code to follow oh ~"',
        option1: 'Catering industry',
        option2: 'Fast food snacks coffee dinner bar a variety of industries',//snack coffee dinner saloon Multiple industries,
        option3: 'Retail convenience',
        option4: 'Convenience stores and large stores and clothing stores',
        option5: 'Fresh fruit',
        option6: 'Fruit and fresh weighing industry',
        product1: 'Tangku cash register',
        product2: 'Suitable for retail catering self-service software',
        product3: 'Tangku kitchen',
        product4: 'Professional kitchen personnel intelligent system',
        product5: 'Tangku AIDS',
        product6: 'Commodity price tag setting, batch printing price tag, electronic scale add and modify',
        product7: 'Tanku orders food',
        product8: 'Small and convenient handheld device, cashier order good assistant',
        product9: 'Tangku stocktaker',
        product10: 'Thoroughly solve the store inventory problem, support multiple people inventory',
        product11: 'Tangku merchant assistant',
        product12: 'Mobile merchant assistant for quick and easy store management',
    },
    // 首页
    index: {
        swiper1: 'One login account has data ',
        swiper2: 'common to different terminals',
        swiper3: 'cash register self-service inventory system',
        swiper4: 'WEB mobile assistant ordering treasure',
        swiper5: 'Catering retail',
        swiper6: 'Multiple scenario support',
        swiper7: 'Suitable for different types of food',
        swiper8: 'Multiple retail modes are used together',
        swiper9: 'Double screen guest display',
        swiper10: 'Video advertising is imminent',
        swiper11: 'The interactive experience is more friendly',
        swiper12: '',
        goods1: 'Product function',
        goods2: 'Eifferent from the traditional cash register, Tangku has done a comprehensive system upgrade,',
        goods3: 'experience upgrade, merchants say good, consumers also say good.',
        md: 'Store management',
        md1: `In-store operations, business reports, marketing strategies, member information, store video surveillance, etc., mobile phones, PCS at any time and anywhere to master the overall situation`,
        hy: 'Membership marketing',
        hy1: 'Play member marketing, you can set up member points system, top up gift, full amount immediate reduction and cash back activities, real return more funds.',
        zf: 'Aggregate payment',
        zf1: 'Relying on intelligent terminal equipment, it provides powerful basic cash register, aggregate payment and other functions for the daily business activities of merchants.',
        zz: 'Value-added service',
        zz1: 'Member management, marketing, model diversification, precision, humanization, service, marketing information instantly reach the target member, communication and interaction through the barrier-free.',
        tj: 'Statistical analysis',
        tj1: 'Support mobile phones and PCS to view reports and discover the value behind business data. Pay attention to "Tangku", experience immediately!',
        cj: 'Application scenario',
        cj1: 'Multi-angle support for various scenarios, a variety of intelligent marketing programs.',
        hz: 'Business partner',
        hz1: 'Tangku Cash Register will work with many partners to provide you with more professional system services',
    },
    // 餐饮行业
    repast: {
        content1: 'Smart kitchen system',
        content2: 'Different dishes display mode to prevent missing orders',
        content3: 'Scan code to order food, save manpower',
        content4: 'Customers can order their own food through wechat, which is more convenient and fast',
        cy: 'Suitable for a variety of dining outlets',
        cy1: 'Fast food, dinner, coffee cake milk tea',
        cy2: 'Multi-industry application',
        sy: 'Cash register/counter/mini program order',
        sy1: 'Pay first or pay later, in, take out',
        dc: 'Cash Register order :',
        dc1: 'Suitable for fast food, coffee shops, bars, direct order - checkout - transfer to the kitchen - wait for the number - pick up food',
        dc2: 'Table/mini program Order:',
        dc3: 'Suitable for dinner, the clerk uses the ordering treasure to order food, data synchronization cash register, after the kitchen, cash register',
        hc: 'Take orders in the back kitchen',
        hc1: 'The cash register orders, the kitchen receives the order, the receipt prints, the order is completed, the big screen calls the number',
        yj: 'Hardware matching scheme',
        yj1: 'Cash register, self-service machine, scanning code gun, scanning code box, money box, kitchen tablet equipment, number calling equipment',
        yj2: 'Different stores support different configurations',
        zz: 'Self-ordering equipment',
        syj: 'Cash register',
        sc: 'Handheld device',
        azk: 'Android Tablet (KDS)',
        aza: 'Android Tablets (ADS)',
        tv: 'Television (TV)',
        rj: 'Software application',
        rj1: 'Tangku cash register, Tangku auxiliary tools, Tangku inventory system',
        tksy: 'Tangku cash register',
        tksy1: 'Tangku smart store, offline cash register, suitable for various cash registers',
        tkcf: 'Tangku kitchen',
        tkcf1: 'An intelligent system for the kitchen staff',
        tkdc: 'Tanku orders food',
        tkdc1: 'Small and convenient handheld ordering device',
    },
    // 唐库收银
    cash: {
        content1: 'Cash register system',
        content2: 'expediency Brain power Save trouble and effort',
        content3: 'Retail/Catering/self-service',
        content4: 'Software download',
        ls: 'Retail catering, a variety of industry support',
        ls1: 'Fresh fruit, convenience store, large supermarket, clothing store, fast food, dinner, milk tea',
        ls2: 'Multi-industry application',
        rjys: 'Software advantage',
        rjys1: 'A variety of marketing, aggregate payment, a variety of different page adaptation, diversified report response speed',
        rjys2: '',
        xs: 'Sales data cloud backup, support offline cash register',
        xs1: 'Suitable for use in multi-industry physical stores, to meet a variety of different cashier needs,',
        xs2: 'dinner cash register, fast food cash register, scan code cash register, express cash register',
        xs3: 'Support rich gameplay, discount, erase, discount, change price, queue up to book, write off coupons and so on',
        cz: 'Applicable to merchant service scenarios',
        cz1: 'Mom-and-pop stores, small chain stores, medium-sized chain stores, large chain stores',
        cz2: 'Different solutions',
        md: 'Small store',
        md1: 'Mom-and-pop stores, single stores',
        md2: 'A cash register solves the problem',
        md3: ' Medium and large stores',
        md4: 'Solve the basic store cash register, purchase, sale and stock report support',
        md5: 'Large chain store',
        md6: 'Multi-store transfer, inventory of a variety of marketing program support',
        ry: 'Complete hardware and software',
        ry1: 'Cash register, self-service machine, integrated scale, electronic scale, scanning code gun, scanning code box, money box',
        ry2: 'Different stores support different configurations',
    },
    // 水果生鲜
    fruits: {
        swiper1: 'Integrated scale',
        swiper2: '',
        swiper3: 'Redefining the electronic scale',
        swiper4: 'Set weighing, cash register, printing as one, powerful function',
        swiper5: 'Direct contact screen can complete the operation, simple and convenient',
        md: 'Suitable for a variety of fresh stores',
        md1: 'Vegetable shop, fruit shop, seafood shop, deli, meatloaf',
        md2: 'Multi-industry application',
        hx: 'The core function of fresh system',
        hx1: 'order management, purchase management, sales management, report diversification',
        hx2: 'directional quotation',
        dd: 'Order management',
        dd1: 'To be shipped, completed, after-sales order management',
        cg: 'Purchasing management',
        cg1: 'Enter purchase order in bulk and change inventory after review',
        jcx: 'Purchase, sales and inventory management',
        jcx1: 'Warehousing, warehousing, inventory, dynamic inventory, digital management',
        dx: 'Directional quotation',
        dx1: 'Different customers have different prices,support price change,flexible management of goods',
        zn: 'Intelligent inventory',
        zn1: 'Many people group inventory, after the audit to change the inventory inventory more accurate',
        jy: 'Business analysis',
        jy1: 'Gross profit, sales data, various reports to meet different needs',
        sc: 'Online mall',
        sc1: 'Small program online mall, support delivery, self-pickup and other different shopping methods',
        goods: 'Product search',
        goods1: 'Find products quickly, and the number of categories can be accurately positioned',
        kc: 'Precision inventory',
        kc1: 'Manual addition, purchase order purchase, inventory check, review warehousing, report loss and overflow',
        kc2: '',
        content1: 'Report losses and spills',
        content2: 'Warehousing management',
        content3: 'Loss report record',
        content4: 'Commodity archives',
        content5: 'Inventory management',
        content6: 'Outbound management',
        content7: 'Transaction record',
        content8: 'Stock count',
        content9: 'Cloud background',
        cx: 'Package promotion flexible price change',
        cx1: 'Support different prices for different customers of the same goods, support packaging, support fixed prices, or pro rata price modification',
        cx2: '',
        yj: 'Hardware matching scheme',
        yj1: 'Cash register, integrated scale, electronic scale, scanning gun, scanning box, money box,',
        yj2: 'Different stores support different configurations',
        ytc: 'Integrated scale',
        syj: 'Cash register',
        dzc: 'Electronic scale',
        rjyy: 'Software application',
        rjyy1: 'Tangku cash register, Tangku auxiliary tools, Tangku inventory system',
        tksy: 'Tangku cash register',
        tksy1: 'Tangku smart store, offline cash register, suitable for various cash registers',
        tkfz: 'Tangku AIDS',
        tkfz1: 'Commodity price tag setting, batch printing commodity price tag',
        tkpdq: 'Tangku stocktaker',
        tkpdq1: 'Thoroughly solve the store inventory problem, support multiple people inventory',
    },
    // 零售便利
    retail: {
        swiper1: 'Intelligent retail system',
        swiper2: 'Business is booming. Good assistant',
        swiper3: 'Multidimensional data analysis',
        swiper4: 'Comprehensive and multi-dimensional big data analysis to monitor real-time store operation status',
        md: 'Suitable for a variety of retail outlets',
        md1: 'Mom-and-pop stores, convenience stores, chain stores, large supermarkets',
        md2: 'multi-industry application',
        gn: 'Multiple functions to meet requirements',
        gn1: 'Multiple marketing, aggregate payment, a variety of different page adaptation, report diversification',
        gn2: 'Sensitive reaction speed',
        zd: 'Omni-channel multi-terminal coverage',
        zd1: 'Micro mall multi-channel access to customers, a variety of marketing to attract customers',
        content1: 'Helper applet',
        content2: 'Cash register system',
        content3: 'backstage',
        content4: 'Price tag',
        content5: 'Inventory tool',
        content6: 'Micro mall',
        cg: 'Purchasing data report',
        cg1: 'Easy to use, easy to enter orders, purchase, sales. Stock. Early warning reminder, report statistics',
        yj: 'Hardware matching scheme',
        yj1: 'Cash register, scanning gun, scanning box, money box, hand-held inventory device',
        yj2: 'the same store supports different configurations',
        syj: 'Cash register',
        pdq: 'Inventory equipment',
        rjyy: 'Software application',
        rjyy1: 'Tangku cash register, Tangku auxiliary tools, Tangku inventory system',
        tksy: 'Tangku cash register',
        tksy1: 'Tangku smart store, offline cash register, suitable for various cash registers',
        tkfz: 'Tangku ATS',
        tkfz1: 'Commodity price tag setting, batch printing commodity price tag',
        tkpdq: 'Tangku stocktaker',
        tkpdq1: 'Thoroughly solve the store inventory problem, support multiple people inventory',
        image1: 'Smart cash register',
        image2: 'Multiple payment methods',
        image3: 'Intelligent inventory',
        image4: 'Group count',
        image5: 'Purchasing assistant',
        image6: 'Intelligent purchase order',
        image7: 'Online mall',
        image8: 'Mini program online shopping',
        image9: 'Chain supply',
        image10: 'Multi-store chain supply',
        ck: 'Outbound',
        rk: 'Warehousing',
        mdtb: 'Store transfer',
        cgjh: 'Purchase',
        kcpd: 'Stock count',
        lsmd: 'Chain store 1',
        goods: 'Goods off the shelf',
        lsmd1: 'Chain store',
        data: 'Aggregate online and offline data',
        lsmd2: 'Chain store II',
        kcyj: 'Inventory warning',
        dgoods: 'Multi-specification goods',
        pgoods: 'Preferred commodity',
    },
    // 唐库点餐
    order: {
        swiper1: 'To-order roll',
        swiper2: 'Mobile phones are faster to order food',
        swiper3: 'You can order and check out with a phone',
        swiper4: '',
        swiper5: 'Software download',
        zt: 'Table usage at a glance',
        zt1: 'Use different states of the table without color distinction',
        zt2: 'leisure  occupy  Make an appointment',
        ztzt: 'Different state table color distinction',
        ztzt1: 'leisure ',
        ztzt2: 'occupy ',
        ztzt3: ' reserve',
        dc: 'Order quickly to save time',
        dc1: 'Product group is clear, quickly find goods, order more convenient',
        data: 'Cash register orders, data synchronization',
        data1: 'Synchronously process orders on the cash register without manual processing, and automatically print to the kitchen after placing an order. It also supports the functions of opening, placing, adding and returning orders in the kitchen for an order, which greatly improves the efficiency of waiters and can manage the number of tables',
        data2: '',
        content1: 'Make ordering easier',
        content2: 'Multi-scene full process support, to meet the business needs of different scenarios and personalized customization, so that ordering is very simple; Ordering treasure application scenarios;',
        content3: '1. Sit-down ordering, valet ordering, cash register; 2. Order food (canteen, packing)',
        content4: '',
    },
    // 唐库厨房
    kitchen: {
        content1: 'Kitchen system',
        content2: 'Kitchen printing, efficient',
        content3: 'Intelligent food control, eliminate missing orders',
        content4: 'Software download',
        cp: 'A variety of menu display modes',
        cp1: 'Menu display can be selected in a variety of different ways: by order display, by menu display and by room display will automatically summarize the same dishes in chronological order, the chef only needs to make according to the displayed number, goodbye to repetitive labor, efficiency is greatly improved.',
        cp2: '',
        cp3: 'Click to change the progress of dishes to improve work efficiency',
        cp4: 'When the dish exceeds the preset time, the system automatically urges the dish, the chef clicks to change the production progress of the dish, and the kitchen system (KDS) and the dish system (ADS) data are displayed synchronously',
        cp5: '',
        dp: 'Big screen call',
        dp1: 'Desk management - Electronic kitchen display - Calling system - Calling system - Intelligent vegetable control - Food intelligent classification - Order intelligence dynamics - One-click call at the table - Time share stock of meals',
        dp2: '',
    },
    // 唐库盘点器
    stock: {
        swiper1: 'Inventory system',
        swiper2: 'Scanning code inventory saves time and effort',
        swiper3: 'Trade deficit and surplus at a glance',
        swiper4: 'Better grasp of inventory dynamics',
        swiper5: 'Software download',
        pd: 'Multi-person inventory, team cooperation is faster',
        pd1: 'Create inventory list, multiple people join inventory, correction of inventory deficit, one-click submit, modify inventory after verification',
        smpd: 'Code scanning inventory, accurate and rapid',
        smpd1: 'Scan the code bar code, input or add or subtract the number of goods, a number of goods can be tray',
        data: 'Accurate data verification, no errors',
        data1: 'Check the inventory data several times, refuse to make mistakes, verify and audit, and modify the inventory',
        content1: 'Multiple people count simultaneously',
        content2: 'Inventory by product category',
        content3: 'The inventory can be divided into several days',
        content4: 'No inventory of goods will affect sales',
        lp: 'Take inventory of missing goods',
        lp1: 'The missing goods can be re-counted in the non-plate',
        lp2: '',
        jz: 'Correction of commodity quantity',
        jz1: 'The count quantity can be changed before submitting the count result to avoid errors',
        jz2: '',
        data2: 'Data synchronization',
        data3: 'Inventory changes after background audit of inventory data synchronization',
        data4: '',
        dd: 'Multiterminal operation',
        dd1: 'Support mobile phones, mobile terminals, cash registers',
        dd2: '',
    },
    // 唐库辅助工具
    utility: {
        content1: 'Price tag',
        content2: 'Electronic scale system',
        content3: "It's faster to print price tags in bulk",
        content4: 'Software download',
        jq: 'Free to design price tag templates',
        jq1: 'Design price tag size, style template, can be adapted to a variety of different price tag formats',
        goodsjq: 'Print commodity price tags in bulk',
        goodsjq1: 'Print multiple product price tags at a time, do not print one by one, save manpower, you can group, brand, supplier, search for products, select products, click print price tags to print',
        goodsjq2: '',
        dzc: 'Set up different types of electronic scales',
        dzc1: 'Various types of electronic scales can be added',
    },
    // 唐库商家助手
    assistant: {
        swiper1: 'Helper program',
        swiper2: 'Little software goes a long way',
        swiper3: 'Mobile phone store, small and convenient',
        swiper4: 'Wechat search Tangku business assistant',
        xs: 'View sales data anytime, anywhere',
        xs1: 'Keep track of the sales situation at all times, and the store operation is clear and clear',
        dd: 'Sales orders at hand',
        dd1: 'After receiving orders directly processing not in the store can also complete the work',
        md: 'Store data management',
        md1: 'Merchandise Management Member Marketing Management Store management',
        content1: 'repricing',
        content2: 'Commodity',
        content3: 'Inventory',
        content4: 'Purchase',
        content5: 'Membership management',
        content6: 'Membership rules',
        content7: 'coupon',
        content8: 'Promotional activity',
    },
    // 关于我们
    aboutUs: {
        xing: 'Beijing Zhexing Information Technology Co., LTD',
        content1: "Founded in 2013, located in Beijing Zhongguancun Science and Technology Park, is a high-tech enterprise mainly engaged in the development, sales and operation of merchants' digital cloud platform system.",
        content2: 'The core team is composed of senior practitioners from the financial industry, the retail industry and the Internet. The product research and development team is perfect, with Android intelligent terminal application development group, background JAVA, C development group, WEB front-end group, product architecture and UI group, network database operation and maintenance group.',
        content3: 'Tangku new retail cloud platform is a cloud platform SAAS system that has been launched and operated by the company, integrating store MIS cash register, chain store ERP, intelligent marketing, member CRM, aggregate payment PAY, wechat public number/mini program, and electronic invoice. At present, it serves the domestic and foreign super, convenience chain, snack, canteen fast food, coffee and tea house, tobacco hotel, pharmacy and other industries.',
        content4: 'The company will further focus on providing merchants with total solutions for digital transformation (ALL IN ONE), data analysis and mining, retail artificial intelligence research and development and operation exploration.',
        content5: '',
        content6: '',
        content7: '',
        content8: '',
        content9: '',
        content10: '',
        content11: '',
        content12: '',
    }
}