<template>
    <div style="width:100%;height:100%;" :style="bool == true ? '' : 'background:#EEEEEE;'">
        <!-- <div class="dialog" :style="dialogVisible == true ? 'display:block':'display:none'"></div> -->
        <div style="width:100%;" v-if="bool">
            <el-dialog title="" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
                <div style="width:100%;height:100%;display:flex;justify-content: center;align-items: center;">
                    <div class="dialog_content">
                        <img :src="codeUrl" class="dialog_content" />
                    </div>
                </div>
            </el-dialog>
            <div class="home">
                Download Center
            </div>
            <div class="home_active"></div>
            <div class="home_content">
                <div class="home_content_li" v-for="(item, index) in list" :key="index">
                    <!-- <div class="home_content_img" v-if="(item.name == 'TK Waiter' || item.name == 'TK KDS') ? false : true"> -->
                        <!-- <img :src="item.imgUrl" class="home_img" /> -->
                    <!-- </div> -->
                     <!-- v-if="(item.name == 'TK Waiter' || item.name == 'TK KDS') ? true : false" -->
                    <div class="home_content_img_WAITER" >
                        <img :src="item.imgUrl" class="home_img" />
                    </div>
                    <div class="home_content_content">
                        <div class="home_name">{{ item.name }}</div>
                        <div class="home_content_title">{{ item.content }}</div>
                        <div class="home_button">
                            <div class="button_code" @click="getCode(item.codeUrl)">QR code</div>
                            <div class="button_downLoad" @click="getDownLoad(item.value)">DownLoad</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="bool == false ? true : false" style="width:100%;background:#EEEEEE;border-top:solid 1px #EEEEEE;">
            <el-dialog title="" :visible.sync="dialogVisible_1" width="300px" :before-close="handleClose">
                <div style="width:100%;height:100%;display:flex;justify-content: center;align-items: center;">
                    <div style="width:200px;height:200px;">
                        <img :src="codeUrl1" style="width:200px;height:200px;" />
                    </div>
                </div>
            </el-dialog>
            <div style="width:850px;margin:0 auto;background:#EEEEEE">
                <div style="font-size:40px;margin-top:30px;">
                    DownLoad
                </div>
                <el-card class="box-card" :style="index == 0 ? 'margin-top:20px' : ''" v-for="(item, index) in list"
                    :key="index">
                    <div style="display: flex;">
                        <div class="box-image"
                            :style="item.name == 'TK Waiter' ? 'border-radius:20px;overflow:hidden;' : 'border-radius:20px;overflow:hidden;'">
                            <img :src="item.imgUrl" class="box-img" />
                        </div>
                        <div class="box-content">
                            <div class="box-name">
                                {{ item.name }}
                            </div>
                            <div class="box-title">
                                {{ item.content }}
                            </div>
                        </div>
                    </div>
                    <div class="box-button">
                        <div class="box-code" @click="getCode_1(item.codeUrl)">QR code</div>
                        <div class="box-downLaod" @click="getDownLoad(item.value)">DownLoad</div>
                    </div>
                </el-card>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            bool: false,
            dialogVisible: false,
            dialogVisible_1: false,
            codeUrl1: '',
            codeUrl: '',
            list: [
                {
                    name: 'TK POS', // 唐库自助pos收银
                    content: 'A handheld sales terminal that can perform basic daily operations.',
                    value: 'https://tk8-file.oss-cn-beijing.aliyuncs.com/apk/TangKu-POS.apk',
                    imgUrl: require('../assets/css/img/TK_ECR.png'),
                    codeUrl: require('../assets/css/img/POS.png')
                },
                {
                    name: 'TK ECR', // 唐库收银
                    content: 'An all-in-one electronic cash register for ordering, payments, and operational management.',
                    value: 'https://tk8-file.oss-cn-beijing.aliyuncs.com/apk/TangKu-ERP.apk',
                    imgUrl: require('../assets/css/img/TK_ECR.png'),
                    codeUrl: require('../assets/css/img/ERP.png')
                },
                {
                    name: 'TK KDS', // 唐库厨房
                    content: 'Intelligent system developed for kitchen staff.',
                    value: 'https://tk8-file.oss-cn-beijing.aliyuncs.com/apk/TangKu-KS.apk',
                    imgUrl: require('../assets/css/img/TK_KDS.png'),
                    codeUrl: require('../assets/css/img/KDS_1.png')
                },
                {
                    name: 'TK Waiter', // 唐库点餐
                    content: 'A quick and convenient handheld ordering tool for waiters.',
                    value: 'https://tk8-file.oss-cn-beijing.aliyuncs.com/apk/TangKu-Waiter.apk',
                    imgUrl: require('../assets/css/img/TK_WAITER.png'),
                    codeUrl: require('../assets/css/img/Waiter.png')
                },
            ]
        }
    },
    mounted() {
        document.title = "DownLoad"
        if (document.documentElement.clientWidth >= 900) {
            return;
        }
        this.bool = true;
        console.log(document.documentElement.clientWidth)
        console.log(document.documentElement);
        // document.documentElement.classList.add('responsive');
        this.setRem(); // 初始设置 rem
        window.addEventListener('resize', this.setRem); // 监听窗口大小变化
        console.log(document.documentElement)
    },
    beforeDestroy() {
        if (document.documentElement.clientWidth >= 900) {
            return;
        }
        // document.documentElement.classList.remove('responsive');
        window.removeEventListener('resize', this.setRem); // 清理事件监听
    },
    methods: {
        getDownLoad(val) {
            const url = val; // 替换为你要下载的文件的 URL
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        handleClose(done) {
            this.codeUrl1 = '';
            this.codeUrl = ''
            done()
        },
        getCode_1(val) {
            console.log(val)
            this.codeUrl1 = val;
            this.dialogVisible_1 = true;
        },
        getCode(val) {
            this.codeUrl = val;
            this.dialogVisible = true;
        },
        setRem() {
            const baseWidth = 750;
            const html = document.documentElement;
            const width = html.clientWidth;
            html.style.fontSize = (width / 750) + 'px'; // 例如：将视口宽度的 1/10 作为根字体大小
        }
    }

}
</script>
<style lang="less" scoped>
@media screen and (min-width:901px) {
    /deep/ .el-dialog {
        height: 350px;
    }
}

@media screen and(max-width:900px) {
    /deep/ .el-dialog {
        height: 600rem;
    }
}


.dialog_content {
    width: 400rem;
    height: 400rem;
}

.dialog {
    width: 80%;
    height: 500rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
}

.home {
    height: 140rem;
    width: 100%;
    border-bottom: solid 1.5px #E8E8E8;
    font-size: 45rem;
    line-height: 140rem;
    box-sizing: border-box;
    padding-left: 20rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: #FFFFFF;
}

.home_content {
    width: 95%;
    margin: 0 auto;
    margin-top: 40rem;
    box-sizing: border-box;
    padding-top: 10rem;
}

.home_content_li {
    width: 100%;
    height: 220rem;
    display: flex;
    margin-bottom: 60rem;
}

.home_content_img {
    height: 150rem;
    width: 150rem;
}

.home_content_img_WAITER {
    height: 150rem;
    width: 150rem;
    border-radius: 30rem;
    overflow: hidden;
}

.home_content_content {
    height: 100%;
    width: calc(100% - 150rem);
    // border: solid 1px;
    border-left: none;
    box-sizing: border-box;
    padding-left: 20rem;
    position: relative;
}

.home_content_content_button {
    height: 100%;
    width: 100rem;
    border: solid;
}

.home_name {
    font-size: 42rem;
}

.home_content_title {
    overflow-wrap: break-word;
    /* 现代标准 */
    word-break: break-all;
    /* 旧版浏览器 */
    hyphens: auto;
    line-height: 28rem;
    font-size: 28rem;
    color: #858687;
}

.home_img {
    height: 100%;
    width: 100%;
}

.home_button {
    position: absolute;
    bottom: 0;
    right: 0;
}

.button_code {
    width: 150rem;
    height: 60rem;
    font-size: 30rem;
    border: solid 1px #858687;
    border-radius: 35rem;
    text-align: center;
    line-height: 60rem;
    display: inline-block;
    margin-right: 20rem;
    color: #858687;
}

.button_downLoad {
    width: 180rem;
    height: 60rem;
    font-size: 30rem;
    border: solid 1px;
    border-radius: 35rem;
    text-align: center;
    line-height: 60rem;
    display: inline-block;
    background: #9B3433;
    color: #FFFFFF;
}

.home_active {
    width: 100%;
    height: 140rem;
    background: #ffffff;
}

.box-card {
    margin-top: 50px;
    height: 100px;
    position: relative;
}

.box-image {
    width: 80px;
    height: 80px;
    margin-left: -10px;
    margin-top: -10px;
}

.box-img {
    width: 100%;
    height: 100%;
}

.box-content {
    margin-top: -10px;
    margin-left: 10px;
    width: calc(100% - 250px);
    height: 80px;
}

.box-name {
    font-size: 22px;
    margin-bottom: 10px;
}

.box-title {
    font-size: 16px;
    line-height: 18px;
    color: #858687;
}

.box-button {
    position: absolute;
    right: 10px;
    top: 45px;
    display: flex;
}

.box-code {
    font-size: 16px;
    width: 80px;
    height: 35px;
    border: solid 1px #858687;
    border-radius: 17.5px;
    text-align: center;
    line-height: 35px;
    color: #858687;
}

.box-downLaod {
    margin-left: 10px;
    font-size: 16px;
    width: 100px;
    height: 35px;
    border: solid 1px;
    border-radius: 17.5px;
    text-align: center;
    line-height: 35px;
    background: #9B3433;
    color: #FFFFFF;
}
</style>
