<template>
    <div class="section">
        <div class="box">
            <img src="../assets/css/img/1-6-1.png" alt="">
            <div class="box-content">
                <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                    <!-- 一体 -->
                    {{ $t('fruits.swiper1') }}
                    <span>
                        <!-- 称 -->
                        {{ $t('fruits.swiper2') }}
                    </span>
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                    <!-- 重新定义电子秤 -->
                    {{ $t('fruits.swiper3') }}
                </p>
                <div class="content-div">
                    <p style="width:30rem;height:0.5rem;background:#C89783;" :class="$i18n.locale == 'en' ? 'act' : 'act1'">
                    </p>
                </div>
                <p :class="$i18n.locale == 'en' ? 'content-p2-1' : 'content-p2'">
                    <!-- 集合称重、收银、打印为一体，功能强大 -->
                    {{ $t('fruits.swiper4') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-p2-1' : 'content-p2'">
                    <!-- 直接触屏即可完成操作，简单方便 -->
                    {{ $t('fruits.swiper5') }}
                </p>
            </div>
        </div>
        <!-- 生鲜门店 -->
        <div class="content-1">
            <p class="content-p1">
                <!-- 适用多种生鲜门店 -->
                {{ $t('fruits.md') }}
            </p>
            <p class="content-p2">
                <!-- 蔬菜店、水果店、海鲜店、熟食店、肉联 -->
                {{ $t('fruits.md1') }}
            </p>
            <p class="content-p2">
                <!-- 多行业适用 -->
                {{ $t('fruits.md2') }}
            </p>
        </div>
        <!-- 多行业适合 -->
        <div class="content-2">
            <ul style="width:105rem;display:flex;justify-content:space-around;margin:0 auto;">
                <li class="li-image1">
                    <div class="li-content">
                        <p class="li-p1">
                            蔬菜店
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Vegetables Shop
                        </p>
                    </div>
                </li>
                <li class="li-image2">
                    <div class="li-content">
                        <p class="li-p1">
                            水果店
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Fruit Shop
                        </p>
                    </div>
                </li>
                <li class="li-image3">
                    <div class="li-content">
                        <p class="li-p1">
                            海鲜店
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Seafood restaurant
                        </p>
                    </div>
                </li>
                <li class="li-image4">
                    <div class="li-content">
                        <p class="li-p1">
                            熟食店
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Deli
                        </p>
                    </div>
                </li>
                <li class="li-image5">
                    <div class="li-content">
                        <p class="li-p1">
                            肉联
                        </p>
                        <p class="p-border"></p>
                        <p class="li-p2">
                            Meat joint
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="section-t4" :class="$i18n.locale == 'en' ? 'active4' : 'active5'">
            <!-- 生鲜系统核心功能 -->
            <div class="content-1">
                <p class="content-p1">
                    <!-- 生鲜系统核心功能  -->
                    {{ $t('fruits.hx') }}
                </p>
                <p class="content-p2">
                    <!-- 订单管理、采购管理、进销存管理、报表多样化 -->
                    {{ $t('fruits.hx1') }}
                </p>
                <p class="content-p2">
                    <!-- 定向报价 -->
                    {{ $t('fruits.hx2') }}
                </p>
            </div>
            <!-- 核心功能 -->
            <div class="content-3-1">
                <ul style="width:120rem;display:flex;justify-content:space-around;"
                    :class="$i18n.locale == 'en' ? 'active2' : 'active3'">
                    <li class="li-1">
                        <div class="li-div">
                            <p class="li-p1">
                                <!-- 订单管理 -->
                                <span :class="$i18n.locale == 'en' ? 'active' : ''">
                                    {{ $t('fruits.dd') }}
                                </span>
                            </p>
                            <p class="li-p2">
                                <!-- 待发货、已完成、售后订单管理 -->
                                <span :class="$i18n.locale == 'en' ? 'active1' : ''">
                                    {{ $t('fruits.dd1') }}
                                </span>
                            </p>
                            <!-- <p class="li-p2">理</p> -->
                        </div>
                    </li>
                    <li class="li-2">
                        <div class="li-div">
                            <p class="li-p1">
                                <!-- 采购管理 -->
                                <span :class="$i18n.locale == 'en' ? 'active' : ''">
                                    {{ $t('fruits.cg') }}
                                </span>
                            </p>
                            <p class="li-p2">
                                <!-- 批量录入采购单，审核之后更改库存 -->
                                <span :class="$i18n.locale == 'en' ? 'active1' : ''">
                                    {{ $t('fruits.cg1') }}
                                </span>
                            </p>
                            <!-- <p class="li-p2">理</p> -->
                        </div>
                    </li>
                    <li class="li-3">
                        <div class="li-div">
                            <p class="li-p1">
                                <!-- 进销存管理 -->
                                <span :class="$i18n.locale == 'en' ? 'active' : ''" style="margin-top:0rem;">
                                    {{ $t('fruits.jcx') }}
                                </span>
                            </p>
                            <p class="li-p2">
                                <!-- 出库、入库、盘点、动态库存，数字化管理 -->
                                <span :class="$i18n.locale == 'en' ? 'active1' : ''" style="margin-top:0rem;">
                                    {{ $t('fruits.jcx1') }}
                                </span>
                            </p>
                            <!-- <p class="li-p2">理</p> -->
                        </div>
                    </li>
                    <li class="li-4">
                        <div class="li-div">
                            <p class="li-p1">
                                <!-- 定向报价 -->
                                <span :class="$i18n.locale == 'en' ? 'active' : ''">
                                    {{ $t('fruits.dx') }}
                                </span>
                            </p>
                            <p class="li-p2">
                                <!-- 不同客户不同价格，支持改价，灵活管理商品  -->
                                <span :class="$i18n.locale == 'en' ? 'active1' : ''">
                                    {{ $t('fruits.dx1') }}
                                </span>
                            </p>
                            <!-- <p class="li-p2">理</p> -->
                        </div>
                    </li>
                </ul>
                <ul style="width:120rem;display:flex;justify-content:space-around;margin:0 auto;">
                    <li class="li-5">
                        <div class="li-div">
                            <p class="li-p1">
                                <!-- 智能盘点 -->
                                <span :class="$i18n.locale == 'en' ? 'active' : ''">
                                    {{ $t('fruits.zn') }}
                                </span>
                            </p>
                            <p class="li-p2">
                                <!-- 多人分组盘点，审核之后改库存库存更精准  -->
                                <span :class="$i18n.locale == 'en' ? 'active1' : ''">
                                    {{ $t('fruits.zn1') }}
                                </span>
                            </p>
                            <!-- <p class="li-p2">理</p> -->
                        </div>
                    </li>
                    <li class="li-6">
                        <div class="li-div">
                            <p class="li-p1">
                                <!-- 经营分析 -->
                                <span :class="$i18n.locale == 'en' ? 'active' : ''">
                                    {{ $t('fruits.jy') }}
                                </span>
                            </p>
                            <p class="li-p2">
                                <!-- 毛利、销售数据、各种报表满足不同需求 -->
                                <span :class="$i18n.locale == 'en' ? 'active1' : ''">
                                    {{ $t('fruits.jy1') }}
                                </span>
                            </p>
                            <!-- <p class="li-p2">理</p> -->
                        </div>
                    </li>
                    <li class="li-7">
                        <div class="li-div">
                            <p class="li-p1">
                                <!-- 线上商城 -->
                                <span :class="$i18n.locale == 'en' ? 'active' : ''">
                                    {{ $t('fruits.sc') }}
                                </span>
                            </p>
                            <p class="li-p2">
                                <!-- 小程序线上商城，支持配送、自提等不同购物方式 -->
                                <span :class="$i18n.locale == 'en' ? 'active1' : ''">
                                    {{ $t('fruits.sc1') }}
                                </span>
                            </p>
                            <!-- <p class="li-p2">理</p> -->
                        </div>
                    </li>
                    <li class="li-8">
                        <div class="li-div">
                            <p class="li-p1">
                                <!-- 商品搜索 -->
                                <span :class="$i18n.locale == 'en' ? 'active' : ''">
                                    {{ $t('fruits.goods') }}
                                </span>
                            </p>
                            <p class="li-p2">
                                <!-- 快速查找商品，品类再多也能精准定位 -->
                                <span :class="$i18n.locale == 'en' ? 'active1' : ''">
                                    {{ $t('fruits.goods1') }}
                                </span>
                            </p>
                            <!-- <p class="li-p2">理</p> -->
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 精准库存 -->
        <div class="content-1">
            <p class="content-p1">
                <!-- 精准库存 -->
                {{ $t('fruits.kc') }}
            </p>
            <p class="content-p2">
                <!-- 手动添加、采购单进货、库存盘点，审核入库，报损报溢 -->
                {{ $t('fruits.kc1') }}
            </p>
            <p class="content-p2">
                <!-- 保证库存精准无误 -->
                {{ $t('fruits.kc2') }}
            </p>
        </div>
        <!-- 图1 -->
        <div class="content-image1">
            <div class="content-div1">
                <img src="../assets/css/img/3-5-2.png">
                <p :class="$i18n.locale == 'en' ? 'content-div1-p1-1' : 'content-div1-p1'">
                    <!-- 报损报溢 -->
                    {{ $t('fruits.content1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-div1-p2-1' : 'content-div1-p2'">
                    <!-- 入库管理 -->
                    {{ $t('fruits.content2') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-div1-p3-1' : 'content-div1-p3'">
                    <!-- 报损记录 -->
                    {{ $t('fruits.content3') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-div1-p4-1' : 'content-div1-p4'">
                    <!-- 商品档案 -->
                    {{ $t('fruits.content4') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-div1-p5-1' : 'content-div1-p5'">
                    <!-- 库存管理 -->
                    {{ $t('fruits.content5') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-div1-p6-1' : 'content-div1-p6'">
                    <!-- 出库管理 -->
                    {{ $t('fruits.content6') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-div1-p7-1' : 'content-div1-p7'">
                    <!-- 交易记录 -->
                    {{ $t('fruits.content7') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-div1-p8-1' : 'content-div1-p8'">
                    <!-- 库存盘点 -->
                    {{ $t('fruits.content8') }}
                </p>
                <p class="content-div1-p9">
                    <!-- 云后台 -->
                    {{ $t('fruits.content9') }}
                </p>
            </div>
        </div>
        <!-- 打包促销 -->
        <div class="content-1" style="margin-top:5rem;">
            <p class="content-p1">
                <!-- 打包促销 灵活改价 -->
                {{ $t('fruits.cx') }}
            </p>
            <p class="content-p2">
                <!-- 支持相同商品不同客户不同价格、支持打包处理 -->
                {{ $t('fruits.cx1') }}
            </p>
            <p class="content-p2">
                <!-- 支持按固定价、或按比例修改价格 -->
                {{ $t('fruits.cx2') }}
            </p>
        </div>
        <!-- 图2 -->
        <div class="content-image2">
            <img src="../assets/css/img/3-5-1.png" alt="">
        </div>
        <div class="section-t4">
            <!-- 硬件配置方案-->
            <div class="content-1">
                <p class="content-p1">
                    <!-- 硬件配套方案 -->
                    {{ $t('fruits.yj') }}
                </p>
                <p class="content-p2">
                    <!-- 收银机、一体秤、电子秤、扫码枪、扫码盒子、钱箱、 -->
                    {{ $t('fruits.yj1') }}
                </p>
                <p class="content-p2">
                    <!-- 不同门店支持不同配置 -->
                    {{ $t('fruits.yj2') }}
                </p>
            </div>
            <!-- 硬件-->
            <div class="content-4">
                <div class="content-4-1">
                    <p :class="$i18n.locale == 'en' ? 'active6' : ''">
                        <!-- 一体秤 -->
                        {{ $t('fruits.ytc') }}
                    </p>
                    <div style="width:42rem;position:absolute;left:17rem;top:4rem;">
                        <img src="../assets/css/img/dianzicheng.png" style="width:100%;">
                    </div>
                </div>
                <div class="content-4-2">
                    <div class="content-s">
                        <p :class="$i18n.locale == 'en' ? 'active6' : ''">
                            <!-- 收银机 -->
                            {{ $t('fruits.syj') }}
                        </p>
                        <div style="width:16rem;position:absolute;left:10rem;top:5rem;">
                            <img src="../assets/css/img/zhuotaikaobei.png" style="width:100%;">
                        </div>
                    </div>
                    <div class="content-d">
                        <p :class="$i18n.locale == 'en' ? 'active6' : ''">
                            <!-- 电子秤 -->
                            {{ $t('fruits.dzc') }}
                        </p>
                        <div style="width:16rem;position:absolute;left:10rem;top:5rem;">
                            <img src="../assets/css/img/cheng.png" style="width:100%;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 软件应用-->
        <div class="content-1">
            <p class="content-p1">
                <!-- 软件应用 -->
                {{ $t('fruits.rjyy') }}
            </p>
            <p class="content-p2">
                <!-- 唐库收银、唐库辅助工具、唐库盘点系统 -->
                {{ $t('fruits.rjyy1') }}
            </p>
        </div>
        <!-- 软件 -->
        <div class="content-3" style="margin-bottom:7rem;">
            <div>
                <img src="../assets/css/img/tangkushouyin@2x.png">
                <p class="img-p1">
                    <!-- 唐库收银 -->
                    {{ $t('fruits.tksy') }}
                </p>
                <p class="img-p2">
                    <!-- 唐库智慧门店，线下收银，适配各种收银机 -->
                    {{ $t('fruits.tksy1') }}
                </p>
            </div>
            <div>
                <img src="../assets/css/img/jiaqian@2x.png">
                <p class="img-p1">
                    <!-- 唐库辅助工具 -->
                    {{ $t('fruits.tkfz') }}
                </p>
                <p class="img-p2">
                    <!-- 商品价签设置，批量打印商品价签 -->
                    {{ $t('fruits.tkfz1') }}
                </p>
            </div>
            <div>
                <img src="../assets/css/img/pandian_01@2x.png">
                <p class="img-p1">
                    <!-- 唐库盘点器 -->
                    {{ $t('fruits.tkpdq') }}
                </p>
                <p class="img-p2">
                    <!-- 彻底解决门店盘点难题，支持多人盘点 -->
                    {{ $t('fruits.tkpdq1') }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'fruits',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang="less" scoped>
.act {
    margin-left: 17rem;
}

.act1 {
    display: block;
    margin: 0 auto;
}

.section {
    width: 100%;
    height: 100%;
}

.active5 {
    height: 58rem;
}

.active4 {
    height: 66rem;
}

.section-t4 {
    width: 100%;
    background: #F2F3F4;
    border: solid 1px #F2F3F4;
    margin-top: 5rem;
}

.active3 {
    margin-bottom: 3.5rem;
}

.active2 {
    margin-bottom: 6rem;
}

.active {
    font-size: 1.8rem;
    display: block;
    margin-top: 1rem;
}

.active1 {
    font-size: 1.6rem;
    display: block;
    // border:solid;
    margin-top: 2.3rem;
}

.content-4 {
    width: 109rem;
    height: 50rem;
    margin: 0 auto;
    margin-bottom: 5rem;
    display: flex;
    justify-content: space-between;

    p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #121212;
        line-height: 22px;
    }

    .active6 {
        font-size: 1.8rem;
    }

    .content-4-1 {
        width: 72rem;
        height: 49rem;
        border-radius: 1rem;
        background: #FFFFFF;
        padding-top: 2rem;
        padding-left: 2rem;
        position: relative;
    }

    .content-4-2 {
        width: 35rem;
        height: 49rem;

        .content-s,
        .content-d {
            width: 36rem;
            height: 23.8rem;
            border-radius: 1rem;
            background: #FFFFFF;
            padding-top: 2rem;
            padding-left: 2rem;
            position: relative;
        }

        .content-s {
            margin-bottom: 1.5rem;
        }
    }
}

.content-3 {
    width: 110rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;

    div {
        width: 35rem;
        text-align: center;

        img {
            width: 20%;
            margin-bottom: 2rem;
        }
    }

    .img-p1 {
        font-size: 2.1rem;
        font-family: Microsoft YaHei;
        font-weight: 700;
        color: #121212;
        line-height: 22px;
        margin-bottom: 0.7rem;
    }

    .img-p2 {
        font-size: 1.8rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 2rem;
    }
}

.content-image2 {
    width: 100rem;
    margin: 0 auto;

    img {
        width: 100%;
    }
}

.content-image1 {
    width: 100rem;
    margin: 0 auto;
    text-align: center;
    margin-top: -7rem;

    .content-div1 {
        width: 55rem;
        margin: 0 auto;
        position: relative;

        img {
            width: 100%;
        }

        p {
            width: 10rem;
            text-align: center;
            font-size: 1.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #121212;
        }

        .content-div1-p1 {
            position: absolute;
            left: 4.5rem;
            top: 8.5rem;
        }

        .content-div1-p1-1 {
            width: 20rem;
            font-size: 1.7rem;
            position: absolute;
            left: -5.5rem;
            top: 8.5rem;
        }

        .content-div1-p2 {
            position: absolute;
            left: 45rem;
            top: 8.5rem;
        }

        .content-div1-p2-1 {
            width: 25rem;
            // border:solid;
            font-size: 1.7rem;
            position: absolute;
            left: 44.5rem;
            top: 8.7rem;
        }

        .content-div1-p3 {
            position: absolute;
            left: -6.1rem;
            top: 18rem;
        }

        .content-div1-p3-1 {
            width: 20rem;
            // border:solid;
            font-size: 1.7rem;
            position: absolute;
            left: -14.5rem;
            top: 18rem;
        }

        .content-div1-p4 {
            position: absolute;
            left: 54.7rem;
            top: 20.5rem;
        }

        .content-div1-p4-1 {
            width: 20rem;
            // border:solid;
            font-size: 1.7rem;
            position: absolute;
            left: 54rem;
            top: 20.5rem;
        }

        .content-div1-p5 {
            position: absolute;
            left: -9.8rem;
            top: 31.8rem;
        }

        .content-div1-p5-1 {
            width: 20rem;
            // border:solid;
            font-size: 1.7rem;
            position: absolute;
            left: -19.5rem;
            top: 31.8rem;
        }

        .content-div1-p6 {
            position: absolute;
            left: 50.5rem;
            top: 34.5rem;
        }

        .content-div1-p6-1 {
            width: 20rem;
            // border:solid;
            font-size: 1.7rem;
            position: absolute;
            left: 51rem;
            top: 34.5rem;
        }

        .content-div1-p7 {
            position: absolute;
            left: 0rem;
            top: 41.7rem;
        }

        .content-div1-p7-1 {
            width: 20rem;
            // border:solid;
            font-size: 1.7rem;
            position: absolute;
            left: -8rem;
            top: 41.7rem;
        }

        .content-div1-p8 {
            position: absolute;
            left: 40rem;
            top: 43.7rem;
        }

        .content-div1-p8-1 {
            width: 20rem;
            // border:solid;
            font-size: 1.7rem;
            position: absolute;
            left: 36rem;
            top: 43.7rem;
        }

        .content-div1-p9 {
            position: absolute;
            font-weight: 700;
            font-size: 2rem;
            left: 23.8rem;
            top: 26rem;
        }
    }
}

.content-3-1 {
    width: 120rem;
    margin: 0 auto;
    margin-top: -2rem;

    li {
        width: 25rem;
        height: 17rem;
        // border:solid;
    }

    .li-1 {
        background: url('../assets/css/img/icon_3-3-1.png') no-repeat;
        background-position: 50% 20%;
        padding-top: 9rem;

        .li-div {
            width: 25rem;
            height: 3rem;
            margin: 0 auto;

            .li-p1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                text-align: center;
                font-size: 1.7rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }

    .li-2 {
        background: url('../assets/css/img/icon_3-3-2.png') no-repeat;
        background-position: 50% 20%;
        padding-top: 9rem;

        .li-div {
            width: 25rem;
            height: 3rem;
            margin: 0 auto;

            .li-p1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                text-align: center;
                font-size: 1.7rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }

    .li-3 {
        background: url('../assets/css/img/icon_3-3-3.png') no-repeat;
        background-position: 50% 20%;
        padding-top: 9rem;

        .li-div {
            width: 25rem;
            height: 3rem;
            margin: 0 auto;

            .li-p1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                text-align: center;
                font-size: 1.7rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }

    .li-4 {
        background: url('../assets/css/img/icon_3-3-4.png') no-repeat;
        background-position: 50% 20%;
        padding-top: 9rem;

        .li-div {
            width: 25rem;
            height: 3rem;
            margin: 0 auto;

            .li-p1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                text-align: center;
                font-size: 1.7rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }

    .li-5 {
        background: url('../assets/css/img/icon_3-3-5.png') no-repeat;
        background-position: 50% 20%;
        padding-top: 9rem;

        .li-div {
            width: 25rem;
            height: 3rem;
            margin: 0 auto;

            .li-p1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                text-align: center;
                font-size: 1.7rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }

    .li-6 {
        background: url('../assets/css/img/icon_3-3-6.png') no-repeat;
        background-position: 50% 20%;
        padding-top: 9rem;

        .li-div {
            width: 25rem;
            height: 3rem;
            margin: 0 auto;

            .li-p1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                text-align: center;
                font-size: 1.7rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }

    .li-7 {
        background: url('../assets/css/img/icon_3-3-7.png') no-repeat;
        background-position: 50% 20%;
        padding-top: 9rem;

        .li-div {
            width: 25rem;
            height: 3rem;
            margin: 0 auto;

            .li-p1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                text-align: center;
                font-size: 1.7rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }

    .li-8 {
        background: url('../assets/css/img/icon_3-3-8.png') no-repeat;
        background-position: 50% 20%;
        padding-top: 9rem;

        .li-div {
            width: 25rem;
            height: 3rem;
            margin: 0 auto;

            .li-p1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                text-align: center;
                font-size: 1.7rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }
}

.content-2 {
    width: 120rem;
    margin: 0 auto;
    // margin-top: 5rem;
    // margin-bottom: 10rem;

    li {
        width: 18rem;
        height: 18rem;
    }

    .li-image1 {
        background: url(../assets/css/img/VegetablesShop.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image1:hover {
        background: url(../assets/css/img/VegetablesShop01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image2 {
        background: url(../assets/css/img/FruitShop.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image2:hover {
        background: url(../assets/css/img/FruitShop01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image3 {
        background: url(../assets/css/img/Seafoodrestaurant.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image3:hover {
        background: url(../assets/css/img/Seafoodrestaurant01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image4 {
        background: url(../assets/css/img/Deli.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image4:hover {
        background: url(../assets/css/img/Deli01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }

    .li-image5 {
        background: url(../assets/css/img/Meatjoint.png) no-repeat;
        background-color: #F2F3F4;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #121212;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #646464;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #D5D5D5;
                margin: 0 auto;
            }
        }
    }

    .li-image5:hover {
        background: url(../assets/css/img/Meatjoint01.png) no-repeat;
        background-color: #4392FE;
        background-position: 50% 25%;
        padding-top: 9rem;

        .li-content {
            width: 18rem;
            height: 3rem;
            text-align: center;

            .li-p1 {
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
                margin-bottom: 1rem;
            }

            .li-p2 {
                margin-top: 0.5rem;
                font-size: 1.6rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.2rem;
            }

            .p-border {
                width: 2rem;
                border: solid 1px #FFFFFF;
                margin: 0 auto;
            }
        }
    }
}

.content-1 {
    width: 100rem;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 3rem;

    .content-p1 {
        text-align: center;
        font-size: 3.6rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        line-height: 6rem;
    }

    .content-p2 {
        text-align: center;
        font-size: 2.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
    }
}

.box {
    width: 100%;
    // max-width: 170rem;
    position: relative;

    img {
        width: 100%;
        // max-width: 170rem;
    }

    .box-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100rem;
        height: 40rem;
        padding-top: 4rem;
        // padding-left: 3rem;

        .content-div {
            width: 40rem;
            margin-bottom: 1.5rem;
        }

        .content-p1 {
            text-align: center;
            width: 40rem;
            font-size: 5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #974629;
            line-height: 6rem;
            margin-bottom: 1.5rem;

            span {
                font-size: 7rem;
            }
        }

        .content-p1-1 {
            // border:solid;
            text-align: center;
            width: 65rem;
            font-size: 4rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #974629;
            line-height: 4.5rem;
            margin-bottom: 1.5rem;
        }

        .content-p2-1 {
            text-align: center;
            width: 65rem;
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #974629;
            line-height: 3.5rem;
        }

        .content-p2 {
            text-align: center;
            width: 40rem;
            font-size: 2.2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #974629;
            line-height: 4rem;
        }
    }
}

@media screen and (max-width:1375px) {
    .act {
        margin-left: 10rem;
    }

    .box {
        width: 100%;
        // max-width: 170rem;
        position: relative;

        img {
            width: 100%;
            // max-width: 170rem;
        }

        .box-content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 95rem;
            height: 40rem;
            padding-top: 5%;
            padding-left: 10%;

            .content-div {
                width: 40rem;
                margin-bottom: 1.5rem;
            }

            .content-p1 {
                text-align: center;
                width: 40rem;
                font-size: 5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #974629;
                line-height: 6rem;
                margin-bottom: 1.5rem;

                span {
                    font-size: 7rem;
                }
            }

            .content-p1-1 {
                // border:solid;
                margin-top: 2rem;
                // margin-left:0rem;
                text-align: center;
                width: 50rem;
                font-size: 3rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #974629;
                line-height: 3.5rem;
                margin-bottom: 1.5rem;
            }

            .content-p2-1 {
                text-align: center;
                width: 50rem;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #974629;
                line-height: 3.2rem;
            }

            .content-p2 {
                text-align: center;
                width: 40rem;
                font-size: 2.2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #974629;
                line-height: 4rem;
            }
        }
    }
}

@media screen and (max-width:1160px) {
    .box {
        width: 116rem;
        // max-width: 170rem;
        position: relative;

        img {
            width: 100%;
            // max-width: 170rem;
        }

        .box-content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 90rem;
            height: 40rem;
            padding-top: 5%;
            padding-left: 10%;

            .content-div {
                width: 40rem;
                margin-bottom: 1.5rem;
            }

            .content-p1 {
                text-align: center;
                width: 40rem;
                font-size: 5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #974629;
                line-height: 6rem;
                margin-bottom: 1.5rem;

                span {
                    font-size: 7rem;
                }
            }

            .content-p1-1 {
                // border:solid;
                margin-top: 2rem;
                // margin-left:0rem;
                text-align: center;
                width: 47rem;
                font-size: 3rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #974629;
                line-height: 3.5rem;
                margin-bottom: 1.5rem;
            }

            .content-p2-1 {
                text-align: center;
                width: 50rem;
                font-size: 1.8rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #974629;
                line-height: 3.2rem;
            }

            .content-p2 {
                text-align: center;
                width: 40rem;
                font-size: 2.2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #974629;
                line-height: 4rem;
            }
        }
    }
}
</style>