module.exports = {
    language: {
        name: 'zh',

    },
    // header and footer
    home: {
        tangku:'唐库',
        header1: '首页',
        header2: '方案场景',
        header3: '产品中心',
        header4: '帮助中心',
        header5: '关于我们',
        language: '简体中文',
        footer1: '联系方式',
        footer2: '地址 : 北京市丰台区南四环西路186号汉威广场一区206室',
        footer3: '邮箱 : market@xingdata.com',
        footer4: '电话 : 400-800-9406',
        footer5: '关于我们',
        footer6: '微信搜索"唐库新零售，或直接扫二维码关注喔~"',
        option1: '餐饮行业',
        option2: '快餐 小吃 咖啡 正餐 酒吧 多种行业',
        option3: '零售便利',
        option4: '便利店 大型商超 服装店',
        option5: '水果生鲜',
        option6: '水果 生鲜 等称重行业',
        product1: '唐库收银',
        product2: '适用于零售 餐饮 自助服务 多种类型软件',
        product3: '唐库厨房',
        product4: '专业的后厨人员智能系统',
        product5: '唐库辅助工具',
        product6: '商品价签设置，批量打印价签，电子秤添加修改',
        product7: '唐库点餐',
        product8: '小巧方便的手持设备，收银员点餐好助手',
        product9: '唐库盘点器',
        product10: '彻底解决门店盘点难题，支持多人盘点',
        product11: '唐库商家助手',
        product12: '移动商家助手，方便快捷管理门店',
    },
    // 首页
    index: {
        swiper1:' 一 个 登 录 账 号 ',
        swiper2:' 不 同 终 端 数 据 共 通 ',
        swiper3:' 收银机 自助机 盘点系统 ',
        swiper4:' WEB 手机助手 点餐宝',
        swiper5:' 餐饮零售 ',
        swiper6:' 多种场景支持 ',
        swiper7:' 适合餐饮零售不同类型门店 ',
        swiper8:' 多种营销模式配合使用 ',
        swiper9:' 双屏客显 ',
        swiper10:' 视屏广告 一触即发 ',
        swiper11:' 顾客付款时消费信息一目了然 ',
        swiper12:' 交互体验更友好 ',
        goods1: '产品功能',
        goods2: '区别于传统收银 ，唐库做了全方位的系统统升级，',
        goods3: '体验升级、商户说好，消费者也说好。',
        md: '门店管理',
        md1: '店内经营、业务报表、营销策略、会员信息、店面视频监控等、手机、PC随时随地掌握全局',
        hy: '会员营销',
        hy1: '玩转会员营销，您可以设置会员积分制度，充值赠送、满额立减和返现活动，实实在在回流更多资金。',
        zf: '聚合支付',
        zf1: '依托智能终端设备，为商户的日常经营活动提供强大的基础收银、聚合支付等功能。',
        zz: '增值服务',
        zz1: '会员管理、营销,模式多样化、精准化、人性化，服务、营销信息瞬间抵达目标会员，沟通互动上通下达无障碍。',
        tj: '统计分析',
        tj1: '支持手机、PC查看报表，发现营业数据背后的价值。关注“唐库”，即刻体验!',
        cj: '适用场景',
        cj1: '多角度支持各大场景，多种方案智能营销。',
        hz: '合作伙伴',
        hz1: '唐库收银将与众多合作伙伴携手，为您提供更专业的系统服务',
    },
    // 餐饮行业
    repast: {
        content1:'智能后厨系统',
        content2:'不同菜品展示模式 杜绝漏单',
        content3:'扫码点餐，节省人力',
        content4:'顾客通过微信自助点餐，更方便快捷',
        cy: '适合多种餐饮门店',
        cy1: '快餐、正餐、咖啡蛋糕奶茶',
        cy2: '多行业适用',
        sy: '收银机/桌台/小程序点餐',
        sy1: '支持先付款或后付款，堂食，外带',
        dc: '收银机点餐：',
        dc1: '适合快餐、咖啡店、酒吧 ，直接点餐-结账-传到后厨-等待叫号-取餐',
        dc2: '桌台/小程序点餐：',
        dc3: '适合正餐，店员使用点餐宝点餐，数据同步收银机，后厨、收银机后结账',
        hc: '后厨接单',
        hc1: '收银机下单，后厨接单，小票打印，订单完成后，大屏幕叫号',
        yj: '硬件配套方案',
        yj1: '收银机、自助机、扫码枪、扫码盒子、钱箱、厨房平板设备、叫号设备',
        yj2: '不同门店支持不同配置',
        zz: '自助点餐设备',
        syj: '收银机',
        sc: '手持设备',
        azk: '安卓平板（KDS）',
        aza: '安卓平板（ADS）',
        tv: '电视（TV）',
        rj: '软件应用',
        rj1: '唐库收银、唐库辅助工具、唐库盘点系统',
        tksy: '唐库收银',
        tksy1: '唐库智慧门店，线下收银，适配各种收银机',
        tkcf: '唐库厨房',
        tkcf1: '为后厨人员开发的智能系统',
        tkdc: '唐库点餐',
        tkdc1: '小巧方便的手持点餐设备',

    },
    // 唐库收银
    cash: {
        content1:'收银系统',
        content2:'方便 智能 省事省力',
        content3:'零售/餐饮/自助服务',
        content4:'软件下载',
        ls: '零售餐饮、多种行业支持',
        ls1: '生鲜水果、便利店、大型商超、服装店、快餐、正餐、咖啡蛋糕奶茶',
        ls2: '多行业适用',
        rjys: '软件优势',
        rjys1: '多种营销、聚合支付、多种不同页面适配、报表多样化',
        rjys2: '反应速度灵敏',
        xs: '销售数据云备份，支持离线收银',
        xs1: '适用于多行业实体店使用，满足多种不同的收银需求，',
        xs2: '正餐收银、快餐收银、扫码收银、快速收银',
        xs3: '支持丰富的玩法，打折、抹零、优惠、改价，排队预订、核销优惠券等等',
        cz: '适用商家 业务场景',
        cz1: '夫妻店、小型连锁门店、中型连锁门店、大型连锁门店',
        cz2: '不同解决方案',
        md: '小型门店',
        md1: '夫妻店、单店',
        md2: '一部收银机解决问题',
        md3: ' 中大型门店',
        md4: '解决基础门店收银，进销存 多种报表支持',
        md5: '大型连锁门店',
        md6: '多店调货、盘点 多种营销方案支持',
        ry: '软硬件配套齐全',
        ry1: '收银机、自助机、一体秤、电子秤、扫码枪、扫码盒子、钱箱',
        ry2: '不同门店支持不同配置',
    },
    // 水果生鲜
    fruits: {
        swiper1:'一体',
        swiper2:'称',
        swiper3:'重新定义电子秤',
        swiper4:'集合称重、收银、打印为一体，功能强大',
        swiper5:'直接触屏即可完成操作，简单方便',
        md: '适用多种生鲜门店',
        md1: '蔬菜店、水果店、海鲜店、熟食店、肉联',
        md2: '多行业适用',
        hx: '生鲜系统核心功能',
        hx1: '订单管理、采购管理、进销存管理、报表多样化',
        hx2: '定向报价',
        dd: '订单管理',
        dd1: '待发货、已完成、售后订单管理',
        cg: '采购管理',
        cg1: '批量录入采购单，审核之后更改库存',
        jcx: '进销存管理',
        jcx1: '出库、入库、盘点、动态库存，数字化管理',
        dx: '定向报价',
        dx1: '不同客户不同价格，支持改价，灵活管理商品',
        zn: '智能盘点',
        zn1: '多人分组盘点，审核之后改库存库存更精准',
        jy: '经营分析',
        jy1: '毛利、销售数据、各种报表满足不同需求',
        sc: '线上商城',
        sc1: '小程序线上商城，支持配送、自提等不同购物方式',
        goods: '商品搜索',
        goods1: '快速查找商品，品类再多也能精准定位',
        kc: '精准库存',
        kc1: '手动添加、采购单进货、库存盘点，审核入库，报损报溢',
        kc2: '保证库存精准无误',
        content1: '报损报溢',
        content2: '入库管理',
        content3: '报损记录',
        content4: '商品档案',
        content5: '库存管理',
        content6: '出库管理',
        content7: '交易记录',
        content8: '库存盘点',
        content9: '云后台',
        cx: '打包促销 灵活改价',
        cx1: '支持相同商品不同客户不同价格、支持打包处理',
        cx2: '支持按固定价、或按比例修改价格',
        yj: '硬件配套方案',
        yj1: '收银机、一体秤、电子秤、扫码枪、扫码盒子、钱箱、',
        yj2: '不同门店支持不同配置',
        ytc: '一体秤',
        syj: '收银机',
        dzc: '电子秤',
        rjyy: '软件应用',
        rjyy1: '唐库收银、唐库辅助工具、唐库盘点系统',
        tksy: '唐库收银',
        tksy1: '唐库智慧门店，线下收银，适配各种收银机',
        tkfz: '唐库辅助工具',
        tkfz1: '商品价签设置，批量打印商品价签',
        tkpdq: '唐库盘点器',
        tkpdq1: '彻底解决门店盘点难题，支持多人盘点',
    },
    // 零售便利
    retail: {
        swiper1:' 智能零售系统',
        swiper2:' 生意红火好助手',
        swiper3:' 多维度数据分析',
        swiper4:' 全方位多维度大数据分析 实时监控店铺运营状态',
        md: '适用多种零售门店',
        md1: '夫妻店、便利店、连锁门店、大型商超',
        md2: '多行业适用',
        gn: '多种功能满足需求',
        gn1: '多种营销、聚合支付、多种不同页面适配、报表多样化',
        gn2: '反应速度灵敏',
        zd: '全渠道多终端覆盖',
        zd1: '微商城多渠道获取客户，各种营销吸引客户',
        content1: '助手小程序',
        content2: '收银系统',
        content3: '后台',
        content4: '价签',
        content5: '盘点工具',
        content6: '微商城',
        cg: '采购进货 数据报表',
        cg1: '简单易用，轻松录入订单，进货、销售。库存。预警提醒、报表统计',
        yj: '硬件配套方案',
        yj1: '收银机、扫码枪、扫码盒子、钱箱、盘点手持设备',
        yj2: '同门店支持不同配置',
        syj: '收银机',
        pdq: '盘点设备',
        rjyy: '软件应用',
        rjyy1: '唐库收银、唐库辅助工具、唐库盘点系统',
        tksy: '唐库收银',
        tksy1: '唐库智慧门店，线下收银，适配各种收银机',
        tkfz: '唐库辅助工具',
        tkfz1: '商品价签设置，批量打印商品价签',
        tkpdq: '唐库盘点器',
        tkpdq1: '彻底解决门店盘点难题，支持多人盘点',
        image1:'智能收银',
        image2:'多种支付方式',
        image3:'智能盘点',
        image4:'多人分组盘点',
        image5:'采购助手',
        image6:'智能采购单',
        image7:'线上商城',
        image8:'小程序线上购物',
        image9:'连锁供应',
        image10:'多门店连锁供应',
        ck:'出库管理',
        rk:'入库管理',
        mdtb:'门店调拨',
        cgjh:'采购进货',
        kcpd:'库存盘点',
        lsmd:'连锁门店一',
        goods:'商品上下架',
        lsmd1:'连锁门店',
        data:'线上线下数据总汇',
        lsmd2:'连锁门店二',
        kcyj:'库存预警',
        dgoods:'多规格商品',
        pgoods:'偏好商品',
    },
    // 唐库点餐
    order: {
        swiper1:'点餐宝',
        swiper2:'手机点餐更快捷',
        swiper3:'一部手机',
        swiper4:'就可完成点餐，结账',
        swiper5:'软件下载',
        zt: '桌台使用情况一目了然',
        zt1: '桌台不同状态使用不用颜色区分',
        zt2: '空闲 占用 预约',
        ztzt: '不同状态桌台颜色区分',
        ztzt1: '空闲 ',
        ztzt2: '占用 ',
        ztzt3: ' 预留',
        dc: '快速点餐 节约时间',
        dc1: '商品分组清晰，快速查找商品，点餐更方便',
        data: '收银机接单，数据同步',
        data1: '在收银机上同步处理订单无需手动处理，下单后自动打印到厨房，还支持一张订单分厨房分订单打印',
        data2: '支持开台、下单、加菜、退菜等功能，大大提升服务员效率，可管理台位数量',
        content1: '让点餐更加简捷',
        content2: '多场景全流程支持，满足不同场景的业务需要及个性化定制，让点餐',
        content3: '很简单；点餐宝应用场景；',
        content4: '1.堂食点餐，代客点餐，收银台； 2.预定点餐（食堂、打包）',
    },
    // 唐库厨房
    kitchen: {
        content1:'厨房系统',
        content2:'分厨打印，高效',
        content3:'智能控菜，杜绝漏单',
        content4:'软件下载',
        cp: '多种菜品显示模式',
        cp1: '可选择多种不同方式菜品显示：按订单展示、按菜品展示和按房间展示',
        cp2: '将同样的菜按照时间顺序自动汇总， 厨师只需按照显示的数量制作，告别重复劳动，效率大大提升。',
        cp3: '点击改变菜品进度，提高工作效率',
        cp4: '出菜超过预设时间，系统自动催菜，厨师单击改变菜品的制作进度，',
        cp5: '厨房系统（KDS）,配菜系统（ADS）数据同步显示',
        dp: '大屏幕叫号',
        dp1: '桌位管理 - 电子厨显 - 叫号系统 - 叫号系统 - 智能控菜 - 餐品智能分类',
        dp2: '订单智能动态 - 桌位一键呼叫 - 餐品分时库存',
    },
    // 唐库盘点器
    stock: {
        swiper1:'盘点系统',
        swiper2:'扫码盘点省时省力',
        swiper3:'盘亏盘盈 一目了然',
        swiper4:'更好掌握库存动态',
        swiper5:'软件下载',
        pd: '多人盘点，团队协作更速度',
        pd1: '创建盘点单，多人加入盘点，盘亏盘盈纠正，一键提交，核实后修改库存',
        smpd: '扫码盘点，精确、迅速',
        smpd1: '扫码品条码，输入或加减商品数量，一次可盘多件商品',
        data: '数据精确核实，拒绝出错',
        data1: '盘点数据多次核对，拒绝出错，核实审核，修改库存',
        content1: '多人同时盘点',
        content2: '按商品品类盘点',
        content3: '可分多天进行盘点',
        content4: '未盘点商品不影响销售',
        lp: '盘点漏盘商品',
        lp1: '在未盘中可重新盘点',
        lp2: '漏盘商品',
        jz: '校正商品数量',
        jz1: '提交盘点结果前可更改',
        jz2: '盘点数量，拒绝出错',
        data2: '数据同步',
        data3: '盘点数据同步后台',
        data4: '审核后，库存更改',
        dd: '多端操作',
        dd1: '支持手机、移动终端、',
        dd2: '收银机',
    },
    // 唐库辅助工具
    utility: {
        content1:'价签',
        content2:'电子秤系统',
        content3:'批量打印价签更快捷',
        content4:'软件下载',
        jq: '自由设计价签模板',
        jq1: '随意设计价签尺寸，样式模板，可适配多种不同价签格式',
        goodsjq: '批量打印商品价签',
        goodsjq1: '一次打印多件商品价签，不用一个一个打印，节省人力',
        goodsjq2: '可以按着分组，品牌、供应商、搜索商品，勾选商品，点击打印价签进行打印',
        dzc: '设置不同类型电子秤',
        dzc1: '可添加多种类型电子秤',
    },
    // 唐库商家助手
    assistant: {
        swiper4:'随时随地查看销售数据 随时随地查看销售数据 小软件大作用 随时随地查看销售数据 小巧方便 店铺经营清晰明了 会员管理 商品管理 销售订单随时掌握 待发货 直接处理 不在门店也能完成工作 门店数据管理 助手程序 手机管点，小巧方便 随时随地查看销售数据 不在门店也能完成工作 ',
        swiper1:'助手程序',
        swiper2:'小软件大作用',
        swiper3:'手机管店，小巧方便',
        swiper4:'微信搜索唐库商家助手',
        xs: '随时随地查看销售数据',
        xs1: '随时掌握销售情况，店铺经营清晰明了',
        dd: '销售订单随时掌握',
        dd1: '待发货 待收货 售后订单 直接处理 不在门店也能完成工作',
        md: '门店数据管理',
        md1: '商品管理 会员营销管理 门店管理',
        content1: '商品改价',
        content2: '商品管理',
        content3: '库存管理',
        content4: '采购进货',
        content5: '会员管理',
        content6: '会员规则',
        content7: '优惠券',
        content8: '促销活动',
    },
    // 关于我们
    aboutUs: {
        xing: '北京浙星信息技术有限公司',
        content1: '成立于2013年，位于北京中关村科技园区，是一家主要从事商家数字化云平台系统研',
        content2: '发、销售和运营的高新技术企业',
        content3: '核心团队来自于从事金融行业的、零售行业的以及互联网的资深从业者组成。产品研',
        content4: '发团队完善，有安卓智能终端应用开发组、后台JAVA、C开发组、WEB前端组、产品',
        content5: '架构与UI组、网络数据库运维组。',
        content6: '公司拥有自主研发产权的系统产品，拥有25项软件著作权。',
        content7: '唐库新零售云平台是公司已经上线并运营产品，集门店MIS收银、连锁店进销存ERP、',
        content8: '智能营销、会员CRM、聚合支付PAY、微信公众号/小程序、电子发票于一体的云平台',
        content9: 'SAAS系统。目前服务于国内外商超、便利连锁、小吃、食堂快餐、咖啡茶馆、烟酒',
        content10: '店、药店等行业业态。',
        content11: '公司将进一步致力于为商户提供数字化转型的整体解决方案( ALL IN ONE)、数据',
        content12: '分析与挖掘、零售人工智能研发与运营的探索。',
    },
}