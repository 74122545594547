import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/base.css'
//中英文切换
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入element-ui中的language
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const zh = require('./components/language/zh'); //require('./components/language/zh')
const en = require('./components/language/en'); //require('./components/language/en')
const messages = {
  'zh':{
    ...zh,
    //引入element-ui中的国际化语法 
    ...zhLocale
  },
  'en':{
    ...en,
    //引入element-ui中的国际化语法 引入element-ui中的国际化语法 en = require ('./components/language/en);
    ...enLocale
  }
}
Vue.use(ElementUI);
Vue.use(VueI18n);
Vue.config.productionTip = false
const i18n = new VueI18n({
  locale:localStorage.getItem('language'),
  messages,
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
