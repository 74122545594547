<template>
    <div class="box">
        <div :class="$i18n.locale=='en'?'content1':'content'">
            <h3 :class="$i18n.locale=='en'?'active1':'active'" style="font-weight:700;margin-bottom:2rem">
                <!-- 北京浙星信息技术有限公司 -->
                {{ $t('aboutUs.xing') }}
            </h3>
            <p class="content-p">
                <!-- 成立于2013年,位于北京中关村科技园区,是一家主要从事商家数字化云平台系统研 -->
                {{ $t('aboutUs.content1') }}
            </p>
            <p class="content-p">
                <!-- 发、销售和运营的高新技术企业 -->
                {{ $t('aboutUs.content2') }}
            </p>
            <p class="content-p">
                <!-- 核心团队来自于从事金融行业的、零售行业的以及互联网的资深从业者组成。产品研 -->
                {{ $t('aboutUs.content3') }}
            </p>
            <p class="content-p">
                <!-- 发团队完善,有安卓智能终端应用开发组、后台JAVA、 C开发组、WEB前端组、 产品 -->
                {{ $t('aboutUs.content4') }}
            </p>
            <p class="content-p">
                <!-- 架构与UI组、网络数据库运维组。 -->
                {{ $t('aboutUs.content5') }}
            </p>
            <p class="content-p">
                <!-- 公司拥有自主研发产权的系统产品,拥有25项软件著作权。 -->
                {{ $t('aboutUs.content6') }}
            </p>
            <p class="content-p">
                <!-- 唐库新零售云平台是公司已经上线并运营产品,集门店MIS收银、 连锁店进销存ERP、 -->
                {{ $t('aboutUs.content7') }}
            </p>
            <p class="content-p">
                <!-- 智能营销、会员CRM、聚合支付PAY、微信公众号/小程序、电子发票于一体的云平台 -->
                {{ $t('aboutUs.content8') }}
            </p>
            <p class="content-p">
                <!-- SAAS系统。目前服务于国内外商超、便利连锁、小吃、食堂快餐、咖啡茶馆、 烟酒 -->
                {{ $t('aboutUs.content9') }}
            </p>
            <p class="content-p">
                <!-- 店、药店等行业业态。 -->
                {{ $t('aboutUs.content10') }}
            </p>
            <p class="content-p">
                <!-- 公司将进一 步致力于为商户提供数字化转型的整体解决方案( ALL IN ONE)、数据  -->
                {{ $t('aboutUs.content11') }}
            </p>
            <p class="content-p">
                <!-- 分析与挖掘、零售人工智能研发与运营的探索。 -->
                {{ $t('aboutUs.content12') }}
            </p>
        </div>
        <div :class="$i18n.locale=='en'?'image1':'image'">
            <img src="../assets/css/img/3-1.png" alt="" width="100%">
        </div>
    </div>
</template>
<script>
export default {
    name: 'aboutUs',
    data() {
        return {

        }
    },
    mounted(){
        document.documentElement.scrollTop=0;
    }
}

</script>
<style lang="less" scoped>
.active{
    font-size:3.5rem;
}
.active1{
    font-size:2.4rem;
}
.box {
    width: 120rem;
    margin: 0 auto;
    display: flex;
    padding-top: 1.5rem;

    .content {
        width: 75rem;
        height: 50rem;
        margin-right: 0rem;
        padding-left: 10rem;
        padding-right:2rem;
        .content-p{
            font-size:1.6rem;
            line-height:3rem;
            // text-indent:2em;
        }
    }
    .content1 {
        width: 75rem;
        height: 50rem;
        margin-right: 0rem;
        padding-left: 10rem;
        padding-right:2rem;
        .content-p{
            width:58rem;
            font-size:1.5rem;
            line-height:2.2rem;
            text-indent:2em;
        }
    }
    .image {
        width: 40rem;
        padding-top:6rem;
    }
    .image1 {
        width: 40rem;
        padding-top:6rem;
    }
}

@media screen and (max-width:1200px) {
    .box {
        width: 100%;

        .content {
            width: 67rem;
            height: 50rem;
            margin-right: 4rem;
            padding-left: 2rem;
        }
    }
}

@media screen and (max-width:1118px) {
    .box {
        width: 1118px;
        overflow: hidden;
    }
}
</style>