<template>
    <div class="section">
        <div class="box">
            <img src="../assets/css/img/2-1-1.png" style="width:100%;">
            <div :class="$i18n.locale == 'en' ? 'box-content-1' : 'box-content'">
                <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                    <!-- 收银系统 -->
                    {{ $t('cash.content1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                    <!-- 方便 智能 省事省力 -->
                    {{ $t('cash.content2') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-p2-1' : 'content-p2'">
                    <!-- 零售/餐饮/自助服务 -->
                    {{ $t('cash.content3') }}
                    <span></span>
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-sub-1' : 'content-sub'">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                        height="25px" style="vertical-align: middle;">
                        <path fill-rule="evenodd" fill="rgb(242, 243, 244)"
                            d="M22.005,10.070 L15.714,10.070 L15.714,0.640 L6.280,0.640 L6.280,10.070 L-0.010,10.070 L10.997,21.071 L22.002,10.070 L22.005,10.070 ZM-0.010,24.214 L-0.010,27.358 L22.005,27.358 L22.005,24.214 L-0.010,24.214 Z" />
                    </svg>
                    <span>
                        <!-- 软件下载 -->
                        {{ $t('cash.content4') }}
                    </span>
                </p>
            </div>
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 零售餐饮、多种行业支持 -->
                {{ $t('cash.ls') }}
            </p>
            <p class="content-p2" style="margin-bottom: 10px">
                <!-- 生鲜水果、便利店、大型商超、服装店、快餐、正餐、咖啡蛋糕奶茶 -->
                {{ $t('cash.ls1') }}
            </p>
            <p class="content-p2">
                <!-- 多行业适用 -->
                {{ $t('cash.ls2') }}
            </p>
        </div>
        <div class="content-image" style="text-align: center;">
            <img src="../assets/css/img/2-1-2.png" width="100%" />
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 软件优势 -->
                {{ $t('cash.rjys') }}
            </p>
            <p class="content-p2" style="margin-bottom: 10px">
                <!-- 多种营销、聚合支付、多种不同页面适配、报表多样化 -->
                {{ $t('cash.rjys1') }}
            </p>
            <p class="content-p2">
                <!-- 反应速度灵敏 -->
                {{ $t('cash.rjys2') }}
            </p>
        </div>
        <div class="content2">
            <div class="content">
                <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                    <!-- 销售数据云备份，支持离线收银 -->
                    {{ $t('cash.xs') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-p2-1' : 'content-p2'">
                    <!-- 适用于多行业实体店使用，满足多种不同的收银需求，<br />
                    正餐收银、快餐收银、扫码收银、快速收银<br />
                    支持丰富的玩法，打折、抹零、优惠、改价，排队预订、核销优惠券等等 -->
                    {{ $t('cash.xs1') }}<br />
                    {{ $t('cash.xs2') }}<br />
                    {{ $t('cash.xs3') }}
                </p>
            </div>
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 适用商家 业务场景 -->
                {{ $t('cash.cz') }}
            </p>
            <p class="content-p2" style="margin-bottom: 10px">
                <!-- 夫妻店、小型连锁门店、中型连锁门店、大型连锁门店 -->
                {{ $t('cash.cz1') }}
            </p>
            <p class="content-p2">
                <!-- 不同解决方案 -->
                {{ $t('cash.cz2') }}
            </p>
        </div>
        <div class="content3">
            <div class="image1">
                <p class="title">
                    <!-- 小型门店 -->
                    {{ $t('cash.md') }}
                </p>
                <p class="content-P">
                    <!-- 夫妻店、单店 -->
                    {{ $t('cash.md1') }}
                    <br />
                    <!-- 一部收银机解决问题 -->
                    {{ $t('cash.md2') }}
                </p>
            </div>
            <div class="image1">
                <p class="title">
                    <!-- 中大型门店 -->
                    {{ $t('cash.md3') }}
                </p>
                <p class="content-P">
                    <!-- 解决基础门店收银，进销存 多种报表支持 -->
                    {{ $t('cash.md4') }}
                </p>
            </div>
            <div class="image1">

                <p class="title">
                    <!-- 大型连锁门店 -->
                    {{ $t('cash.md5') }}
                </p>

                <p class="content-P">
                    <!-- 多店调货、盘点 多种营销方案支持 -->
                    {{ $t('cash.md6') }}
                </p>
            </div>
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 软硬件配套齐全 -->
                {{ $t('cash.ry') }}
            </p>
            <p class="content-p2" style="margin-bottom: 10px">
                <!-- 收银机、自助机、一体秤、电子秤、扫码枪、扫码盒子、钱箱 -->
                {{ $t('cash.ry1') }}
            </p>
            <p class="content-p2">
                <!-- 不同门店支持不同配置 -->
                {{ $t('cash.ry2') }}
            </p>
        </div>
        <div class="image-1">
            <img src="../assets/css/img/2-1-4.png" alt="" style="width:100%">
        </div>
    </div>
</template>
<script>
export default {
    name: 'cash',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang="less" scoped>
.section {
    width: 100%;
    height: 100%;
    // border:solid;
    overflow: hidden;
}

.image-1 {
    width: 100vw;
    // height: 450px;
    // background: url('../assets/css/img/2-1-4.png') no-repeat;
    // background-size: 100vw;
}

.content3 {
    width: 1200px;
    height: 470px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

    div {
        width: 280px;
        height: 460px;
        border-radius: 30px;
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 30px;
    }

    .image1 {
        background: url('../assets/css/img/2-1-2-2.png') no-repeat;
        background-size: cover;

        .content-P {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #121212;
            line-height: 40px;
        }
    }

    .title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        line-height: 33px;
        margin-bottom: 30px;
    }

    .image1:hover {
        transition: all .5s;
        background: url('../assets/css/img/2-1-2-1.png') no-repeat;
        background-size: cover;

        .title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
            margin-bottom: 30px;
        }

        .content-P {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
        }
    }
}



.content2 {
    width: 100vw;
    height: 380px;
    background: url("../assets/css/img/2-1-3.png") no-repeat;
    background-size: 100vw;
    padding-top: 7rem;

    .content {
        width: 50rem;
        height: 21rem;
        margin-left: 10rem;

        .content-p1 {
            font-size: 3rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #f2f7ff;
            margin-bottom: 3.5rem;
        }

        .content-p1-1 {
            width: 70rem;
            font-size: 2.5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #f2f7ff;
            margin-bottom: 3.5rem;
        }

        .content-p2-1 {
            font-size: 1.5rem;
            width: 70rem;
            font-weight: 400;
            line-height: 6rem;
            color: #ffffff;
        }

        .content-p2 {
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 3.5rem;
            color: #ffffff;
        }
    }
}

.content-image {
    width: 120rem;
    // height: 30rem;
    // border:solid 1px;
    margin: 0 auto;
    // background: url('../assets/css/img/2-1-2.png') no-repeat;
    // background-size: cover;
}

// 内容样式
.content1 {
    width: 100rem;
    // height: 25rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 3rem;

    .content-p1 {
        font-size: 3.6rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        margin-bottom: 1rem;
    }

    .content-p2 {
        font-size: 2.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
    }
}

.box {
    width: 100%;
    // height: 43rem;
    border-top: solid 1px #ffffff;
    margin: 0 auto;
    // background: url('../assets/css/img/2-1-1.png') no-repeat;
    // background-size: 100%;
    overflow: hidden;
    position: relative;

    .box-content-1 {
        position: absolute;
        left: 35rem;
        top: 14rem;
        width: 35rem;

        .content-p1-1 {
            width: 45rem;
            text-align: center;
            font-size: 3.5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #6a3906;
            line-height: 4.5rem;
        }

        .content-p2-1 {
            width: 45rem;
            position: relative;
            font-size: 2.4rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #121212;
            line-height: 7.3rem;
            text-align: center;
        }

        .content-sub-1 {
            margin: 0 auto;
            margin-top: 5rem;
            margin-left: 10rem;
            width: 25rem;
            height: 5rem;
            background-color: #4591fe;
            border-radius: 1rem;
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 5rem;
            padding-left: 2rem;

            span {
                margin-left: 0.3rem;
            }
        }
    }

    .box-content {
        position: absolute;
        left: 35rem;
        top: 14rem;
        width: 35rem;

        .content-p1 {
            text-align: center;
            font-size: 4rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #6a3906;
            line-height: 6rem;
        }



        .content-p2 {
            // margin-bottom: 60px;
            position: relative;
            font-size: 2.4rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #121212;
            line-height: 7.3rem;
            text-align: center;
        }

        .content-sub {
            margin: 0 auto;
            margin-top: 5rem;
            width: 15rem;
            height: 5rem;
            background-color: #4591fe;
            border-radius: 1rem;
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 5rem;
            padding-left: 2rem;

            span {
                margin-left: 0.3rem;
            }
        }
    }
}

@media screen and (max-width:1450px) {
    .box {
        width: 100%;
        border-top: solid 1px #ffffff;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        .box-content-1 {
            position: absolute;
            left: 20%;
            top: 15%;
            width: 35rem;

            .content-p1-1 {
                width: 45rem;
                text-align: center;
                font-size: 3.5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #6a3906;
                line-height: 4.5rem;
            }

            .content-p2-1 {
                width: 45rem;
                position: relative;
                font-size: 2.4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #121212;
                line-height: 7.3rem;
                text-align: center;
            }

            .content-sub-1 {
                margin: 0 auto;
                margin-top: 5rem;
                margin-left: 10rem;
                width: 25rem;
                height: 5rem;
                background-color: #4591fe;
                border-radius: 1rem;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 5rem;
                padding-left: 2rem;

                span {
                    margin-left: 0.3rem;
                }
            }
        }

        .box-content {
            position: absolute;
            left: 35rem;
            top: 14rem;
            width: 35rem;

            .content-p1 {
                text-align: center;
                font-size: 4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #6a3906;
                line-height: 6rem;
            }



            .content-p2 {
                // margin-bottom: 60px;
                position: relative;
                font-size: 2.4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #121212;
                line-height: 7.3rem;
                text-align: center;
            }

            .content-sub {
                margin: 0 auto;
                margin-top: 5rem;
                width: 15rem;
                height: 5rem;
                background-color: #4591fe;
                border-radius: 1rem;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 5rem;
                padding-left: 2rem;

                span {
                    margin-left: 0.3rem;
                }
            }
        }
    }
}

@media screen and (max-width:1300px) {
    .content-image {
        width: 100%;
        margin: 0 auto;
        // background: url('../assets/css/img/2-1-2.png') no-repeat;
        // background-size: 120rem ;
    }

    .content2 {
        width: 100vw;
        height: 39vh;
        background: url("../assets/css/img/2-1-3.png") no-repeat;
        background-size: 100vw;
        padding-top: 3rem;

        .content {
            width: 50rem;
            height: 21rem;
            margin-left: 5rem;

            .content-p1 {
                font-size: 3rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f2f7ff;
                margin-bottom: 3.5rem;
            }

            .content-p2 {
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 3.5rem;
                color: #ffffff;
            }

            .content-p1-1 {
                margin-left: -2rem;
                width: 70rem;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f2f7ff;
                margin-bottom: 3.5rem;
            }

            .content-p2-1 {
                margin-left: -2rem;
                font-size: 1.2rem;
                width: 55rem;
                font-weight: 400;
                line-height: 3.6rem;
                color: #ffffff;
            }
        }
    }

    .section {
        overflow: hidden;
    }

    .content3 {
        width: 100%;
        height: 470px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        div {
            width: 23%;
            height: 460px;
            border-radius: 30px;
            padding-top: 40px;
            padding-left: 3%;
            padding-right: 3%;
        }

        .image2 {
            background: linear-gradient(0deg,
                    #f4f7fd,
                    #ebf2ff,
                    #ebf2ff,
                    #f4f7ff,
                    #ebf2ff);
        }

        .image1 {
            background: url('../assets/css/img/2-1-2-2.png') no-repeat;
            background-size: cover;
        }

        .image3 {
            background: url('../assets/css/img/2-1-2-1.png') no-repeat;
            background-size: cover;
        }

        .title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #121212;
            line-height: 33px;
            margin-bottom: 30px;
        }

        .content-P {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #121212;
            line-height: 40px;
        }

        .title1 {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
            margin-bottom: 30px;
        }

        .content-P1 {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
        }
    }

    .box {
        width: 100%;
        border-top: solid 1px #ffffff;
        margin: 0 auto;
        overflow: hidden;
        position: relative;

        .box-content-1 {
            position: absolute;
            left: 15rem;
            top: 6rem;
            width: 35rem;
        }

        .box-content {
            position: absolute;
            left: 30rem;
            top: 12rem;
            width: 35rem;

            .content-p1 {
                text-align: center;
                font-size: 4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #6a3906;
                line-height: 6rem;
            }

            .content-p2 {
                // margin-bottom: 60px;
                position: relative;
                font-size: 2.4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #121212;
                line-height: 7.3rem;
                text-align: center;
            }

            .content-sub {
                margin: 0 auto;
                margin-top: 5rem;
                width: 15rem;
                height: 5rem;
                background-color: #4591fe;
                // background-image: url("../assets/css/img/download.png");
                // background-repeat: no-repeat;
                // background-size: 12%;
                // background-position: 1.5rem;
                border-radius: 1rem;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 5rem;
                padding-left: 2rem;

                span {
                    margin-left: 0.3rem;
                }
            }
        }
    }
}

@media screen and (max-width:673px) {
    // .content1 {
    //     width: 673px;
    //     overflow: hidden;
    // }

    .content3 {
        width: 670px;
        height: 470px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        div {
            width: 23%;
            height: 460px;
            border-radius: 30px;
            padding-top: 40px;
            padding-left: 3%;
            padding-right: 3%;
        }
    }
}

@media screen and (max-width:1154px) {
    .content2 {
        width: 100vw;
        height: 40rem;
        background: url("../assets/css/img/2-1-3.png") no-repeat;
        background-size: 100vw;
        padding-top: 3rem;

        .content {
            width: 50rem;
            height: 21rem;
            margin-left: 5rem;

            .content-p1 {
                font-size: 1.5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f2f7ff;
                margin-bottom: 3rem;
            }

            .content-p2 {
                font-size: 1rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 3rem;
                color: #ffffff;
            }
        }
    }
}

@media screen and (max-width:950px) {
    .content2 {
        width: 100vw;
        height: 40rem;
        background: url("../assets/css/img/2-1-3.png") no-repeat;
        background-size: 100vw;
        padding-top: 3rem;

        .content {
            width: 50rem;
            height: 21rem;
            margin-left: 10rem;

            .content-p1 {
                font-size: 1.5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f2f7ff;
                margin-bottom: 3rem;
            }

            .content-p2 {
                font-size: 1rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 3rem;
                color: #ffffff;
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .content-image {
        width: 118rem;

        margin: 0 auto;
        // background: url('../assets/css/img/2-1-2.png') no-repeat;
        // background-size: 120rem ;
    }

    .box {
        width: 120rem;
        // height: 43rem;
        border-top: solid 1px #ffffff;
        margin: 0 auto;
        // background: url('../assets/css/img/2-1-1.png') no-repeat;
        // background-size: 100%;
        overflow: hidden;
        position: relative;

        .box-content {
            position: absolute;
            left: 25rem;
            top: 10rem;
            width: 35rem;

            .content-p1 {
                text-align: center;
                font-size: 4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #6a3906;
                line-height: 6rem;
            }

            .content-p2 {
                // margin-bottom: 60px;
                position: relative;
                font-size: 2.4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #121212;
                line-height: 7.3rem;
                text-align: center;
            }

            .content-sub {
                margin: 0 auto;
                margin-top: 5rem;
                width: 15rem;
                height: 5rem;
                background-color: #4591fe;
                // background-image: url("../assets/css/img/download.png");
                // background-repeat: no-repeat;
                // background-size: 12%;
                // background-position: 1.5rem;
                border-radius: 1rem;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 5rem;
                padding-left: 2rem;

                span {
                    margin-left: 0.3rem;
                }
            }
        }
    }
}

@media screen and (min-width:1710px) {
    .box {
        width: 100%;
        // height: 43rem;
        border-top: solid 1px #ffffff;
        margin: 0 auto;
        // background: url('../assets/css/img/2-1-1.png') no-repeat;
        // background-size: 100%;
        overflow: hidden;
        position: relative;

        .box-content-1 {
            position: absolute;
            left: 23%;
            top: 20%;
            width: 35rem;

            .content-p1-1 {
                width: 45rem;
                text-align: center;
                font-size: 3.5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #6a3906;
                line-height: 4.5rem;
            }

            .content-p2-1 {
                width: 45rem;
                position: relative;
                font-size: 2.4rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #121212;
                line-height: 7.3rem;
                text-align: center;
            }

            .content-sub-1 {
                margin: 0 auto;
                margin-top: 5rem;
                margin-left: 10rem;
                width: 25rem;
                height: 5rem;
                background-color: #4591fe;
                border-radius: 1rem;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 5rem;
                padding-left: 2rem;

                span {
                    margin-left: 0.3rem;
                }
            }
        }

        .box-content {
            position: absolute;
            left: 22%;
            top: 19%;
            width: 50rem;

            .content-p1 {
                text-align: center;
                font-size: 4.5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #6a3906;
                line-height: 8rem;
            }

            .content-p2 {
                // margin-bottom: 60px;
                position: relative;
                font-size: 3rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #121212;
                line-height: 7.3rem;
                text-align: center;
            }

            .content-sub {
                margin: 0 auto;
                margin-top: 5rem;
                width: 15rem;
                height: 5rem;
                // background-color: #4591fe;
                // background-image: url("../assets/css/img/download.png");
                // background-repeat: no-repeat;
                // background-size: 12%;
                // background-position: 1.5rem;
                border-radius: 1rem;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 5rem;
                padding-left: 1.5rem;

                span {
                    margin-left: 1.5rem;
                }
            }
        }
    }

    .content2 {
        width: 100vw;
        height: 49rem;
        background: url("../assets/css/img/2-1-3.png") no-repeat;
        background-size: 100vw;
        padding-top: 10rem;

        .content {
            width: 50rem;
            height: 21rem;
            margin-left: 10rem;

            .content-p1 {
                font-size: 3rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f2f7ff;
                margin-bottom: 3.5rem;
            }

            .content-p2 {
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 3.5rem;
                color: #ffffff;
            }

            .content-p1-1 {
                width: 80rem;
                font-size: 2.5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f2f7ff;
                margin-bottom: 3.5rem;
            }

            .content-p2-1 {
                font-size: 1.8rem;
                width: 80rem;
                font-weight: 400;
                line-height: 6rem;
                color: #ffffff;
            }
        }
    }

}</style>
