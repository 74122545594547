<template>
    <div class="section">
        <div class="box" style="text-align: center;">
            <img src="../assets/css/img/2-6-1.png" style="width:100%;" alt="">
            <div :class="$i18n.locale == 'en' ? 'box-content-1' : 'box-content'">
                <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                    <!-- 助手程序 -->
                    {{ $t('assistant.swiper1') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-p1-1' : 'content-p1'">
                    <!-- 小软件大作用 -->
                    {{ $t('assistant.swiper2') }}
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-p2-1' : 'content-p2'">
                    <!-- 手机管店，小巧方便 -->
                    {{ $t('assistant.swiper3') }}
                    <span></span>
                </p>
                <p :class="$i18n.locale == 'en' ? 'content-sub-1' : 'content-sub'">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="35px"
                        style="vertical-align: middle;" height="29px">
                        <path fill-rule="evenodd" fill="rgb(255, 255, 255)"
                            d="M23.687,8.771 C24.091,8.771 24.486,8.802 24.885,8.847 C23.813,3.769 18.478,-0.002 12.385,-0.002 C5.575,-0.002 -0.003,4.718 -0.003,10.713 C-0.003,14.172 1.851,17.014 4.954,19.220 L3.716,23.010 L8.046,20.803 C9.595,21.112 10.838,21.432 12.385,21.432 C12.774,21.432 13.159,21.414 13.539,21.385 C13.300,20.543 13.156,19.661 13.156,18.743 C13.159,13.238 17.805,8.771 23.687,8.771 L23.687,8.771 ZM17.029,5.356 C17.965,5.356 18.580,5.981 18.580,6.929 C18.580,7.872 17.965,8.504 17.029,8.504 C16.104,8.504 15.173,7.872 15.173,6.929 C15.173,5.979 16.101,5.356 17.029,5.356 L17.029,5.356 ZM8.361,8.504 C7.431,8.504 6.494,7.872 6.494,6.929 C6.494,5.981 7.431,5.356 8.361,5.356 C9.291,5.356 9.907,5.979 9.907,6.929 C9.907,7.872 9.291,8.504 8.361,8.504 L8.361,8.504 ZM34.996,18.592 C34.996,13.554 30.039,9.449 24.473,9.449 C18.577,9.449 13.939,13.557 13.939,18.592 C13.939,23.641 18.580,27.733 24.473,27.733 C25.706,27.733 26.952,27.419 28.190,27.104 L31.587,28.998 L30.654,25.848 C33.142,23.948 34.996,21.432 34.996,18.592 L34.996,18.592 ZM21.057,17.014 C20.442,17.014 19.818,16.391 19.818,15.754 C19.818,15.127 20.442,14.496 21.057,14.496 C21.998,14.496 22.608,15.127 22.608,15.754 C22.608,16.391 21.998,17.014 21.057,17.014 L21.057,17.014 ZM27.870,17.014 C27.259,17.014 26.639,16.391 26.639,15.754 C26.639,15.127 27.258,14.496 27.870,14.496 C28.802,14.496 29.420,15.127 29.420,15.754 C29.420,16.391 28.802,17.014 27.870,17.014 L27.870,17.014 Z" />
                    </svg>
                    <span>
                        <!-- 微信搜索唐库商家助手 -->
                        {{ $t('assistant.swiper4') }}
                    </span>
                </p>
            </div>
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 随时随地查看销售数据 -->
                {{ $t('assistant.xs') }}
            </p>
            <p class="content-p2" style="margin-bottom: 10px">
                <!-- 随时掌握销售情况，店铺经营清晰明了 -->
                {{ $t('assistant.xs1') }}
            </p>
        </div>
        <div class="content-image">
            <img src="../assets/css/img/TEXT1.png" />
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 销售订单随时掌握 -->
                {{ $t('assistant.dd') }}
            </p>
            <p class="content-p2" style="margin-bottom: 10px;padding-left:55px;">
                <!-- 待发货 待收货 售后订单 直接处理 不在门店也能完成工作 -->
                {{ $t('assistant.dd1') }}
            </p>
        </div>
        <div class="content2">
        </div>
        <div class="content1">
            <p class="content-p1">
                <!-- 门店数据管理 -->
                {{ $t('assistant.md') }}
            </p>
            <p class="content-p2" style="margin-bottom: 10px">
                <!-- 商品管理 会员营销管理 门店管理 -->
                {{ $t('assistant.md1') }}
            </p>
        </div>
        <div class="image-1">
            <ul>
                <li>
                    <div class="img">
                        <img src="../assets/css/img/assistant_01.png" />
                    </div>
                    <p :class="$i18n.locale == 'en' ? 'active' : ''">
                        <!-- 商品改价 -->
                        {{ $t('assistant.content1') }}
                    </p>
                </li>
                <li>
                    <div class="img">
                        <img src="../assets/css/img/assistant_02.png" />
                    </div>
                    <p :class="$i18n.locale == 'en' ? 'active' : ''">
                        <!-- 商品管理 -->
                        {{ $t('assistant.content2') }}
                    </p>
                </li>
                <li>
                    <div class="img">
                        <img src="../assets/css/img/assistant_03.png" />
                    </div>
                    <p :class="$i18n.locale == 'en' ? 'active' : ''">
                        <!-- 库存管理 -->
                        {{ $t('assistant.content3') }}
                    </p>
                </li>
                <li>
                    <div class="img">
                        <img src="../assets/css/img/assistant_04.png" />
                    </div>
                    <p :class="$i18n.locale == 'en' ? 'active' : ''">
                        <!-- 采购进货  采购进货-->
                        {{ $t('assistant.content4') }}
                    </p>
                </li>
                <li>
                    <div class="img">
                        <img src="../assets/css/img/assistant_05.png" />
                    </div>
                    <p :class="$i18n.locale == 'en' ? 'active' : ''">
                        <!-- 会员管理 -->
                        {{ $t('assistant.content5') }}
                    </p>
                </li>
                <li>
                    <div class="img">
                        <img src="../assets/css/img/assistant_06.png" />
                    </div>
                    <p :class="$i18n.locale == 'en' ? 'active' : ''">
                        <!-- 会员规则 -->
                        {{ $t('assistant.content6') }}
                    </p>
                </li>
                <li>
                    <div class="img">
                        <img src="../assets/css/img/assistant_07.png" />
                    </div>
                    <p :class="$i18n.locale == 'en' ? 'active' : ''">
                        <!-- 优惠券 -->
                        {{ $t('assistant.content7') }}
                    </p>
                </li>
                <li>
                    <div class="img">
                        <img src="../assets/css/img/assistant_08.png" />
                    </div>
                    <p :class="$i18n.locale == 'en' ? 'active' : ''">
                        <!-- 促销活动 -->
                        {{ $t('assistant.content8') }}
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'cash',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang="less" scoped>
.section {
    // width: 100%;
    height: 100%;
    // border:solid;
    overflow: hidden;
}

.image-1 {
    width: 1200px;
    margin: 0 auto;
    height: 300px;

    ul {
        display: flex;
        justify-content: space-around;
        padding-top: 10px;

        li {
            width: 95px;
            height: 95px;

            .img {
                width: 85px;
                height: 85px;
                margin: 0 auto;
            }

            p {
                padding-left: 11px;
                padding-top: 10px;
                font-size: 1.8rem;
            }

            .active {
                padding-left: 0rem;
                width: 10rem;
                font-size: 1.6rem;
                text-align: center;
            }
        }
    }
}

.content3 {
    width: 1200px;
    height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

    div {
        width: 280px;
        height: 460px;
        border-radius: 30px;
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 30px;
    }

    .image2 {
        background: linear-gradient(0deg,
                #f4f7fd,
                #ebf2ff,
                #ebf2ff,
                #f4f7ff,
                #ebf2ff);
    }

    .image1 {
        background: url('../assets/css/img/2-1-2-2.png') no-repeat;
        background-size: cover;
    }

    .image3 {
        background: url('../assets/css/img/2-1-2-1.png') no-repeat;
        background-size: cover;
    }

    .title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        line-height: 33px;
        margin-bottom: 30px;
    }

    .content-P {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #121212;
        line-height: 40px;
    }

    .title1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 33px;
        margin-bottom: 30px;
    }

    .content-P1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
    }
}

.content2 {
    width: 120rem;
    height: 50rem;
    margin: 0 auto;
    background: url("../assets/css/img/2-6-3.png") no-repeat;
    background-size: 100%;
}

.content-image {
    width: 1200px;
    text-align: center;
    margin: 0 auto;
}

// 内容样式
.content1 {
    width: 100rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 7rem;
    margin-bottom: 3rem;

    .content-p1 {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        margin-bottom: 10px;
    }

    .content-p2 {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
    }
}

.box {
    width: 100%;
    // height:55rem;
    border-top: solid 1px #ffffff;
    margin: 0 auto;
    // background: url('../assets/css/img/2-6-1.png') no-repeat;
    background-size: 100%;
    overflow: hidden;
    position: relative;

    .box-content-1 {
        position: absolute;
        left: 35rem;
        top: 15rem;
        width: 55rem;

        .content-p1-1 {
            text-align: center;
            font-size: 3.5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 5rem;
        }

        .content-p2-1 {
            text-align: center;
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 5rem;
        }

        .content-sub-1 {
            margin: 0 auto;
            margin-top: 7rem;
            width: 48rem;
            height: 6rem;
            background: #06B33D;
            border-radius: 3rem;
            font-size: 2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 6rem;
            padding-left: 0rem;

            span {
                margin-left: 1rem;
            }
        }
    }

    .box-content {
        position: absolute;
        left: 350px;
        top: 150px;
        width: 550px;

        .content-p1 {
            text-align: center;
            font-size: 4rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 5rem;
            color: #FFFFFF;
        }

        .content-p2 {
            // margin-bottom: 60px;
            margin-top: 1rem;
            text-align: center;
            position: relative;
            font-size: 2.2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 4rem;
        }

        .content-sub {
            margin: 0 auto;
            margin-top: 7rem;
            width: 41.6rem;
            height: 6rem;
            background: #06B33D;
            border-radius: 3rem;
            font-size: 2.2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 6rem;
            padding-left: 0rem;

            span {
                margin-left: 2rem;
            }
        }
    }
}

@media screen and (max-width:1300px) {
    .section {
        overflow: hidden;
    }

    .content2 {
        width: 100%;
        height: 50rem;
        margin: 0 auto;
        background: url("../assets/css/img/2-6-3.png") no-repeat;
        background-size: 100%;
        // margin-top: 10%;
    }

    .content-image {
        width: 100%;
        text-align: center;
        // height: 300px;
        margin: 0 auto;
        // background: url('../assets/css/img/TEXT1.png') no-repeat;
        // background-size: 100%;
    }

    .image-1 {
        width: 100%;
        // margin: 0 auto;
        height: 300px;

        ul {
            display: flex;
            justify-content: space-around;
            padding-top: 10px;

            li {
                width: 7.9%;
                height: 95px;

                .img {
                    width: 90%;
                    height: 85px;
                    margin: 0 auto;
                }

                p {
                    padding-left: 11px;
                    padding-top: 10px;
                }
            }
        }

    }

    .box {
        width: 100%;
        border-top: solid 1px #ffffff;
        margin: 0 auto;
        background: url('../assets/css/img/2-6-1.png') no-repeat;
        background-size: 100%;
        overflow: hidden;
        position: relative;
        .box-content-1 {
            position: absolute;
            left: 13%;
            top: 9%;
            width: 55rem;

            .content-p1-1 {
                text-align: center;
                font-size: 3.5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 5rem;
            }

            .content-p2-1 {
                text-align: center;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 5rem;
            }

            .content-sub-1 {
                margin: 0 auto;
                margin-top: 7rem;
                width: 48rem;
                height: 6rem;
                background: #06B33D;
                border-radius: 3rem;
                font-size: 2rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 6rem;
                padding-left: 0rem;

                span {
                    margin-left: 1rem;
                }
            }
        }

        .box-content {
            position: absolute;
            left: 15%;
            top: 9%;
            width: 55rem;
        }
    }

    .content3 {
        width: 100%;
        height: 500px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        div {
            width: 23%;
            height: 460px;
            border-radius: 30px;
            padding-top: 40px;
            padding-left: 3%;
            padding-right: 3%;
        }

        .image2 {
            background: linear-gradient(0deg,
                    #f4f7fd,
                    #ebf2ff,
                    #ebf2ff,
                    #f4f7ff,
                    #ebf2ff);
        }

        .image1 {
            background: url('../assets/css/img/2-1-2-2.png') no-repeat;
            background-size: cover;
        }

        .image3 {
            background: url('../assets/css/img/2-1-2-1.png') no-repeat;
            background-size: cover;
        }

        .title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #121212;
            line-height: 33px;
            margin-bottom: 30px;
        }

        .content-P {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #121212;
            line-height: 40px;
        }

        .title1 {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
            margin-bottom: 30px;
        }

        .content-P1 {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
        }
    }
}

@media screen and (max-width:1080px) {
    .image-1 {
        width: 720px;
        margin: 0 auto;
        height: 300px;

        ul {
            display: flex;
            justify-content: space-around;
            padding-top: 10px;

            li {
                width: 95px;
                height: 95px;

                .img {
                    width: 85px;
                    height: 85px;
                    margin: 0 auto;
                    // border: dashed 1px;
                }

                p {
                    padding-left: 11px;
                    padding-top: 10px;
                }
            }
        }

    }
}


@media screen and (max-width:673px) {
    .content3 {
        width: 670px;
        height: 500px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        div {
            width: 23%;
            height: 460px;
            border-radius: 30px;
            padding-top: 40px;
            padding-left: 3%;
            padding-right: 3%;
        }
    }
}</style>
