<template>
    <div class="section">
        <div class="section1">
            <div class="section-box">
                <img src="../assets/css/img/2-5-1.png" style="width:100%;">
                <div class="box" :class="$i18n.locale=='en'?'active1':'active'">
                    <p :class="$i18n.locale=='en'?'box-p1-1':'box-p1'">
                        <!-- 价签 -->
                        {{ $t('utility.content1') }}
                    </p>
                    <p :class="$i18n.locale=='en'?'box-p1-1':'box-p1'">
                        <!-- 电子秤系统 -->
                        {{ $t('utility.content2') }}
                    </p>
                    <p class="box-p2">
                        <!-- 批量打印价签更快捷 -->
                        {{ $t('utility.content3') }}
                    </p>
                    <p :class="$i18n.locale=='en'?'box-sub-1':'box-sub'">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                            height="25px" style="vertical-align: middle;">
                            <path fill-rule="evenodd" fill="rgb(242, 243, 244)"
                                d="M22.005,10.070 L15.714,10.070 L15.714,0.640 L6.280,0.640 L6.280,10.070 L-0.010,10.070 L10.997,21.071 L22.002,10.070 L22.005,10.070 ZM-0.010,24.214 L-0.010,27.358 L22.005,27.358 L22.005,24.214 L-0.010,24.214 Z" />
                        </svg>
                        <span>
                            <!-- 软件下载 -->
                            {{ $t('utility.content4') }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="content">
            <p class="content-p1">
                <!-- 自由设计价签模板 -->
                {{ $t('utility.jq') }}
            </p>
            <p class="content-p2">
                <!-- 随意设计价签尺寸，样式模板，可适配多种不同价签格式 -->
                {{ $t('utility.jq1') }}
            </p>
        </div>
        <div class="image-1">
            <img src="../assets/css/img/2-5-2.png" />
        </div>
        <div class="content">
            <p class="content-p1">
                <!-- 批量打印商品价签 -->
                {{ $t('utility.goodsjq') }}
            </p>
            <p class="content-p2">
                <!-- 一次打印多件商品价签，不用一个一个打印，节省人力 -->
                {{ $t('utility.goodsjq1') }}
            </p>
            <p class="content-p2">
                <!-- 可以按着分组，品牌、供应商、搜索商品，勾选商品，点击打印价签进行打印 -->
                {{ $t('utility.goodsjq2') }}
            </p>
        </div>
        <div class="image-1">
            <img src="../assets/css/img/2-5-3.png" />
        </div>
        <div class="content">
            <p class="content-p1">
                <!-- 设置不同类型电子秤 -->
                {{ $t('utility.dzc') }}
            </p>
            <p class="content-p2">
                <!-- 可添加多种类型电子秤 -->
                {{ $t('utility.dzc1') }}
            </p>
        </div>
        <div class="image-1" style="margin-bottom:20rem;">
            <img src="../assets/css/img/2-5-4.png" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'utility',
    data() {
        return {

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang='less' scoped>
.active{
    margin-left: 10rem;
}
.active1{
    margin-left:2rem;
}
.section {
    width: 100%;
    height: 100%;
}

.section1 {
    width: 100%;
    margin: 0 auto;
}

.image-1 {
    width: 120rem;
    margin: 0 auto;

    img {
        width: 100%;
    }
}

.content {
    width: 100rem;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 5rem;

    .content-p1 {
        font-size: 3.6rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
        line-height: 5rem;
    }

    .content-p2 {
        font-size: 2.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
    }
}

.section-box {
    width: 100%;
    margin: 0 auto;
    position: relative;
    //一种方法 ： 将.box放在一个120rem的盒子中 margin: 0 auto; 来定位
    .box {
        // border:solid 1px;
        width: 50rem;
        height: 30rem;
        padding-top: 8rem;
        position: absolute;
        left: 10rem;
        top: 5rem;
        .box-p1 {
            font-size: 5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 7rem;
            text-align: center;
        }
        .box-p1-1{
            font-size:4rem;
            font-family: Microsoft YaHei;
            font-weight:bold;
            color:#FFFFFF;
            line-height:5rem;
            text-align:center;
        }
        .box-p2-1{
            font-size:2.3rem;
            font-family: Microsoft YaHei;
            font-weight:400;
            color:#FFFFFF;
            line-height:6rem;
            text-align:center;
        }
        .box-p2 {
            font-size: 2.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 8rem;
            text-align: center;
        }
        .box-sub-1 {
            width: 25rem;
            height: 4.5rem;
            border-radius: 1.1rem;
            background-color: #4591fe;
            font-size: 2rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            padding-left: 2rem;

            span {
                margin-left: 0.3rem;
            }

            margin: 0 auto;
            margin-top: 4rem;
        }
        .box-sub {
            width: 15rem;
            height: 4.5rem;
            border-radius: 1.1rem;
            background-color: #4591fe;
            font-size: 2rem;
            color: #FFFFFF;
            line-height: 4.5rem;
            padding-left: 2rem;

            span {
                margin-left: 0.3rem;
            }

            margin: 0 auto;
            margin-top: 4rem;
        }
    }

}

@media screen and (max-width:1500px) {
    .section-box {
        width: 100%;
        // height: 40rem;
        margin: 0 auto;
        // background: url('../assets/css/img/2-5-1.png') no-repeat;
        // background-size: 100%;
        position: relative;

        //一种方法 ： 将.box放在一个120rem的盒子中 margin: 0 auto; 来定位
        .box {
            width: 50rem;
            height: 30rem;
            padding-top: 8rem;
            position: absolute;
            left: 1%;
            top: -5%;

            .box-p1 {
                font-size: 5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 7rem;
                text-align: center;
            }

            .box-p2 {
                font-size: 2.8rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 8rem;
                text-align: center;
            }

            .box-sub {
                width: 15rem;
                height: 4.5rem;
                border-radius: 1.1rem;
                // background: url('../assets/css/img/download.png') no-repeat;
                // background-size: 15%;
                // background-position: 1.5rem;
                background-color: #4591fe;
                font-size: 2rem;
                color: #FFFFFF;
                line-height: 4.5rem;
                padding-left: 2rem;
                span {
                    margin-left: 0.3rem;
                }
                margin: 0 auto;
                margin-top: 4rem;
            }
        }

    }
}

@media screen and (max-width:1300px) {
    .image-1 {
        width: 118rem;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }
}

@media screen and (max-width:1200px) {
    .section-box {
        width: 120rem;
        margin: 0 auto;
        position: relative;
        .box {
            width: 50rem;
            height: 30rem;
            padding-top: 8rem;
            position: absolute;
            left: 1%;
            top: -5%;
            .box-p1 {
                font-size: 5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 7rem;
                text-align: center;
            }

            .box-p2 {
                font-size: 2.8rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 8rem;
                text-align: center;
            }

            .box-sub {
                width: 15rem;
                height: 4.5rem;
                border-radius: 1.1rem;
                // background: url('../assets/css/img/download.png') no-repeat;
                // background-size: 15%;
                // background-position: 1.5rem;
                background-color: #4591fe;
                font-size: 2rem;
                color: #FFFFFF;
                line-height: 4.5rem;
                padding-left:2rem;
                span{
                    margin-left:0.3rem;
                }
                margin: 0 auto;
                margin-top: 4rem;
            }
        }

    }
}

@media screen and (min-width:1710px) {
    .section-box {
        width: 100%;
        // height: 40rem;
        margin: 0 auto;
        // background: url('../assets/css/img/2-5-1.png') no-repeat;
        // background-size: 100%;
        position: relative;

        //一种方法 ： 将.box放在一个120rem的盒子中 margin: 0 auto; 来定位
        .box {
            width: 50rem;
            height: 30rem;
            padding-top: 8rem;
            position: absolute;
            left: 12%;
            top: 7%;

            .box-p1 {
                font-size: 5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 7rem;
                text-align: center;
            }

            .box-p2 {
                font-size: 2.8rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 8rem;
                text-align: center;
            }

            .box-sub {
                width: 15rem;
                height: 4.5rem;
                border-radius: 1.1rem;
                // background: url('../assets/css/img/download.png') no-repeat;
                // background-size: 15%;
                // background-position: 1.5rem;
                background-color: #4591fe;
                font-size: 2rem;
                color: #FFFFFF;
                line-height: 4.5rem;
                padding-left: 2rem;
                span{
                    margin-left:0.3rem;
                }
                margin: 0 auto;
                margin-top: 4rem;
            }
        }

    }
}</style>